/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./WorkProfile.scss";
import * as toast from "@/wrapper/toast";
import { useNavigate } from "react-router-dom";

import * as storageConstants from "@/constants/storageConstants";
import * as ApiHelper from "@/services/apiHelper";
import * as commonServices from "@/services/common";
import { LOCATION_THRESHOLD } from "@/constants/storageConstants";
import * as errors from "@/constants/stringConstants";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isExist, isNotNullOrEmpty } from "@/utils/objectUtil";
import { checkUrl } from "@/utils/validation";
import { isCandidate, isEvaluator } from "@/utils/authUtil";
import { scrollToElement } from "@/utils/styleUtils";

import { jsonToFormData } from "../../wrapper/formData";
import WorkProfileLayout from "./WorkProfileLayout";
import { isNullOrEmpty, stripHTMLTags } from "../../utils/stringUtils";
import { years, months } from "../../utils/dateUtil";
import { addDataOnFocusOut } from "../../services/common";

function WorkProfile({ setTrigger }) {
  const [profile, setProfile] = useState({});
  const [profilePreview, setProfilePreview] = useState({});
  const [summary, setSummary] = useState("");
  const masterData = commonServices.getLocalData(storageConstants.DATA);
  masterData?.joining_preferences.pop();

  const [skillData, setSkillData] = useState(commonServices.getLocalData(storageConstants.SKILLS));
  const [showModalShare, setShowModalShare] = useState(false);

  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);
  let [dataAutoSave, setDataAutoSave] = useState(false);
  let [color] = useState("#000000");

  var url_string = window.location.href;
  var url = new URL(url_string);
  var profileInProgress = url.searchParams.get("profileInProgress");
  var el = url.searchParams.get("el");
  var redirect = url.searchParams.get("redirect");

  const [showModalEducation, setShowModalEducation] = useState(false);
  const [showModalEmployment, setShowModalEmployment] = useState(false);
  const [showModalCertificate, setShowModalCertificate] = useState(false);
  const [showModalSkills, setShowModalSkills] = useState(false);
  const [showModalAward, setShowModalAward] = useState(false);
  const [locationSuggestion, setLocationSuggestion] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const [profileFetched, setProfileFetched] = useState(false);
  const [userIndustryDomain, setUserIndustryDomain] = useState([]);
  const [userIndustryDomainName, setUserIndustryDomainName] = useState([]);

  const month = [
    "abc",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [showExperienceSuggestionModal, setShowExperienceSuggestionModal] =
    useState(false);
  const [industryDomainSuggestion, setIndustryDomainSuggestion] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [skill, setSkill] = useState([]);
  const [links, setLinks] = useState([]);

  var dropdownOptions = [
    {
      id: "0",
      name: "No",
    },
    {
      id: "1",
      name: "Yes",
    },
  ];

  useEffect(() => {
    getLatestProfile();
    // getSkills();
    if (profileInProgress === 1) {
      var options = { hideAfter: 5 };
      if (isCandidate())
        toast.info(errors.INCOMPLETE_WORKPROFILE_ERROR, options);
      else toast.info(errors.INCOMPLETE_PROFILE_ERROR, options);
    }
    if (el === "Skills") {
      scrollToElement(el);
    }
  }, []);

  const normalizePhoneNumber1 = (input, defaultCountry = null) => {
    const parsedPhone = parsePhoneNumber(input, defaultCountry);
    if (parsedPhone) {
      return parsedPhone?.number;
    } else {
      return input;
    }
  };

  const getLatestProfile = async () => {
    setLoading(true);
    await ApiHelper.getProfile().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        if (!isNullOrEmpty(response.data.phone_number) && !response.data.phone_number.includes("+91")) {
          response.data.phone_number = response.data.cc_phone_number + response.data.phone_number;
        }
        setProfile(response.data);
        setSummary(
          response.data.user_work_profile !== null
            ? response.data.user_work_profile.summary
            : ""
        );
        setUserIndustryDomain(response?.data?.industry_domain);
        setUserIndustryDomainName(response?.data?.industry_domain_names);
        commonServices.storeLocalData(storageConstants.PROFILE, response.data);
        setCurrentLocation(
          response.data.user_work_profile &&
          response.data.user_work_profile.location_name
        );
        setLinks(
          response.data?.userWorkProfileDetail !== undefined &&
            response.data?.userWorkProfileDetail?.link !== undefined
            ? response.data?.userWorkProfileDetail?.link
            : []
        );
        setTrigger((prev) => !prev);
        setProfileFetched(true);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const getSkills = () => {
    ApiHelper.getSkills().then((response) => {
      if (response.isSuccess === true) {
        setSkillData(response.data);
      } else {
        console.log("error");
      }
    });
  };

  const onChange = (e, type = "") => {
    if (type === "") {
      setProfile({
        ...profile,
        [e.target.name]: e.target.value,
      });
    } else {
      var tempProfile = { ...profile };
      if (tempProfile[type] === null) {
        tempProfile[type] = [];
      }
      tempProfile[type][e.target.name] = e.target.value;
      setProfile(tempProfile);
    }
  };

  const updateSummary = (summaryData) => {
    setSummary(summaryData);
    var type = "user_work_profile";
    var tempProfile = { ...profile };
    if (tempProfile[type] === null) {
      tempProfile[type] = [];
    }
    if (tempProfile[type] !== undefined) {
      tempProfile[type]["summary"] = summaryData;
    }
    setProfile(tempProfile);
  };

  const deleteDegree = async (e, data) => {
    e.preventDefault();
    var tempProfileArr = { ...profile };
    tempProfileArr.userWorkProfileDetail.degree.splice(data, 1);
    setProfile(tempProfileArr);
  };

  const uploadMedia = async (e, type) => {
    const formMedia = new FormData();

    formMedia.append("file", e.target.files[0]);
    formMedia.append("type", type);

    setLoading(true);

    await ApiHelper.uploadMedia(formMedia).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        getLatestProfile();
        clearFileValues();
        fileRef.current.value = "";
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const locationHandler = (text) => {
    setCurrentLocation(text);
    if (text.length > LOCATION_THRESHOLD) {
      commonServices.getLocations(text).then((locations) => {
        setLocationSuggestion(locations);
      });
    } else {
      setLocationSuggestion([]);
    }
  };

  const onLocationClick = (location) => {
    setCurrentLocation(location.name);
    var tempProfile = { ...profile };
    tempProfile.user_work_profile.location_name = location.name;
    tempProfile.user_work_profile.location_id = location.id;
    setProfile(tempProfile);
  };

  const experienceHandler = (e, type) => {
    var tempArray = { ...profile };
    if (tempArray.user_work_profile === null) {
      tempArray.user_work_profile = {};
      tempArray.user_work_profile.total_experience = 0;
    }
    if (type === "year") {
      var months = parseInt(e.target.value) * 12;
      tempArray.user_work_profile.total_experience = ((!isNaN(tempArray.user_work_profile.total_experience) ? tempArray.user_work_profile.total_experience : 0) % 12) + months;
      setProfile(tempArray);
    } else if (type === "month") {
      tempArray.user_work_profile.total_experience =
        Math.trunc((!isNaN(tempArray.user_work_profile.total_experience) ? tempArray.user_work_profile.total_experience : 0) / 12) * 12 +
        parseInt(e.target.value);
      setProfile(tempArray);
    }
  };

  const deleteEmployment = async (e, data) => {
    e.preventDefault();
    var tempProfileArr = { ...profile };
    tempProfileArr.userWorkProfileDetail.experience.splice(data, 1);
    setProfile(tempProfileArr);
  };

  const deleteCertificate = async (e, data) => {
    e.preventDefault();
    var tempProfileArr = { ...profile };
    tempProfileArr.userWorkProfileDetail.certificate.splice(data, 1);
    setProfile(tempProfileArr);
  };

  const deleteAward = async (e, data) => {
    e.preventDefault();
    var tempProfileArr = { ...profile };
    tempProfileArr.userWorkProfileDetail.award.splice(data, 1);
    setProfile(tempProfileArr);
  };

  const sortArray = (array) => {
    return array.sort(function (a, b) {
      var keyA = new Date(a.from_date),
        keyB = new Date(b.from_date);
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
  };

  const onChangeHandlerIndustryDomain = (text) => {
    if (text.length > storageConstants.INDUSTRY_DOMAIN_THRESHOLD) {
      commonServices.getIndustryDomainByName(text).then((domains) => {
        setIndustryDomainSuggestion(domains);
      });
    } else {
      setIndustryDomainSuggestion([]);
    }
  };

  const onIndustryDomainClick = (domain, e) => {
    e.preventDefault();
    if(userIndustryDomain.length >= 3){
      toast.error(errors.INDUSTRY_DOMAIN_MAX);
      return;
    }
    if (![...userIndustryDomain].includes(domain.id)) {
      setUserIndustryDomain([...userIndustryDomain, domain.id]);
      setUserIndustryDomainName([...userIndustryDomainName, domain]);
    }
    document.getElementById("industry_domain").value = "";
    onChangeHandlerIndustryDomain("");
  };

  const deleteIndustryDomain = async (e, data) => {
    e.preventDefault();
    var arrow = [...userIndustryDomain];
    var tempUserIndustryDomainName = [...userIndustryDomainName];
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    for (var i = 0; i < tempUserIndustryDomainName.length; i++) {
      if (parseInt(tempUserIndustryDomainName[i]?.id) === data) {
        tempUserIndustryDomainName.splice(i, 1);
      }
    }
    setUserIndustryDomain(arrow);
    setUserIndustryDomainName(tempUserIndustryDomainName);
  };

  const updateWorkProfile = async (action = "preview") => {
    const parsedNumber = parsePhoneNumber(profile.phone_number || '');
    const countryCode = parsedNumber?.countryCallingCode;
    const numberOnly = parsedNumber?.nationalNumber;

    if (profile.user_work_profile === null) {
      profile.user_work_profile = [];
    }

    if(profile?.user_work_profile?.joining_preference_id && action === "preview"){
      var tempProfile = { ...profile };
      const result = masterData?.joining_preferences.find(item => item.id === parseInt(profile?.user_work_profile?.joining_preference_id));
      tempProfile["user_work_profile"]["joining_preference_name"] = result ? result.name : 'N/A';
      
      setProfile(tempProfile);
    }
    const obj = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      layoff: profile.user_work_profile.layoff,
      her_career_reboot: profile.user_work_profile.her_career_reboot,
      differently_abled: profile.user_work_profile.differently_abled,
      armed_forces: profile.user_work_profile.armed_forces,
      email: profile.email,
      summary: profile.user_work_profile.summary,
      title: profile.user_work_profile.title,
      joining_preference_id: profile.user_work_profile.joining_preference_id,
      location_id: profile.user_work_profile.location_id,
      location_name: profile.user_work_profile.location_name,
      phone_number: numberOnly || '',
      cc_phone_number: "+" + (countryCode || ''),
      total_experience: profile.user_work_profile.total_experience,
      work_authorization_id: profile.user_work_profile
        ? profile.user_work_profile.work_authorization_id
        : null,
      work_profile_id: profile.user_work_profile
        ? profile.user_work_profile.user_id
        : null,
      certificate: profile.userWorkProfileDetail.certificate,
      experience: profile.userWorkProfileDetail.experience,
      education_degree: profile.userWorkProfileDetail.degree,
      awards: profile.userWorkProfileDetail.award,
      skills: skill,
      links: links,
      gender: profile?.gender,
      current_salary: commonServices.removeCommaFromAmount(profile?.current_salary),
      min_salary: commonServices.removeCommaFromAmount(profile?.min_salary),
      required_company_size: profile.user_work_profile.required_company_size,
      industry_domain: JSON.stringify(userIndustryDomain),
    };

    let isError = 0;

    if (!obj.first_name) {
      isError = 1;
      toast.warn(errors.FIRST_NAME_REQUIRED);
    } else if (!obj.last_name) {
      isError = 1;
      toast.warn(errors.LAST_NAME_REQUIRED);
    } else if (!obj.email) {
      isError = 1;
      toast.warn(errors.EMAIL_REQUIRED);
    } else if (!obj.phone_number) {
      isError = 1;
      toast.warn(errors.PHONE_NUMBER_REQUIRED);
    } else if (!isValidPhoneNumber(profile?.phone_number)) {
      isError = 1;
      toast.warn(errors.PHONE_NUMBER_INCORRECT);
    } else if (isCandidate() && !obj.location_name) {
      isError = 1;
      toast.warn(errors.LOCATION_REQUIRED);
    } else if (isCandidate() && !obj.joining_preference_id) {
      isError = 1;
      toast.warn(errors.NOTICE_PERIOD_REQUIRED);
    } else if (isCandidate() && !obj.title) {
      isError = 1;
      toast.warn(errors.JOB_TITLE_ERROR);
    } else if (!isNotNullOrEmpty(stripHTMLTags(obj.summary))) {
      isError = 1;
      toast.warn(errors.PROFESSIONAL_SUMMARY_REQUIRED);
    }else if (isCandidate() && !obj.joining_preference_id) {
      isError = 1;
      toast.warn(errors.NOTICE_PERIOD_REQUIRED);
    }else if (isCandidate() && !obj.current_salary) {
      isError = 1;
      toast.warn(errors.CURRENT_SALARY_REQUIRED);
    }else if (commonServices.removeCommaFromAmount(obj.current_salary) > 999999999) {
      isError = 1;
      toast.warn(errors.MAX_SALARY_AMT_REACHED+ " for currect CTC");
    }else if (isCandidate() && !obj.min_salary) {
      isError = 1;
      toast.warn(errors.REQUIRED_SALARY_REQUIRED);
    }else if (commonServices.removeCommaFromAmount(obj.min_salary) > 999999999) {
      isError = 1;
      toast.warn(errors.MAX_SALARY_AMT_REACHED+ " for expected CTC");
    } else if (isCandidate() && obj.total_experience > 0 && (!obj.experience || obj.experience.length < 1)) {
      isError = 1;
      toast.warn(errors.EMPLOYMENT_HISTORY_REQUIRED);
    } else if (!obj.skills || obj.skills.length < 1) {
      isError = 1;
      toast.warn(errors.SKILLS_REQUIRED);
    } else if (skillChildRef.current.isInvalidSkills()) {
      isError = 1;
    } else if (!obj.education_degree || obj.education_degree.length < 1) {
      isError = 1;
      toast.warn(errors.EDUCATION_REQUIRED);
    } else if (isInvalidLinks()) {
      isError = 1;
    }

    if (isError === 0) {
      if (action === "preview") {
        setShowPreviewModal(true);
        return;
      } else {
        setShowPreviewModal(false);
        setLoading(true);
        const formDataSeralize = jsonToFormData(obj);

        await ApiHelper.updateWorkProfile(formDataSeralize).then((response) => {
          if (response.isSuccess === true) {
            getLatestProfile();
            clearFileValues();
            getSkills();
            setLoading(false);
            if (redirect !== undefined && redirect !== null) {
              navigate(redirect);
            }
            toast.success(response.message);
          } else {
            setLoading(false);
            toast.warn(response.message);
          }
        });
      }
    }
  };

  const autoSaveWorkProfile = async () => {
    const parsedNumber = parsePhoneNumber(profile?.phone_number ?? '');
    const countryCode = parsedNumber?.countryCallingCode;
    const numberOnly = parsedNumber?.nationalNumber;
    if (profile.user_work_profile === null) {
      profile.user_work_profile = [];
    }
    const obj = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      layoff: profile.user_work_profile.layoff,
      email: profile.email,
      summary: profile.user_work_profile.summary,
      joining_preference_id: profile.user_work_profile.joining_preference_id,
      location_id: profile.user_work_profile.location_id,
      location_name: profile.user_work_profile.location_name,
      phone_number: numberOnly,
      cc_phone_number: "+" + (countryCode || ''),
      total_experience: profile.user_work_profile.total_experience,
      work_authorization_id: profile.user_work_profile
        ? profile.user_work_profile.work_authorization_id
        : null,
      work_profile_id: profile.user_work_profile
        ? profile.user_work_profile.user_id
        : null,
      certificate: profile.userWorkProfileDetail.certificate,
      experience: profile.userWorkProfileDetail.experience,
      education_degree: profile.userWorkProfileDetail.degree,
      awards: profile.userWorkProfileDetail.award,
      skills: skill,
      links: links,
    };

    await ApiHelper.updateWorkProfile(obj).then((response) => {
      setDataAutoSave(false);
      if (response.isSuccess === true) {
        console.log("success");
      } else {
        console.error(response.message);
      }
    });
  };

  useEffect(() => {
    if (dataAutoSave) {
      autoSaveWorkProfile();
    }
  }, [dataAutoSave]);

  const deleteResume = async (element = "cv_link") => {
    setLoading(true);
    const formData = new FormData();
    formData.append(element, "");
    formData.append('is_delete', true);
    await ApiHelper.updateWorkProfile(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        getLatestProfile();

        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
    });
  };

  const isInvalidLinks = () => {
    var isError = false;
    links &&
      links.map((element, key) => {
        var currentRow = key + 1;
        var isLinkExist = false;
        isLinkExist = links.some(function (el, i) {
          return (
            (el.title === links[key].title && key !== i) ||
            (el.description === links[key].description && key !== i)
          );
        });

        if (isLinkExist) {
          isError = true;
          toast.warn(
            errors.PORTFOLIO_LINK_EXISTS_ERROR + " at row " + currentRow
          );
          return false;
        } else if (!links[key].title) {
          isError = true;
          toast.warn(
            errors.EMPTY_PORTFOLIO_LABEL_ERROR + " at row " + currentRow
          );
        } else if (!links[key].description) {
          isError = true;
          toast.warn(
            errors.EMPTY_PORTFOLIO_LINK_ERROR + " at row " + currentRow
          );
        } else if (!checkUrl(links[key].description)) {
          isError = true;
          toast.warn(errors.VALID_PORTFOLIO_LINK + " at row " + currentRow);
        }
      });

    return isError;
  };

  const setUserSkillData = () => {
    if (
      profile.userWorkProfileDetail !== undefined &&
      profile.userWorkProfileDetail.skill !== undefined &&
      profile.userWorkProfileDetail.skill !== null
    ) {
      profile.userWorkProfileDetail.skill.forEach((currentSkill) => {
        skillData.forEach((element) => {
          if (element.id === currentSkill.skill_id) {
            currentSkill.skill_name = element.name;
          }
        });
      });
    }

    if (
      profile.userWorkProfileDetail !== undefined &&
      profile.userWorkProfileDetail.preffered_skill !== undefined &&
      profile.userWorkProfileDetail.preffered_skill !== null
    ) {
      profile.userWorkProfileDetail.preffered_skill.forEach((currentSkill) => {
        skillData.forEach((element) => {
          if (element.id === currentSkill.skill_id) {
            currentSkill.skill_name = element.name;
          }
        });
      });
    }

    let userSkills =
      isExist(profile.userWorkProfileDetail) &&
        isExist(profile.userWorkProfileDetail.skill) &&
        isNotNullOrEmpty(profile.userWorkProfileDetail.skill)
        ? profile.userWorkProfileDetail.skill
        : [];
    let userPrefferedSkills =
      isExist(profile.userWorkProfileDetail) &&
        isExist(profile.userWorkProfileDetail.preffered_skill) &&
        isNotNullOrEmpty(profile.userWorkProfileDetail.preffered_skill)
        ? profile.userWorkProfileDetail.preffered_skill
        : [];
        let set1 = new Set(userSkills);
        userPrefferedSkills.forEach(element => {
          set1.add(element);
      });
      let mergedArray = Array.from(set1);
    setSkill(mergedArray);
  };

  useEffect(() => {
    if (profile.userWorkProfileDetail !== undefined) {
      let tempProfile = { ...profile };

      tempProfile.userWorkProfileDetail.skill = skill;
      setProfile(tempProfile);
    }
  }, [skill]);

  useEffect(() => {
    setUserSkillData();
  }, [skillData, profileFetched]);

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  const closeModal = () => {
    onClose();
  };

  const infoButtonOneAction = () => {
    setShowExperienceSuggestionModal(false);
  };

  const [experienceAction, setExperienceAction] = useState(false);

  useEffect(() => {
    if (experienceAction) {
      infoButtonTwoAction();
    }
  }, [experienceAction]);

  useEffect(() => {
    setProfilePreview(profile);
  }, [profile]);

  const updateExperience = () => {
    setExperienceAction(true);
  };

  const infoButtonTwoAction = () => {
    let tempProfile = { ...profile };
    let experience = tempProfile?.user_work_profile?.total_experience;
    skill &&
      skill.map((element, key) => {
        if (skill[key].experience > experience) {
          experience = skill[key].experience;
        }
      });

    tempProfile.user_work_profile.total_experience = experience;
    setProfile(tempProfile);
    setShowExperienceSuggestionModal(false);
    setExperienceAction(false);
  };

  const [experienceSuggestionModalData, setExperienceSuggestionModalData] =
    useState({
      heading: errors.IMPORTANT_WARNING_TITLE,
      paragraph: errors.TOTAL_EXPERIENCE_WARNING_TEXT,
      buttonOneText: "Cancel",
      buttonOneAction: infoButtonOneAction,
      buttonTwoText: "Continue",
      buttonTwoAction: updateExperience,
    });

  const fileRef = useRef(null);
  const skillChildRef = useRef();

  const clearFileValues = () => {
    const fileInputs = document.querySelectorAll('input[type="file"]');

    fileInputs.forEach(function (input) {
      input.value = "";
    });
  };

  const triggerSelect = (event) => {
    const active = document.querySelector(".sugg_p.active");
    if (event.which === 13 || event.keyCode === 13) {
      if (active) {
        active.click();
      }
    }
  };

  const triggerClickDown = (event) => {
    if (event.which === 27 || event.keyCode === 27) {
      setLocationSuggestion([]);
    } else if (event.which === 9 || event.keyCode === 9) {
      if (event.target.name === "location_id") {
        addDataOnFocusOut(locationSuggestion, setLocationSuggestion);
        event.target.value = "";
      }
    }
  };

  return (
    <WorkProfileLayout
      profile={profile}
      setProfile={setProfile}
      loading={loading}
      showModalEducation={showModalEducation}
      setShowModalEducation={setShowModalEducation}
      showModalEmployment={showModalEmployment}
      setShowModalEmployment={setShowModalEmployment}
      showModalCertificate={showModalCertificate}
      setShowModalCertificate={setShowModalCertificate}
      showModalSkills={showModalSkills}
      setShowModalSkills={setShowModalSkills}
      showModalAward={showModalAward}
      setShowModalAward={setShowModalAward}
      setDataAutoSave={setDataAutoSave}
      skillData={skillData}
      setSkillData={setSkillData}
      experienceSuggestionModalData={experienceSuggestionModalData}
      showExperienceSuggestionModal={showExperienceSuggestionModal}
      setExperienceSuggestionModalData={setExperienceSuggestionModalData}
      setShowExperienceSuggestionModal={setShowExperienceSuggestionModal}
      showPreviewModal={showPreviewModal}
      setShowPreviewModal={setShowPreviewModal}
      profilePreview={profilePreview}
      updateWorkProfile={updateWorkProfile}
      showModalShare={showModalShare}
      setShowModalShare={setShowModalShare}
      fileRef={fileRef}
      currentLocation={currentLocation}
      locationSuggestion={locationSuggestion}
      masterData={masterData}
      summary={summary}
      updateSummary={updateSummary}
      years={years}
      months={months}
      skill={skill}
      setSkill={setSkill}
      isOpen={isOpen}
      closeModal={closeModal}
      links={links}
      setLinks={setLinks}
      color={color}
      normalizePhoneNumber1={normalizePhoneNumber1}
      dropdownOptions={dropdownOptions}
      onChange={onChange}
      locationHandler={locationHandler}
      onLocationClick={onLocationClick}
      setLocationSuggestion={setLocationSuggestion}
      experienceHandler={experienceHandler}
      sortArray={sortArray}
      month={month}
      deleteEmployment={deleteEmployment}
      deleteDegree={deleteDegree}
      deleteCertificate={deleteCertificate}
      deleteAward={deleteAward}
      uploadMedia={uploadMedia}
      deleteResume={deleteResume}
      onOpen={onOpen}
      navigate={navigate}
      triggerSelect={triggerSelect}
      triggerClickDown={triggerClickDown}
      skillChildRef={skillChildRef}
      industryDomainSuggestion={industryDomainSuggestion}
      userIndustryDomainName={userIndustryDomainName}
      userIndustryDomain={userIndustryDomain}
      onChangeHandlerIndustryDomain={onChangeHandlerIndustryDomain}
      onIndustryDomainClick={onIndustryDomainClick}
      deleteIndustryDomain={deleteIndustryDomain}
    />
  );
}
export default WorkProfile;
