import * as storageConstants from "../constants/storageConstants";
import * as commonServices from "../services/common";

const masterData = commonServices.getLocalData(storageConstants.DATA);

export const getChipsNameFromMasterData = (type, data) => {
    
    if (masterData) {
        return masterData[type]?.find(arrValue => arrValue.id === data)?.name;

    } else {
        return "";
    }
}

export const returnNewPayloadArray = (array, data, action) => {
    if (action === 'a') {
        if (!array.includes(data)) {
            array.push(data);
        }
        return array;
    } else if (action === 'r') {
        if (array.includes(data)) {
            array = array.filter(item => item !== data);
        }
        return array;
    }
}

export const getSkillChipsName = (id) => {
    if (id) {
        const skillData = commonServices.getLocalData(storageConstants.SKILLS);
        return skillData?.length > 0 && skillData.filter(s => s.id === id)[0]?.name;
    }
}

export const getLocationChipsName = (id) => {
    if (id) {
        const locationData = commonServices.getLocalData(storageConstants.LOCATIONS);
        return locationData?.length > 0 && locationData.filter(l => l.id === id)[0]?.name;
    }
}
