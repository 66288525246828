import React, { useEffect, useState, useRef } from "react";
import "./AddJob.scss";
import * as storageConstants from "@/constants/storageConstants";
import * as toast from "@/wrapper/toast";
import * as erros from "@/constants/stringConstants";
import * as ApiHelper from "@/services/apiHelper";
import { useNavigate, useParams } from "react-router-dom";
import { css } from "@emotion/react";
import * as commonServices from "@/services/common";
import { LOCATION_THRESHOLD, USER_ROLE } from "@/constants/storageConstants";
import { COMPANY_ADMIN_ROLE, COMPANY_USER_ROLE } from "../../constants/roleConstants";
import { PUBLISHED, DRAFT } from "@/constants/JobStatusConstants";
import { addDataOnFocusOut } from "@/services/common";
import AddJobLayouts from "./AddJobLayouts";
import { isNullOrEmpty, stringCompare, stripHTMLTags } from "../../utils/stringUtils";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function AddJob() {
  var incentives = ["Monthly", "Quarterly", "Half Yearly", "Yearly"];

  const navigate = useNavigate();
  const profile = commonServices.getLocalData(storageConstants.PROFILE);
  const masterData = commonServices.getLocalData(storageConstants.DATA);

  let [loading, setLoading] = useState(false);
  let [showReferralModel, setShowReferralModel] = useState(false);
  let [color] = useState("#000000");

  const [locSuggestion, setLocSuggestion] = useState();
  const [preferred_location, setPreferred_location] = useState([]);

  const [preferredLocationName, setPreferredLocationName] = useState([]);

  const [skillSuggestion, setSkillSuggestion] = useState([]);
  const [skills, setSkills] = useState([]);

  const [additionalSkillSuggestion, setAdditionalSkillSuggestion] = useState(
    []
  );
  const [additionalSkills, setAdditionalSkills] = useState([]);
  const [boostId, setBoostId] = useState("");

  const [jobTitle, setJobTitle] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [jobType, setJobType] = useState("");
  const [engagementMode, setengagementMode] = useState("");
  const [minimumExperience, setMinimumExperience] = useState("");
  const [maximumExperience, setMaximumExperience] = useState("");
  const [totalPositions, setTotalPositions] = useState(1);
  const [education, setEducation] = useState("");
  const [bonus, setBonus] = useState(null);
  const [bonusText, setBonusText] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [salaryMin, setSalaryMin] = useState("");
  const [salaryMax, setSalaryMax] = useState("");
  const [benefits, setBenefits] = useState([]);
  const [salaryType, setSalaryType] = useState("");
  const [industryDomain, setIndustryDomain] = useState("");
  const [workAuthorization, setWorkAuthorization] = useState("");
  const [joiningPreference, setJoiningPreference] = useState("");
  const [jobDuration, setJobDuration] = useState("");
  const [telecommute, setTelecommute] = useState("0");
  const [travelRequired, setTravelRequired] = useState("0");
  const [travelPercentage, setTravelPercentage] = useState(0);
  const [preferredSkillName, setPreferredSkillName] = useState([]);
  const [preferredAdditionalSkillName, setPreferredAdditionalSkillName] =
    useState([]);
  const [statusType, setStatusType] = useState("");
  const [currentTab, setCurrentTab] = useState("details");
  let [descriptionLoading, setDescriptionLoading] = useState(false);

  let skillRef = useRef();
  let skillRef1 = useRef();
  let locationRef = useRef();
  let titleRef = useRef();
  let referenceRef = useRef();
  let jobTypeRef = useRef();
  let engagementModeRef = useRef();
  let locationTextRef = useRef();
  let bonusTextRef = useRef();
  let skillTextRef = useRef();
  let minExpRef = useRef();
  let maxExpRef = useRef();
  let totalPositionsRef = useRef();
  let salaryTypeRef = useRef();
  let minSalaryRef = useRef();
  let maxSalaryRef = useRef();
  let industryDomainRef = useRef();
  let joiningPreferenceRef = useRef();
  let jobDurationRef = useRef();
  let travelPercentRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!skillRef.current.contains(event.target)) {
        if (skillSuggestion.length > 0) {
          addDataOnFocusOut(
            skillSuggestion,
            setSkillSuggestion,
            setSkills,
            setPreferredSkillName
          );
        } else {
          addSkillsOrgCall();
        }

        document.getElementById("skills").value = "";
        setAddSkillElementOrg("");
      }
      if (!skillRef1.current.contains(event.target)) {
        if (additionalSkillSuggestion.length > 0) {
          addDataOnFocusOut(
            additionalSkillSuggestion,
            setAdditionalSkillSuggestion,
            setAdditionalSkills,
            setPreferredAdditionalSkillName
          );
        } else {
          addSkillCall();
        }
        document.getElementById("additional_skills").value = "";
        setAddSkillElement("");
      }
      if (!locationRef.current.contains(event.target)) {
        addDataOnFocusOut(
          locSuggestion,
          setLocSuggestion,
          setPreferred_location,
          setPreferredLocationName
        );
        document.getElementById("search_location").value = "";
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const onChangeHandler_location = (text) => {
    if (text.length > LOCATION_THRESHOLD) {
      commonServices.getLocations(text).then((locations) => {
        setLocSuggestion(locations);
      });
    } else {
      setLocSuggestion([]);
    }
  };

  const del_location = async (e, data) => {
    e.preventDefault();
    var arrow = preferred_location;
    await setPreferred_location([]);
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    setPreferred_location(arrow);
  };

  const onChangeHandlerSkills = (text) => {
    if (text.length > storageConstants.SKILL_THRESHOLD) {
      commonServices.getSkillByName(text).then((skills) => {
        setSkillSuggestion(skills);
      });
    } else {
      setSkillSuggestion([]);
    }
  };

  const removeSkill = async (e, data) => {
    e.preventDefault();
    var arrow = skills;
    await setSkills("");
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    setSkills(arrow);
  };

  const onChangeHandlerAdditionalSkills = (text) => {
    if (text.length > storageConstants.SKILL_THRESHOLD) {
      commonServices.getSkillByName(text).then((skills) => {
        setAdditionalSkillSuggestion(skills);
      });
    } else {
      setAdditionalSkillSuggestion([]);
    }
  };

  const removeAdditionalSkill = async (e, data) => {
    e.preventDefault();
    var arrow = additionalSkills;
    await setAdditionalSkills("");
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    setAdditionalSkills(arrow);
  };

  let params = useParams();
  const job_id = params.job_id;

  useEffect(() => {
    if (typeof job_id !== "undefined") {
      setLoading(true);
      ApiHelper.getJobDetail(job_id).then((response) => {
        if (response.isSuccess === true) {
          var data = response.data;
          if (parseInt(data.user_id) !== profile.id) {
            navigate(-1);
            const role = commonServices.getLocalData(USER_ROLE);
            if (role === COMPANY_ADMIN_ROLE) {
              toast.warn("This Job is posted by a Company User");
            } else if (role === COMPANY_USER_ROLE) {
              toast.warn("This Job is posted by a Company Admin");
            }
          } else {
            setJobTitle(data.title);
            setReferenceNumber(data.reference_number);
            setJobDescription(data.description !== null ? data.description : "");
            setJobType(data.job_type_id);
            setengagementMode(data.engagement_mode_id);
            setTotalPositions(data.total_positions);
            setEducation(data.education);
            if (data?.bonus !== null) {
              if (incentives.includes(data?.bonus)) {
                setBonus(data?.bonus);
              } else {
                setBonus("Other");
                setBonusText(data?.bonus);
              }
            }

            setMinimumExperience(data.minimum_experience_required / 12);
            setMaximumExperience(data.maximum_experience_required / 12);
            setSalaryMin(data.min_salary);
            setSalaryMax(data.max_salary);
            setSalaryType(data.salary_type_id);
            setIndustryDomain(data.industry_domain_id);
            setWorkAuthorization(data.work_authorization_id);
            setJoiningPreference(data.joining_preference_id);
            setJobDuration(data.job_duration_id);
            setTelecommute(data.is_telecommute);
            setTravelRequired(data.is_travel_required);
            setTravelPercentage(data.travel_percentage);
            setPreferred_location(data?.locations.map((location) => location.id));
            setPreferredLocationName(data.locations ? data.locations : []);
            setSkills(data?.required_skills.map((skill) => skill.id));
            setAdditionalSkills(data?.additional_skills.map((skill) => skill.id));
            setPreferredSkillName(
              data.required_skills ? data.required_skills : []
            );
            setPreferredAdditionalSkillName(
              data.additional_skills ? data.additional_skills : []
            );
            setBenefits(
              data.job_benefit_ids[0] !== "" ? data.job_benefit_ids : []
            );
            setStatusType(data.status);
          }
        } else {
          toast.warn(response.message);
        }
        setLoading(false);
      });
    }
  }, []);

  var jobData = {
    title: jobTitle,
    reference_number: referenceNumber,
    description: jobDescription,
    job_type_id: jobType,
    engagement_mode_id: engagementMode,
    work_locations: preferred_location,
    required_skills: skills,
    additional_skills: additionalSkills,
    min_salary: commonServices.removeCommaFromAmount(salaryMin),
    max_salary: commonServices.removeCommaFromAmount(salaryMax),
    salary_type_id: salaryType,
    benefits: benefits,
    joining_preference_id: joiningPreference,
    work_authorization_id: workAuthorization,
    industry_domain_id: industryDomain,
    is_telecommute: telecommute,
    minimum_experience_required: minimumExperience * 12,
    maximum_experience_required: maximumExperience * 12,
    is_travel_required: travelRequired,
    travel_percentage: travelPercentage,
    job_duration_id: jobDuration,
    total_positions: totalPositions,
    education: education,
    bonus: bonus !== "Other" ? bonus : bonusText,
    eeo: "0",
  };

  const handleBenefits = (e) => {
    let isChecked = e.target.checked;
    let selectedBenefit = e.target.value;
    if (isChecked) {
      setBenefits([...benefits, selectedBenefit]);
    } else {
      var array = [...benefits];
      const index = array.indexOf(selectedBenefit);
      array.splice(index, 1);
      setBenefits(array);
    }
  };

  const handleSalaryType = (e) => {
    let isChecked = e.target.checked;
    let selectedSalaryType = e.target.value;
    if (isChecked) {
      setSalaryType([...salaryType, selectedSalaryType]);
    } else {
      var array = [...salaryType];
      const index = array.indexOf(selectedSalaryType);
      array.splice(index, 1);
      setSalaryType(array);
    }
  };

  const isCompleteData = () => {
    let status = true;

    status = isCompleteDataStep1();
    if (salaryType === "") {
      status = false;
    } else if (salaryMin === "") {
      status = false;
    } else if (salaryMax === "") {
      status = false;
    } else if (
      commonServices.removeCommaFromAmount(salaryMax) <=
      commonServices.removeCommaFromAmount(salaryMin)
    ) {
      status = false;
    } else if (bonus !== null) {
      if (bonus === "Other" && bonusText === "") {
        status = false;
      }
    }
    return status;
  };

  const isCompleteDataStep1 = () => {
    let status = true;
    if (jobTitle === "") {
      status = false;
    } else if (jobTitle.length > 100) {
      status = false;
    } else if (referenceNumber !== null && referenceNumber.length > 30) {
      status = false;
    } else if (jobType === "") {
      status = false;
    } else if (preferred_location.length === 0) {
      status = false;
    } else if (skills.length === 0) {
      status = false;
    } else if (minimumExperience === "") {
      status = false;
    } else if (maximumExperience === "") {
      status = false;
    } else if (parseInt(maximumExperience) < parseInt(minimumExperience)) {
      status = false;
    } else if (industryDomain === "") {
      status = false;
    } else if (joiningPreference === "") {
      status = false;
    } else if (jobDuration === "") {
      status = false;
    } else if (
      travelRequired === "1" &&
      (travelPercentage < 1 || travelPercentage > 100)
    ) {
      status = false;
    }
    return status;
  };

  const validateDataStep1 = () => {
    let status = true;
    if (jobTitle === "") {
      titleRef.current.focus();
      status = false;
      toast.warn(erros.JOB_TITLE_REQUIRED);
    } else if (jobTitle.length > 100) {
      titleRef.current.focus();
      status = false;
      toast.warn(erros.JOB_TITLE_LIMIT_ERROR);
    } else if (referenceNumber !== null && referenceNumber.length > 30) {
      referenceRef.current.focus();
      status = false;
      toast.warn(erros.REFERENCE_NUMBER_LIMIT_ERROR);
    } else if (isNullOrEmpty(jobType)) {
      jobTypeRef.current.focus();
      status = false;
      toast.warn(erros.JOB_TYPE_REQUIRED);
    } else if (preferred_location.length === 0) {
      locationTextRef.current.focus();
      status = false;
      toast.warn(erros.PRE_LOCATION_REQUIRED);
    } else if (totalPositions === "") {
      totalPositionsRef.current.focus();
      status = false;
      toast.warn(erros.TOTAL_POSITIONS_REQUIRED_ERROR);
    } else if (parseInt(totalPositions) < 1) {
      totalPositionsRef.current.focus();
      status = false;
      toast.warn(erros.TOTAL_POSITIONS_LIMIT_ERROR);
    } else if (skills.length === 0) {
      skillTextRef.current.focus();
      status = false;
      toast.warn(erros.GIG_SKILL_REQUIRED);
    } else if (minimumExperience === "") {
      minExpRef.current.focus();
      status = false;
      toast.warn(erros.MIN_EXPERIENCE_REQUIRED);
    } else if (maximumExperience === "") {
      maxExpRef.current.focus();
      status = false;
      toast.warn(erros.MAX_EXPERIENCE_REQUIRED);
    } else if (parseInt(maximumExperience) < parseInt(minimumExperience) || parseInt(maximumExperience) <= 0) {
      minExpRef.current.focus();
      status = false;
      toast.warn(erros.MIN_MAX_EXPERIENCE_ERROR);
    } else if (isNullOrEmpty(industryDomain)) {
      industryDomainRef.current?.focus();
      status = false;
      toast.warn(erros.INDUSTRY_REQUIRED);
    } else if (isNullOrEmpty(joiningPreference)) {
      status = false;
      toast.warn(erros.JOINING_PREFERENCE_REQUIRED);
      joiningPreferenceRef.current?.focus();
    } else if (isNullOrEmpty(jobDuration)) {
      status = false;
      toast.warn(erros.JOB_DURATION_REQUIRED);
      jobDurationRef.current?.focus();
    } else if (
      travelRequired === "1" &&
      (travelPercentage < 1 || travelPercentage > 100)
    ) {
      travelPercentRef.current.focus();
      status = false;
      toast.warn(erros.INVALID_TRAVEL_PERCENTAGE);
    }
    return status;
  };

  const validateDataStep2 = () => {
    let status = true;
    if (isNullOrEmpty(salaryType)) {
      salaryTypeRef.current.focus();
      status = false;
      toast.warn(erros.SALARY_TYPE_REQUIRED);
    } else if (salaryMin === "") {
      minSalaryRef.current.focus();
      status = false;
      toast.warn(erros.MIN_SALARY_REQUIRED);
    } else if (salaryMax === "") {
      maxSalaryRef.current.focus();
      status = false;
      toast.warn(erros.MAX_SALARY_REQUIRED);
    } else if (
      commonServices.removeCommaFromAmount(salaryMax) <
      commonServices.removeCommaFromAmount(salaryMin)
    ) {
      minSalaryRef.current.focus();
      status = false;
      toast.warn(erros.MIN_MAX_SALARY_ERROR);
    } else if (bonus === "Other" && isNullOrEmpty(bonusText)) {
      status = false;
      toast.warn(erros.BONUS_REQUIRED);
      bonusTextRef.current.focus();
    }

    return status;
  };

  const saveJob = (status = DRAFT) => {
    var isError = 0;
    if (status === DRAFT) {
      if (jobTitle === "") {
        isError = 1;
        toast.warn(erros.JOB_TITLE_REQUIRED);
      }
    }
    if (status === PUBLISHED) {
      if (!validateDataStep1()) {
        isError = 1;
      }
      if (!validateDataStep2()) {
        isError = 1;
      }
      if (isNullOrEmpty(stripHTMLTags(jobDescription))) {
        isError = 1;
        toast.warn(erros.JOB_DESCRIPTION_REQUIRED);
      }
      if (stripHTMLTags(jobDescription).length > 3000) {
        isError = 1;
        toast.warn('Max 3000 Characters');
      }
    }

    if (isError === 0) {
      setLoading(true);
      if (typeof job_id !== "undefined") {
        jobData.job_id = job_id;
      }
      jobData.status = status;
      ApiHelper.addEditJob(jobData).then((response) => {
        setLoading(false);
        if (response.isSuccess) {
          const params = new URLSearchParams(window.location.search);
          toast.success(response.message);
          if ((typeof job_id === "undefined" || params.get('action') === 'boostJob') && status === PUBLISHED) {
            setBoostId(response.data.eid);
            setShowReferralModel(true);
          } else {
            navigate("/posted");
          }
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const nextStep = (status = DRAFT) => {
    if (currentTab === "details") {
      if (status === PUBLISHED && validateDataStep1()) {
        setCurrentTab("salary");
        window.scrollTo(0, 0);
      } else if (status === DRAFT) {
        setCurrentTab("salary");
        window.scrollTo(0, 0);
      }
    }

    if (currentTab === "salary") {
      if (status === PUBLISHED && validateDataStep2()) {
        setCurrentTab("description");
        window.scrollTo(0, 0);
      } else if (status === DRAFT) {
        setCurrentTab("description");
        window.scrollTo(0, 0);
      }
    }
  };

  const [addSkillElement, setAddSkillElement] = useState("");

  const addSkills = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      addSkillCall();
      event.target.value = "";
    }
  };

  const addSkillCall = () => {
    if (
      addSkillElement !== "" &&
      addSkillElement !== undefined &&
      additionalSkillSuggestion < 1
    ) {
      const formData = new FormData();
      formData.append("name", addSkillElement);
      setLoading(true);

      ApiHelper.addSkill(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          response.data.forEach((element) => {
            if (stringCompare(addSkillElement, element.name)) {
              setAdditionalSkills([...additionalSkills, element.id]);
              setPreferredAdditionalSkillName([
                ...preferredAdditionalSkillName,
                element,
              ]);
            }
          });
          document.getElementById("additional_skills").value = "";
          setAddSkillElement("");
          onChangeHandlerAdditionalSkills(" ");
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const [addSkillElementOrg, setAddSkillElementOrg] = useState("");

  const addSkillsOrg = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      addSkillsOrgCall();
      event.target.value = "";
    }
  };

  const addSkillsOrgCall = () => {
    if (
      addSkillElementOrg !== "" &&
      addSkillElementOrg !== undefined &&
      skillSuggestion.length < 1
    ) {
      const formData = new FormData();
      formData.append("name", addSkillElementOrg);
      setLoading(true);

      ApiHelper.addSkill(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          response.data.forEach((element) => {
            if (stringCompare(addSkillElementOrg, element.name)) {
              setSkills([...skills, element.id]);
              setPreferredSkillName([...preferredSkillName, element]);
            }
          });
          document.getElementById("skills").value = "";

          onChangeHandlerSkills(" ");
          setAddSkillElementOrg("");
        } else {
          toast.warn(response.message);
        }
      });
    }
  };

  const getAIDescription = () => {
    setDescriptionLoading(true);
    var locationStr = "";
    preferredLocationName.map((data, k) => {
      if (
        preferred_location.includes(data.id) ||
        preferred_location.includes(data.id)
      ) {
        locationStr += "&location[]=" + data.name;
      }
    });

    var benefitStr = "";
    masterData.benefits.map((benefit, i) => {
      if (benefits.indexOf(benefit.id.toString()) !== -1) {
        benefitStr += "&benefit[]=" + benefit.name;
      }
    });

    var queryString =
      "?type=job&title=" +
      jobTitle +
      "&minExp=" +
      minimumExperience +
      " Years" +
      "&maxExp=" +
      maximumExperience +
      " Years" +
      locationStr +
      benefitStr;
    ApiHelper.getDescriptionByChatGPT(queryString).then((response) => {
      if (response.isSuccess === true) {
        setDescriptionLoading(false);
        setJobDescription(response.data.description);
      } else {
        setDescriptionLoading(false);
        toast.warn(response.message);
      }
    });
  };

  const triggerClickDown = (event) => {
    const list = [...document.querySelectorAll(".sugg_p")];
    const active = document.querySelector(".sugg_p.active");
    let i = list.indexOf(active);

    if (event.which === 40 || event.keyCode === 40) {
      if (active) {
        active.classList.remove("active");
      }

      const c = document.getElementsByClassName("sugg_p hand-hover");

      if (c.length > 0) {
        if (c[i + 1]) {
          c[i + 1].classList.add("active");
          c[i + 1].scrollIntoView({ block: "nearest", inline: "nearest" });
        }
      }
    } else if (event.which === 27 || event.keyCode === 27) {
      setLocSuggestion([]);
      setSkillSuggestion([]);
      setAdditionalSkillSuggestion([]);
    } else if (event.which === 9 || event.keyCode === 9) {
      if (event.target.name === "skills") {
        if (skillSuggestion.length > 0) {
          addDataOnFocusOut(
            skillSuggestion,
            setSkillSuggestion,
            setSkills,
            setPreferredSkillName
          );
          setAddSkillElementOrg("");
        } else {
          addSkillsOrgCall();
        }
        event.target.value = "";
      } else if (event.target.name === "additionalSkills") {
        if (additionalSkillSuggestion.length > 0) {
          addDataOnFocusOut(
            additionalSkillSuggestion,
            setAdditionalSkillSuggestion,
            setAdditionalSkills,
            setPreferredAdditionalSkillName
          );
          setAddSkillElement("");
        } else {
          addSkillCall();
        }
        event.target.value = "";
      } else if (event.target.name === "location") {
        addDataOnFocusOut(
          locSuggestion,
          setLocSuggestion,
          setPreferred_location,
          setPreferredLocationName
        );
        event.target.value = "";
      }
    }
  };

  const triggerClickUp = (event) => {
    const list = [...document.querySelectorAll(".sugg_p")];
    const active = document.querySelector(".sugg_p.active");
    let i = list.indexOf(active);
    if (event.which === 38 || event.keyCode === 38) {
      if (active) {
        active.classList.remove("active");
      }
      const c = document.getElementsByClassName("sugg_p hand-hover");

      if (c.length > 0) {
        if (c[i - 1]) {
          c[i - 1].classList.add("active");
          c[i - 1].scrollIntoView({ block: "nearest", inline: "nearest" });
        }
      }
    }
  };

  const triggerSelect = (event) => {
    const list = [...document.querySelectorAll(".sugg_p")];
    const active = document.querySelector(".sugg_p.active");
    let i = list.indexOf(active);

    if (event.which === 13 || event.keyCode === 13) {
      const c = document.getElementsByClassName("sugg_p hand-hover");

      if (active) {
        active.click();
      }
    }
  };

  return (
    <AddJobLayouts
      override={override}
      showReferralModel={showReferralModel}
      setShowReferralModel={setShowReferralModel}
      boostId={boostId}
      loading={loading}
      color={color}
      job_id={job_id}
      currentTab={currentTab}
      statusType={statusType}
      saveJob={saveJob}
      PUBLISHED={PUBLISHED}
      nextStep={nextStep}
      DRAFT={DRAFT}
      handleBenefits={handleBenefits}
      setJobDescription={setJobDescription}
      jobDescription={jobDescription}
      descriptionLoading={descriptionLoading}
      getAIDescription={getAIDescription}
      jobTitle={jobTitle}
      bonus={bonus}
      bonusTextRef={bonusTextRef}
      bonusText={bonusText}
      setBonusText={setBonusText}
      masterData={masterData}
      benefits={benefits}
      commonServices={commonServices}
      setSalaryMax={setSalaryMax}
      minSalaryRef={minSalaryRef}
      setSalaryType={setSalaryType}
      travelPercentage={travelPercentage}
      travelPercentRef={travelPercentRef}
      telecommute={telecommute}
      setJobDuration={setJobDuration}
      jobDuration={jobDuration}
      setMinimumExperience={setMinimumExperience}
      minimumExperience={minimumExperience}
      minExpRef={minExpRef}
      removeAdditionalSkill={removeAdditionalSkill}
      additionalSkills={additionalSkills}
      preferredAdditionalSkillName={preferredAdditionalSkillName}
      addSkillElement={addSkillElement}
      additionalSkillSuggestion={additionalSkillSuggestion}
      skillRef1={skillRef1}
      setAddSkillElement={setAddSkillElement}
      addSkills={addSkills}
      triggerSelect={triggerSelect}
      triggerClickDown={triggerClickDown}
      triggerClickUp={triggerClickUp}
      setPreferredAdditionalSkillName={setPreferredAdditionalSkillName}
      onChangeHandlerAdditionalSkills={onChangeHandlerAdditionalSkills}
      incentives={incentives}
      setCurrentTab={setCurrentTab}
      validateDataStep1={validateDataStep1}
      isCompleteDataStep1={isCompleteDataStep1}
      validateDataStep2={validateDataStep2}
      isCompleteData={isCompleteData}
      setBonus={setBonus}
      salaryMax={salaryMax}
      maxSalaryRef={maxSalaryRef}
      setSalaryMin={setSalaryMin}
      salaryMin={salaryMin}
      salaryType={salaryType}
      salaryTypeRef={salaryTypeRef}
      setTravelPercentage={setTravelPercentage}
      setTravelRequired={setTravelRequired}
      setTelecommute={setTelecommute}
      jobDurationRef={jobDurationRef}
      setJoiningPreference={setJoiningPreference}
      joiningPreference={joiningPreference}
      joiningPreferenceRef={joiningPreferenceRef}
      setIndustryDomain={setIndustryDomain}
      industryDomain={industryDomain}
      industryDomainRef={industryDomainRef}
      setMaximumExperience={setMaximumExperience}
      maxExpRef={maxExpRef}
      setAdditionalSkills={setAdditionalSkills}
      removeSkill={removeSkill}
      skills={skills}
      preferredSkillName={preferredSkillName}
      addSkillElementOrg={addSkillElementOrg}
      setAddSkillElementOrg={setAddSkillElementOrg}
      onChangeHandlerSkills={onChangeHandlerSkills}
      setPreferredSkillName={setPreferredSkillName}
      skillSuggestion={skillSuggestion}
      addSkillsOrg={addSkillsOrg}
      skillTextRef={skillTextRef}
      skillRef={skillRef}
      setTotalPositions={setTotalPositions}
      totalPositions={totalPositions}
      totalPositionsRef={totalPositionsRef}
      setEducation={setEducation}
      education={education}
      del_location={del_location}
      preferred_location={preferred_location}
      preferredLocationName={preferredLocationName}
      locSuggestion={locSuggestion}
      titleRef={titleRef}
      setJobTitle={setJobTitle}
      referenceRef={referenceRef}
      referenceNumber={referenceNumber}
      setReferenceNumber={setReferenceNumber}
      jobTypeRef={jobTypeRef}
      jobType={jobType}
      setJobType={setJobType}
      engagementModeRef={engagementModeRef}
      engagementMode={engagementMode}
      setengagementMode={setengagementMode}
      locationRef={locationRef}
      locationTextRef={locationTextRef}
      onChangeHandler_location={onChangeHandler_location}
      maximumExperience={maximumExperience}
      setPreferred_location={setPreferred_location}
      setSkills={setSkills}
      setPreferredLocationName={setPreferredLocationName}
    />
  );
}

export default AddJob;
