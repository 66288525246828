import ClipLoader from "react-spinners/ClipLoader";
import { CURRENCY } from "@/constants/storageConstants";
import Editor from "@/components/Common/Editor";
import { redirectBack } from "@/utils/redirectUtil";
import arrowLeft from "@/Assets/svgs/arrow-left.svg";
import { PuffLoader } from "react-spinners";
import { sortArrayByName } from "@/utils/arrayUtils";
import {
  KEYBOARD_SHORTCUT_TEXT,
  ADD_SKILL_SUGGESTION,
} from "@/constants/stringConstants";
import ModalJobReferral from "@/components/Modals/ModalJobReferral";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import Loader from "../_widgets/Loader/Loader";

function AddJobLayouts({ override, showReferralModel, setShowReferralModel, boostId, loading, color, job_id, currentTab, statusType, saveJob, PUBLISHED, nextStep, DRAFT, handleBenefits, setJobDescription, jobDescription, descriptionLoading, getAIDescription, jobTitle, bonus, bonusTextRef, bonusText, setBonusText, masterData, benefits, commonServices, setSalaryMax, minSalaryRef, setSalaryType, travelPercentage, travelPercentRef, telecommute, setJobDuration, jobDuration, setMinimumExperience, minimumExperience, minExpRef, removeAdditionalSkill, additionalSkills, preferredAdditionalSkillName, addSkillElement, additionalSkillSuggestion, skillRef1, setAddSkillElement, addSkills, triggerSelect, triggerClickDown, triggerClickUp, setPreferredAdditionalSkillName, onChangeHandlerAdditionalSkills, incentives, setCurrentTab, validateDataStep1, isCompleteDataStep1, validateDataStep2, isCompleteData, setBonus, salaryMax, maxSalaryRef, setSalaryMin, salaryMin, salaryType, salaryTypeRef, setTravelPercentage, setTravelRequired, setTelecommute, jobDurationRef, setJoiningPreference, joiningPreference, joiningPreferenceRef, setIndustryDomain, industryDomain, industryDomainRef, setMaximumExperience, maxExpRef, setAdditionalSkills, removeSkill, skills, preferredSkillName, addSkillElementOrg, setAddSkillElementOrg, onChangeHandlerSkills, setPreferredSkillName, skillSuggestion, addSkillsOrg, skillTextRef, skillRef, setTotalPositions, totalPositions, totalPositionsRef, setEducation, education, del_location, preferred_location, preferredLocationName, locSuggestion, titleRef, setJobTitle, referenceRef, referenceNumber, setReferenceNumber, jobTypeRef, jobType, setJobType, engagementModeRef, engagementMode, setengagementMode, locationRef, locationTextRef, onChangeHandler_location, maximumExperience, setPreferred_location, setSkills, setPreferredLocationName

}) {
  const handleBenefitsChild = (e) => {
    handleBenefits(e);
  };
  const nextStepChild = (status) => {
    nextStep(status);
  };
  const saveJobChild = (status) => {
    saveJob(status);
  };
  const setCurrentTabChild = (status) => {
    setCurrentTab(status);
  };

  return (
    <div className="add_job tt-container">
      <ModalJobReferral
        showModal={showReferralModel}
        setShowModal={setShowReferralModel}
        id={boostId}
      />
      {loading ? (
        <Loader />
      ) : null}


      <div className="content">
        <div className="mb-4">
          <div className="d-flex align-items-center justify-content-between">
            <div >
              <h2
                className="main_t"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}>
                <a className="hand-hover" onClick={() => redirectBack()}>

                  <img className="back-arrow" src={arrowLeft}></img>
                </a>
                {typeof job_id !== "undefined" ? "Edit" : "Add New"} Job
              </h2>
            </div>
            <div>
              <div className="form_buttons">
                {job_id !== undefined ? (
                  currentTab === "description" ? (
                    <>
                      <button className="btn" onClick={() => saveJobChild()}>
                        Save Changes
                      </button>
                      <input
                        className="btn"
                        type="submit"
                        onClick={() => saveJobChild(PUBLISHED)}
                        value={"Publish Changes"}
                      />
                    </>
                  ) : (<div>
                    <button className="btn" onClick={() => saveJobChild()}>
                      Save Changes
                    </button>
                    <input
                      className="btn"
                      type="submit"
                      onClick={() => {
                        nextStepChild(PUBLISHED);
                      }}
                      value={"Next"}
                    />
                  </div>
                  )
                ) : (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => saveJobChild()}>
                      Save as Draft
                    </button>
                    {currentTab === "description" ? (
                      <input
                        className="btn"
                        type="submit"
                        onClick={(e) => saveJobChild(PUBLISHED)}
                        value={"Publish Job"}
                      />
                    ) : (
                      <input
                        className="btn"
                        type="submit"
                        onClick={() => {
                          nextStepChild(PUBLISHED);
                        }}
                        value={"Next"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="list-group mb-5" id="list-tab" role="tablist">
          <a
            className={
              currentTab === "details"
                ? "list-group-item list-group-item-action active"
                : "list-group-item list-group-item-action"
            }
            onClick={() => setCurrentTabChild("details")}
            id="list-details-list"
            data-bs-toggle="list"
            href="#list-details"
            role="tab"
            aria-controls="list-details">
            1 - Job Details
          </a>
          <a
            className={
              currentTab === "salary"
                ? "list-group-item list-group-item-action active"
                : "list-group-item list-group-item-action"
            }
            onClick={() => {
              if (validateDataStep1()) {
                setCurrentTabChild("salary");
              }
            }}
            id={isCompleteDataStep1() ? "list-salary-list" : "tab-disbaled"}
            data-bs-toggle="list"
            href="#list-salary"
            role="tab"
            aria-controls="list-salary">
            2 - Salary and Benefits
          </a>
          <a
            className={
              currentTab === "description"
                ? "list-group-item list-group-item-action active"
                : "list-group-item list-group-item-action"
            }
            onClick={() => {
              if (validateDataStep1() && validateDataStep2()) {
                setCurrentTabChild("description");
              }
            }}
            id={isCompleteData() ? "list-description-list" : "tab-disbaled"}
            data-bs-toggle="list"
            href="#list-description"
            role="tab"
            aria-controls="list-description">
            3 - Job Description
          </a>
        </div>

        <div className="tab-content" id="nav-tabContent">
          <div
            className={
              currentTab === "details"
                ? "tab-pane fade show active"
                : "tab-pane fade show"
            }
            id="list-details"
            role="tabpanel"
            aria-labelledby="list-details-list">
            <div className="row mt-4">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2 className="required">Title</h2>
                {/* <p>
                                    Describe the job details
                                </p> */}
              </div>
              <div
                className="col-lg-7 col-md-12 col-sm-12 list_profile_heading"
                style={{ display: "flex", flexDirection: "column" }}>
                <div className="input-group">
                  <input
                    ref={titleRef}
                    type="text"
                    value={jobTitle}
                    className="form-control"
                    onChange={(e) => setJobTitle(e.target.value)}
                    id="search_job_type"
                    placeholder="Job Title"
                  />
                </div>
                <p
                  className="right"
                  style={{ display: "flex", justifyContent: "flex-end" }}>
                  Max 100 characters
                </p>
              </div>
              <div className="col-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2>Reference Number</h2>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12 list_profile_heading">
                <div className="input-group">
                  <input
                    ref={referenceRef}
                    type="text"
                    value={referenceNumber}
                    className="form-control"
                    onChange={(e) => setReferenceNumber(e.target.value)}
                    id="reference_number"
                    placeholder="Internal reference number/notes"
                  />
                </div>
                <p className="right">Max 30 characters</p>
              </div>
              <div className="col-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2 className="required">Job Type</h2>
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12">
                {masterData.job_types &&
                  masterData.job_types.map((job_type, i) => {
                    return (
                      <div key={i} className="form-check radio-check">
                        <input
                          ref={jobTypeRef}
                          name="job-type"
                          id={"job_type" + job_type.id}
                          className="form-check-input"
                          type="radio"
                          checked={jobType === job_type.id ? true : false}
                          onChange={() => setJobType(job_type.id)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"job_type" + job_type.id}>
                          {job_type.name}
                        </label>
                      </div>
                    );
                  })}
              </div>

              <div className="col-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2>Engagement Mode</h2>
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12">
                {masterData.engagement_mode &&
                  masterData.engagement_mode.map((engagement_mode, i) => {
                    return (
                      <div key={i} className="form-check radio-check">
                        <input
                          ref={engagementModeRef}
                          name="work-mode"
                          id={"engagement_mode" + engagement_mode.id}
                          className="form-check-input"
                          type="radio"
                          checked={engagementMode === engagement_mode.id ? true : false}
                          onChange={() => setengagementMode(engagement_mode.id)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"engagement_mode" + engagement_mode.id}>
                          {engagement_mode.name}
                        </label>
                      </div>
                    );
                  })}
              </div>

              <div className="col-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2 className="required">Job Location</h2>
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12" ref={locationRef}>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    <img
                      className="input-icon"
                      onError={commonServices.imgError}
                      src="../../Assets/svgs/map_pin.svg"
                      alt=""
                    />
                  </span>
                  <input
                    ref={locationTextRef}
                    type="text"
                    className="form-control"
                    id="search_location"
                    autoComplete="off"
                    placeholder="Type to search for location"
                    onChange={(e) => onChangeHandler_location(e.target.value)}
                    name="location"
                    onKeyPress={(e) => {
                      triggerSelect(e);
                    }}
                    onKeyDown={(e) => triggerClickDown(e)}
                    onKeyUp={(e) => triggerClickUp(e)}
                  />
                </div>
                <div className="suggestion_box">
                  <div>
                    {locSuggestion &&
                      locSuggestion.map((suggestion, i) => (
                        <p
                          className={
                            i === 0
                              ? "sugg_p hand-hover active"
                              : "sugg_p hand-hover"
                          }
                          key={i}
                          onClick={() => {
                            if (
                              ![...preferred_location].includes(suggestion.id)
                            ) {
                              setPreferred_location([
                                ...preferred_location,
                                suggestion.id,
                              ]);
                              setPreferredLocationName((value) => {
                                if (
                                  value.some((val) => val.id === suggestion.id)
                                ) {
                                  return [...value];
                                }
                                return [...value, suggestion];
                              });
                            }
                            document.getElementById("search_location").value =
                              "";
                            onChangeHandler_location("");
                          }}>
                          {suggestion.name}, {suggestion.description}
                        </p>
                      ))}
                  </div>
                  {locSuggestion?.length > 0 ? (
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        background: "#f5f5f5",
                        padding: "10px",
                        color: "#9398A1",
                        fontSize: "0.875em",
                      }}>
                      {KEYBOARD_SHORTCUT_TEXT}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex f_t_box">

                  {preferredLocationName ? (
                    <>

                      {preferredLocationName.map((data, k) => {
                        if (
                          preferred_location.includes(data.id) ||
                          preferred_location.includes(data.id)
                        )
                          return (
                            <div className="f_t" key={k}>
                              <p>
                                {data.name}, {data.description}
                              </p>
                              <button
                                onClick={(e) => {
                                  del_location(e, data.id);
                                }}
                                className="del_jt">
                                <img
                                  className="input-icon"
                                  src="../../Assets/svgs/x.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          );
                      })}
                    </>
                  ) : null}
                </div>
              </div>

              <div className="col-2"></div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2>Education</h2>
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="input-group">
                  <input
                    type="text"
                    value={education}
                    placeholder="Education"
                    onChange={(e) => setEducation(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2 className="required">No. of positions</h2>
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="input-group">
                  <input
                    ref={totalPositionsRef}
                    type="number"
                    min="1"
                    value={totalPositions}
                    onChange={(e) => setTotalPositions(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2 className="required">Skills and Experience</h2>
                <p>You can select multiple skills</p>
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="s_e" ref={skillRef}>
                  <label className="input-lebel" htmlFor="">
                    Required Skills
                  </label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <img
                        onError={commonServices.imgError}
                        className="input-icon"
                        src="../../Assets/svgs/search.svg"
                        alt=""
                      />
                    </span>
                    <input
                      ref={skillTextRef}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        onChangeHandlerSkills(e.target.value);
                        setAddSkillElementOrg(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        addSkillsOrg(e);
                        triggerSelect(e);
                      }}
                      id="skills"
                      placeholder="Type to search for skills"
                      name="skills"
                      onKeyDown={(e) => triggerClickDown(e)}
                      onKeyUp={(e) => triggerClickUp(e)}
                    />
                  </div>
                  <div className="suggestion_box">
                    <div>
                      {skillSuggestion &&
                        skillSuggestion.map((suggestion, i) => (
                          <p
                            className={
                              i === 0
                                ? "sugg_p hand-hover active"
                                : "sugg_p hand-hover"
                            }
                            key={i}
                            onClick={() => {
                              if (![...skills].includes(suggestion.id)) {
                                setSkills([...skills, suggestion.id]);
                                setPreferredSkillName((value) => {
                                  if (
                                    value.some(
                                      (val) => val.id === suggestion.id
                                    )
                                  ) {
                                    return [...value];
                                  }
                                  return [...value, suggestion];
                                });
                              }
                              document.getElementById("skills").value = "";
                              onChangeHandlerSkills("");
                              setAddSkillElementOrg("");
                            }}>
                            {suggestion.name} {suggestion.description}
                          </p>
                        ))}
                    </div>
                    {skillSuggestion?.length > 0 ? (
                      <div
                        style={{
                          position: "sticky",
                          bottom: 0,
                          background: "#f5f5f5",
                          padding: "10px",
                          color: "#9398A1",
                          fontSize: "0.875em",
                        }}>
                        {KEYBOARD_SHORTCUT_TEXT}
                      </div>
                    ) : addSkillElementOrg.length > 3 ? (
                      <div
                        style={{
                          position: "sticky",
                          bottom: 0,
                          background: "#f5f5f5",
                          padding: "10px",
                          color: "#9398A1",
                          fontSize: "0.875em",
                        }}>
                        {ADD_SKILL_SUGGESTION}
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex f_t_box">
                    {preferredSkillName ? (
                      <>

                        {preferredSkillName.map((data, k) => {
                          if (skills.includes(data.id))
                            return (
                              <div className="f_t" key={k}>
                                <span className="dot"></span>
                                <p>{data.name}</p>
                                <button
                                  onClick={(e) => {
                                    removeSkill(e, data.id);
                                  }}
                                  className="del_jt">
                                  <img
                                    className="input-icon"
                                    onError={commonServices.imgError}
                                    src="../../Assets/svgs/x.svg"
                                    alt=""
                                  />
                                </button>
                              </div>
                            );
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
                <br />
                <div className="s_e" ref={skillRef1}>
                  <label className="input-lebel" htmlFor="">
                    Additional Skill-Sets
                  </label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <img
                        className="input-icon"
                        src="../../Assets/svgs/search.svg"
                        alt=""
                      />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="additional_skills"
                      onChange={(e) => {
                        onChangeHandlerAdditionalSkills(e.target.value);
                        setAddSkillElement(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        addSkills(e);
                        triggerSelect(e);
                      }}
                      placeholder="Type to search for skills"
                      name="additionalSkills"
                      onKeyDown={(e) => triggerClickDown(e)}
                      onKeyUp={(e) => triggerClickUp(e)}
                    />
                  </div>
                  <div className="suggestion_box">
                    <div>
                      {additionalSkillSuggestion &&
                        additionalSkillSuggestion.map((suggestion, i) => (
                          <p
                            className={
                              i === 0
                                ? "sugg_p hand-hover active"
                                : "sugg_p hand-hover"
                            }
                            key={i}
                            onClick={() => {
                              if (
                                ![...additionalSkills].includes(suggestion.id)
                              ) {
                                setAdditionalSkills([
                                  ...additionalSkills,
                                  suggestion.id,
                                ]);
                                setPreferredAdditionalSkillName((value) => {
                                  if (
                                    value.some(
                                      (val) => val.id === suggestion.id
                                    )
                                  ) {
                                    return [...value];
                                  }
                                  return [...value, suggestion];
                                });
                              }
                              document.getElementById(
                                "additional_skills"
                              ).value = "";
                              onChangeHandlerAdditionalSkills("");
                              setAddSkillElement("");
                            }}>
                            {suggestion.name} {suggestion.description}
                          </p>
                        ))}
                    </div>
                    {additionalSkillSuggestion?.length > 0 ? (
                      <div
                        style={{
                          position: "sticky",
                          bottom: 0,
                          background: "#f5f5f5",
                          padding: "10px",
                          color: "#9398A1",
                          fontSize: "0.875em",
                        }}>
                        {KEYBOARD_SHORTCUT_TEXT}
                      </div>
                    ) : addSkillElement.length > 3 ? (
                      <div
                        style={{
                          position: "sticky",
                          bottom: 0,
                          background: "#f5f5f5",
                          padding: "10px",
                          color: "#9398A1",
                          fontSize: "0.875em",
                        }}>
                        {ADD_SKILL_SUGGESTION}
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex f_t_box">
                    {preferredAdditionalSkillName ? (
                      <>

                        {preferredAdditionalSkillName.map((data, k) => {
                          if (additionalSkills.includes(data.id))
                            return (
                              <div className="f_t" key={k}>
                                <span className="dot"></span>
                                <p>{data.name}</p>
                                <button
                                  onClick={(e) => {
                                    removeAdditionalSkill(e, data.id);
                                  }}
                                  className="del_jt">
                                  <img
                                    onError={commonServices.imgError}
                                    src="../../Assets/svgs/x.svg"
                                    alt=""
                                  />
                                </button>
                              </div>
                            );
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
                <br />

                <div className="s_e">
                  <label className="input-lebel" htmlFor="">
                    Minimum Experience Required (in years)
                  </label>
                  <div className="input-group">
                    <input
                      ref={minExpRef}
                      type="number"
                      min="0"
                      placeholder="Minimum Experience"
                      value={minimumExperience}
                      onChange={(e) => setMinimumExperience(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <br />

                <div className="s_e">
                  <label className="input-lebel" htmlFor="">
                    Maximum Experience Required (in years)
                  </label>
                  <div className="input-group">
                    <input
                      ref={maxExpRef}
                      type="number"
                      min="0"
                      placeholder="Maximum Experience"
                      value={maximumExperience}
                      onChange={(e) => setMaximumExperience(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="col-2"></div>
            </div>

            <div className="row mt-3 mb-5">
              <div className="col-md-3">
                <h2 className="required">Other Requirements</h2>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <Dropdown
                  label="Industry Domain"
                  ref={industryDomainRef}
                  value={industryDomain}
                  id="industry-domain"
                  name="industry-domain"
                  showDefaultOption={true}
                  select={sortArrayByName(masterData?.industry_domains)}
                  onChange={e => setIndustryDomain(e.target.value)}
                />
                <br />

                <div>
                  <label className="input-lebel" htmlFor="">
                    Joining Preference
                  </label>
                  <select
                    ref={joiningPreferenceRef}
                    value={joiningPreference}
                    className="form-select form-select-lg"
                    id="year"
                    onChange={(e) => setJoiningPreference(e.target.value)}>
                    <option value={""}>Select Joining Preference</option>
                    {masterData.joining_preferences &&
                      masterData.joining_preferences.map((preferences, i) => {
                        if (preferences.id != -1) {
                          return (
                            <option key={i} value={preferences.id}>
                              {preferences.name}
                            </option>
                          );
                        }
                      })}
                  </select>
                </div>

                <br />

                <div>
                  <label className="input-lebel" htmlFor="">
                    Job Duration
                  </label>
                  <select
                    ref={jobDurationRef}
                    value={jobDuration}
                    className="form-select form-select-lg"
                    id="year"
                    onChange={(e) => setJobDuration(e.target.value)}>
                    <option value={""}>Select Job Duration</option>
                    {masterData.job_durations &&
                      masterData.job_durations.map((duration, i) => {
                        return (
                          <option key={i} value={duration.id}>
                            {duration.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <br />
                {/* <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <label className="input-lebel-switch" htmlFor="">
                      Work from Home
                    </label>

                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={telecommute === "1" ? true : false}
                        onChange={(e) =>
                          setTelecommute(telecommute === "1" ? "0" : "1")
                        }
                        value="1"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>

                  <div className='col'>
                                        <label className="input-lebel-switch" htmlFor="">
                                            Travel Required
                                        </label>
                                        <label className="switch">
                                            <input type="checkbox" checked={travelRequired === '1' ? true : false} onChange={(e) => setTravelRequired(travelRequired === '1' ? '0' : '1')} value="1" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <label
                      className="input-lebel travel-label"
                      htmlFor=""
                      style={{ float: "right" }}>
                      Travel Required
                    </label>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12">
                    <input
                      ref={travelPercentRef}
                      style={{ width: "90%" }}
                      type="range"
                      class="form-range"
                      min="0"
                      max="100"
                      step="25"
                      id="customRange3"
                      value={travelPercentage}
                      onChange={(e) => {
                        setTravelRequired(e.target.value > 0 ? "1" : "0");
                        setTravelPercentage(e.target.value);
                      }}></input>
                    <div className="range_label">
                      <div className="left_value">0%</div>
                      <div className="left_value">25%</div>
                      <div className="left_value">50%</div>
                      <div className="left_value">75%</div>
                      <div className="right_value">100%</div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row mt-3">
                  <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                    <h2>Travel Required</h2>
                  </div>

                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <input
                          ref={travelPercentRef}
                          type="range"
                          class="form-range"
                          min="0"
                          max="100"
                          step="25"
                          id="customRange3"
                          value={travelPercentage}
                          onChange={(e) => {
                            setTravelRequired(e.target.value > 0 ? "1" : "0");
                            setTravelPercentage(e.target.value);
                          }}></input>
                        <div className="range_label">
                          <div className="left_value">0%</div>
                          <div className="left_value">25%</div>
                          <div className="left_value">50%</div>
                          <div className="left_value">75%</div>
                          <div className="right_value">100%</div>
                        </div>
                  </div>

                  <div className="col-2"></div>
                </div>
              <div className="col-2"></div>
            </div>
          </div>

          <div
            className={
              currentTab === "salary"
                ? "tab-pane fade show active"
                : "tab-pane fade show"
            }
            id="list-salary"
            role="tabpanel"
            aria-labelledby="list-salary-list">
            <div className="row mt-3" style={{ minHeight: "53vh" }}>
              <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                <h2 className="required">Salary and Benefits</h2>
                <p>You can select multiple Benefits</p>
              </div>

              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="row">
                  <label className="input-lebel" htmlFor="">
                    Salary Type
                  </label>
                  <span>
                    For Full Time Job choose Annual and for other options choose
                    Monthly
                  </span>
                </div>
                <div
                  className="row checkform mt-3"
                  style={{ paddingLeft: "10px" }}>
                  {masterData.salary_types &&
                    masterData.salary_types.map((salary_type, i) => {
                      return (
                        <div key={i} className="col-3 form-check radio-check">
                          <input
                            ref={salaryTypeRef}
                            name="salary-type"
                            className="form-check-input"
                            type="radio"
                            checked={salaryType === salary_type.id}
                            onChange={(e) => setSalaryType(salary_type.id)}
                            id={salary_type.id}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={salary_type.id}>
                            {salary_type.name}
                          </label>
                        </div>
                      );
                    })}
                </div>
                <div className="row mt-3">
                  <label className="input-lebel" htmlFor="">
                    Salary Range
                  </label>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="input-group min_sal_inp">
                      <span className="input-group-text" id="basic-addon1">
                        {CURRENCY}
                      </span>
                      <input
                        ref={minSalaryRef}
                        type="text"
                        value={commonServices.currencyFormatter(salaryMin)}
                        onChange={(e) => {
                          if (commonServices.isValidCurrency(e.target.value)) {
                            setSalaryMin(
                              commonServices.currencyFormatter(e.target.value)
                            );
                          }
                        }}
                        className="form-control "
                        placeholder="XXXXXX"
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="input-group min_sal_inp">
                      <span className="input-group-text" id="basic-addon1">
                        {CURRENCY}
                      </span>
                      <input
                        ref={maxSalaryRef}
                        type="text"
                        value={commonServices.currencyFormatter(salaryMax)}
                        onChange={(e) => {
                          if (commonServices.isValidCurrency(e.target.value)) {
                            setSalaryMax(
                              commonServices.currencyFormatter(e.target.value)
                            );
                          }
                        }}
                        className="form-control "
                        placeholder="XXXXXX"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg-3 col-md-12 col-sm-12 list_profile_heading">
                    <label className="input-lebel" htmlFor="">
                      Incentive/Bonus
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="input-group">
                      <select
                        value={bonus}
                        className="form-select form-select-lg"
                        id="bonus"
                        onChange={(e) => setBonus(e.target.value)}>
                        <option value={""}>Select Incentive/Bonus</option>

                        {incentives.map((incentive, i) => {
                          return (
                            <option key={i} value={incentive}>
                              {incentive}
                            </option>
                          );
                        })}
                        <option value={"Other"}>Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {bonus === "Other" ? (
                      <div className="input-group">
                        <input
                          ref={bonusTextRef}
                          type="text"
                          value={bonusText}
                          placeholder="Specify Other"
                          onChange={(e) => setBonusText(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row checkform mt-3">
                  {masterData.benefits &&
                    masterData.benefits.map((benefit, i) => {
                      return (
                        <div key={i} className="col form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={
                              benefits.indexOf(benefit.id.toString()) !== -1
                                ? true
                                : false
                            }
                            onChange={(e) => handleBenefitsChild(e)}
                            value={benefit.id}
                            id={benefit.id}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={benefit.id}>
                            {benefit.name}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="col-2"></div>
            </div>
          </div>

          <div
            className={
              currentTab === "description"
                ? "tab-pane fade show active"
                : "tab-pane fade show"
            }
            id="list-description"
            role="tabpanel"
            aria-labelledby="list-description-list">
            <div className="row mt-4">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="row mt-4">
                  <div className="col-lg-4 col-md-12 col-sm-12 list_profile_heading">
                    <h2 className="required">Job Description</h2>
                    <p>Describe the job details</p>
                  </div>
                  <div
                    className="col-lg-8 col-md-12 col-sm-12 list_profile_heading"
                    style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ display: "flex" }}>
                      Let TT help you with generating job description
                    </p>
                    <span style={{ marginTop: "10px", display: "flex" }}>
                      {!descriptionLoading ? (
                        <>
                          <button
                            style={{ borderRadius: "5px", fontSize: "1.125em" }}
                            className={
                              jobTitle.length <= 0
                                ? "fade-btn green-btnn description-btn"
                                : "green-btnn description-btn"
                            }
                            disabled={jobTitle.length <= 0 ? true : false}
                            onClick={() => {
                              getAIDescription();
                            }}>

                            Generate Job Description with<p className="ai-btn">AI</p>
                          </button>
                          <p style={{ padding: "0.313em 1.25em" }}> Or Enter your predefined JD below</p>
                        </>
                      ) : null}
                      <PuffLoader
                        color={color}
                        loading={descriptionLoading}
                        cssOverride={override}
                        size={50}
                        height={50}
                        width={50}
                        radios={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </span>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 col-md-12 col-sm-12 list_profile_heading"></div>

                  <div
                    className="col-lg-8 col-md-12 col-sm-12 mt-3 list_profile_heading"
                    style={{ minHeight: "40vh" }}>
                    <Editor
                      desc={jobDescription}
                      setDesc={setJobDescription}
                      placeholder="Write your own job description"
                    />
                    <p className="right">Max 3000 characters</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="about-ai-description mb-3">
                  <h2>AI Generated Job Description</h2>
                  <br />
                  <p className="para_2">
                    Introducing our revolutionary, cutting-edge AI driven job
                    descriptions that revolutionizes and optimizes the way you
                    post your job openings. Say goodbye to the hassle and
                    complexity of manual JD creation, and say hello to a
                    seamless and effortless experience. Discover the ultimate
                    time-saving solution that will turbocharge your search for
                    the ideal candidates.
                  </p>
                  <br />
                  {/* <p className='tag'>How it works</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddJobLayouts;