import { useState, useEffect, useRef } from "react";
import "./RegistrationSteps/Steps/Steps.scss";
import * as commonServices from "../../services/common";
import defaultAvatar from "../../Assets/svgs/default_avatar.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
import Editor from "../Common/Editor";
import * as responseCode from "../../constants/responseCodes";
import { LOCATION_THRESHOLD, COUNTRY } from "../../constants/storageConstants";

import {
  KEYBOARD_SHORTCUT_TEXT,
  LOCATION_SUGGESTION,
} from "../../constants/stringConstants";

import * as ApiHelper from "../../services/apiHelper";
import * as toast from "../../wrapper/toast";
import * as errors from "../../constants/stringConstants";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import * as storageConstants from "../../constants/storageConstants";
import AddSkill from "./RegistrationSteps/Steps/AddSkill";
import mapPinImg from "../../Assets/svgs/map_pin.svg";
import { addDataOnFocusOut } from "../../services/common";
import { ClearFileValues } from "../../utils/fileUtils";
import { jsonToFormData } from "../../wrapper/formData";
import {
  trackCandidateOnboarding,
  trackEvaluatorOnboarding,
} from "../../helpers/analytics";
import { analyticsConstant } from "../../constants/analyticsConstant";
import xSvg from "../../Assets/svgs/x.svg";
import Button from "../_widgets/Button/Button";
import Input from "../_widgets/Input/Input";
import { isNullOrEmpty, stripHTMLTags } from "../../utils/stringUtils";
import Text from "../_widgets/Text/Text";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import ModalEmp from "../Modals/ModalEmp";
import ModalAddEducation from "../Modals/ModalAddEducation";
import { handleSendOTP, initiateFirebase } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import AppModal from "../Modals/AppModal";
import { updateHeaderMessageIcon } from "../../utils/messageUtil";
import { READ, UNREAD } from "../../constants/messageConstants";
import { years, months } from "../../utils/dateUtil";
import { genderOptions, companySizeOptions } from "../../utils/dropdownUtils";
import ModalEmailVerification from "../Modals/ModalEmailVerification";
import ModalInformation from "../Modals/ModalInformation";
import { isCandidate, isEvaluator } from "../../utils/authUtil";
import { parsePhoneNumber } from "react-phone-number-input";
import ModalRestoreAccount from "../Modals/ModalRestoreAccount";
import Loader from "../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function RegisterV2({
  profile,
  setProfile,
  selectedProfile,
  referralCode,
  setReferralCode,
  isHeaderUpdate,
  setIsHeaderUpdate,
  dataFromResume,
  resumeLoading,
}) {
  useEffect(() => {
    if (dataFromResume !== undefined) {
      fillDataFromResume();
    }
  }, [dataFromResume]);

  const masterData = commonServices.getLocalData(storageConstants.DATA);
  masterData?.joining_preferences.pop();
  var joiningPreferenceOptions = masterData?.joining_preferences;
  joiningPreferenceOptions.unshift({
    id: "",
    name: "Please select notice period"
  });

  const fillDataFromResume = () => {
    const { first_name, last_name, email, phone_number, phone_number_country_code, alternate_phone_number, alternate_phone_number_country_code, professional_summary, smart_summary, skill_chips, project_and_company_details, total_experience_months, location, education_degree, employment_history, gender } = dataFromResume;

    let tempProfile = { ...profile };

    if (!isNullOrEmpty(first_name)) {
      tempProfile.first_name = first_name;
    }
    if (!isNullOrEmpty(last_name)) {
      tempProfile.last_name = last_name;
    }
    
    if (!isNullOrEmpty(gender)) {
      tempProfile.gender = gender;
    }
    if (!isNullOrEmpty(last_name)) {
      tempProfile.last_name = last_name;
    }
    if (!isNullOrEmpty(email) && !isEmailDisabled()) {
      tempProfile.email = email;
    }
    if (isNullOrEmpty(phone_number_country_code)) {
      tempProfile.cc_phone_number = "+91";
    } else {
      tempProfile.cc_phone_number = phone_number_country_code;
    }
    if (isNullOrEmpty(alternate_phone_number_country_code)) {
      tempProfile.cc_alternate_phone_number = "+91";
    } else {
      tempProfile.cc_alternate_phone_number = alternate_phone_number_country_code;
    }
    if (!isNullOrEmpty(phone_number)) {
      if (phone_number.includes("+91")) {
        tempProfile.phone_number = phone_number;
      } else {
        tempProfile.phone_number = tempProfile.cc_phone_number + phone_number;
      }
    }
    if (!isNullOrEmpty(alternate_phone_number)) {
      if (alternate_phone_number.includes("+91")) {
        tempProfile.alternate_phone_number = alternate_phone_number;
      } else {
        tempProfile.alternate_phone_number = tempProfile.cc_alternate_phone_number + alternate_phone_number;
      }
    }
    if (!isNullOrEmpty(skill_chips)) {
      setSkill([...skill, ...skill_chips.map(skill => ({
        "skill_id": skill.skill_id,
        "skill_name": skill.skill_name,
        "experience": skill.experience
      }))]);
    }
    if (!isNullOrEmpty(location)) {
      tempProfile.location_name = location?.name;
      tempProfile.location_id = location?.id;

      if (
        location?.name &&
        location?.id &&
        ![...preferred_location].includes(location.id)
      ) {
        setPreferred_location([location?.id]);
        setPreferredLocationName([location]);
      }

      setCurrentLocation(location?.name);
    }
    if (!tempProfile.user_work_profile) {
      tempProfile.user_work_profile = {};
    }
    if (!tempProfile.userWorkProfileDetail) {
      tempProfile.userWorkProfileDetail = {};
    }
    if (!isNullOrEmpty(total_experience_months)) {
      tempProfile.user_work_profile.total_experience = parseInt(
        total_experience_months
      );
    }
    if (!isNullOrEmpty(employment_history)) {
      if (!tempProfile.userWorkProfileDetail.experience) {
        tempProfile.userWorkProfileDetail.experience = [];
      }

      tempProfile.userWorkProfileDetail.experience = employment_history.map(
        (history) => {
          const isPresent = history?.to_date.toLowerCase() === "present";

          let startDate = null;
          let endDate = null;

          const startDateParts = history?.from_date.split("/");

          if (startDateParts.length === 1) {
            startDate = `01/01/${startDateParts[0]}`;
          } else if (startDateParts.length === 2) {
            startDate = `${startDateParts[0]}/01/${startDateParts[1]}`;
          } else if (startDateParts.length === 3) {
            startDate = `${startDateParts[1]}/${startDateParts[0]}/${startDateParts[2]}`;
          }

          if (!isPresent) {
            const endDateParts = history.to_date.split("/");

            if (endDateParts.length === 1) {
              endDate = `01/01/${endDateParts[0]}`;
            } else if (endDateParts.length === 2) {
              endDate = `${endDateParts[0]}/01/${endDateParts[1]}`;
            } else if (endDateParts.length === 3) {
              endDate = `${endDateParts[1]}/${endDateParts[0]}/${endDateParts[2]}`;
            }
          }

          return {
            title: history.designation || "",
            awarded_by: history.company || "",
            from_date: startDate || "",
            to_date: isPresent ? null : endDate,
            is_present: isPresent ? "1" : "0",
            description: history.summary || "",
          };
        }
      );
    }

    if (!isNullOrEmpty(education_degree)) {
      if (!tempProfile.userWorkProfileDetail.degree) {
        tempProfile.userWorkProfileDetail.degree = [];
      }

      tempProfile.userWorkProfileDetail.degree = education_degree.map((edu) => {
        const isPresent = edu.end_date.toLowerCase() === "present";

        let startDate = null;
        let endDate = null;

        if (edu?.start_date !== null && edu?.start_date !== "") {
          const startDateParts = edu.start_date.split("/");

          if (startDateParts.length === 1) {
            startDate = `01/01/${startDateParts[0]}`;
          } else if (startDateParts.length === 2) {
            startDate = `${startDateParts[0]}/01/${startDateParts[1]}`;
          } else if (startDateParts.length === 3) {
            startDate = `${startDateParts[1]}/${startDateParts[0]}/${startDateParts[2]}`;
          }
        }

        if (!isPresent) {
          const endDateParts = edu.end_date.split("/");
          if (endDateParts.length === 1) {
            endDate = `01/30/${endDateParts[0]}`;
          } else if (endDateParts.length === 2) {
            endDate = `${endDateParts[0]}/30/${endDateParts[1]}`;
          } else if (endDateParts.length === 3) {
            endDate = `${endDateParts[1]}/${endDateParts[0]}/${endDateParts[2]}`;
          }
        }

        return {
          awarded_by: edu.university,
          title: edu.degree,
          from_date: startDate,
          to_date: isPresent ? null : endDate,
          is_present: isPresent ? "1" : "0",
        };
      });
    }
    if (!isNullOrEmpty(smart_summary)) {
      tempProfile.smart_summary = smart_summary;
    }
    setProfile(tempProfile);
    if (!isNullOrEmpty(professional_summary)) {
      tempProfile.summary = professional_summary;
    }
    
  };

  var url_string = window.location.href;
  var url = new URL(url_string);
  var redirect = url.searchParams.get("redirect");
  const navigate = useNavigate();

  const [AlreadyRegistered, setAlreadyRegistered] = useState(false);
  const [emailUnverified, setEmailUnverified] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [locationSuggestion, setLocationSuggestion] = useState([]);
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");
  const [skill, setSkill] = useState([]);
  const [preferred_location, setPreferred_location] = useState([]);
  const [userIndustryDomain, setUserIndustryDomain] = useState([]);
  const [userIndustryDomainName, setUserIndustryDomainName] = useState([]);
  const [preferredLocationName, setPreferredLocationName] = useState([]);
  const [locSuggestion, setLocSuggestion] = useState([]);
  const [locSuggestionText, setLocSuggestionText] =
    useState(LOCATION_SUGGESTION);
  const [showModalEmployment, setShowModalEmployment] = useState(false);
  const [showModalEducation, setShowModalEducation] = useState(false);
  const [userId, setUserId] = useState();
  let [dataAutoSave, setDataAutoSave] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState(false);
  const [showResumeLoadingModal, setShowResumeLoadingModal] =
    useState(resumeLoading);

  const [industryDomainSuggestion, setIndustryDomainSuggestion] = useState([]);

  let locationRef = useRef();
  const emailRef = useRef();
  const skillChildRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!locationRef?.current?.contains(event.target)) {
        if (locationSuggestion.length > 0) {
          onLocationClick(locationSuggestion[0]);
          document.getElementById("search_location").value = "";
          setLocationSuggestion([]);
        }
        // else if (profile?.location_id === undefined || profile?.location_id === null || profile?.location_id === "") {

        //     setCurrentLocation('');
        // }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    setShowResumeLoadingModal(resumeLoading);
  }, [resumeLoading]);

  const isResumeExists = () => {
    return profile?.resume_file && profile?.resume_file_name;
  };

  const isEmailDisabled = () => {
    return (
      emailOtpSent || emailOtpVerified || profile?.email_verified_at != null
    );
  };

  const onResumeChange = (e) => {
    let file = e.target.files[0];
    let fileName = e.target.files[0]?.name;
    let tempProfile = { ...profile };
    tempProfile.resume_file = file;
    tempProfile.resume_file_name = fileName;
    setProfile(tempProfile);
  };

  const onAvatarChange = (e) => {
    let file = e.target.files[0];
    let fileUrl = URL.createObjectURL(file);
    let tempProfile = { ...profile };
    tempProfile.avatar = file;
    tempProfile.avatar_location = fileUrl;
    setProfile(tempProfile);
  };

  const resumeDelete = () => {
    ClearFileValues();
    let file = null;
    let fileName = null;
    let tempProfile = { ...profile };
    tempProfile.resume_file = file;
    tempProfile.resume_file_name = fileName;
    setProfile(tempProfile);
  };

  const onChange = (e, type = "") => {
    if (type === "") {
      if(e.target.name === "current_salary" || e.target.name === "min_salary"){
        if (commonServices.isValidCurrency(e.target.value)) {
          setProfile({
            ...profile,
            [e.target.name]: e.target.value,
          });
        }
      }else{
        setProfile({
          ...profile,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      var tempProfile = { ...profile };
      if (tempProfile[type] === null) {
        tempProfile[type] = [];
      }
      tempProfile[type][e.target.name] = e.target.value;
      setProfile(tempProfile);
    }
  };

  const updateSummary = (summary, delta, source) => {
    // setSummary(summary);
    if (source !== "api") {
      var tempProfile = { ...profile };
      tempProfile.summary = summary;
      setProfile(tempProfile);
    }
  };

  const locationHandler = (text) => {
    setCurrentLocation(text);
    if (text.length > LOCATION_THRESHOLD) {
      commonServices.getLocations(text).then((locations) => {
        setLocationSuggestion(locations);
        if (locations.length < 1) {
          setLocSuggestionText(errors.NO_LOCATION_FOUND_SUGGESTION);
        }
      });
    } else {
      setLocationSuggestion([]);
      setLocSuggestionText(LOCATION_SUGGESTION);
    }
  };

  const onLocationClick = (location) => {
    setCurrentLocation(location.name + ", " + location.description);
    var tempProfile = { ...profile };
    tempProfile.location_name = location.name;
    tempProfile.location_id = location.id;
    setProfile(tempProfile);
    setLocSuggestionText("");

    if (
      ![...preferred_location].includes(location.id) &&
      [...preferred_location].length < 1
    ) {
      setPreferred_location([...preferred_location, location.id]);
      setPreferredLocationName([...preferredLocationName, location]);
    }
  };

  const onChangeHandler_location = (text) => {
    if (text.length > LOCATION_THRESHOLD) {
      commonServices.getLocations(text).then((locations) => {
        setLocSuggestion(locations);
      });
    } else {
      setLocSuggestion([]);
    }
  };
  
  const onChangeHandlerIndustryDomain = (text) => {
    if (text.length > storageConstants.INDUSTRY_DOMAIN_THRESHOLD) {
      commonServices.getIndustryDomainByName(text).then((domains) => {
        setIndustryDomainSuggestion(domains);
      });
    } else {
      setIndustryDomainSuggestion([]);
    }
  };

  const onPreferredLocationClick = (location, e) => {
    e.preventDefault();
    if (![...preferred_location].includes(location.id)) {
      setPreferred_location([...preferred_location, location.id]);
      setPreferredLocationName([...preferredLocationName, location]);
    }
    document.getElementById("preferred_location").value = "";
    onChangeHandler_location("");
  };
  
  const onIndustryDomainClick = (domain, e) => {
    e.preventDefault();
    if(userIndustryDomain.length >= 3){
      toast.error(errors.INDUSTRY_DOMAIN_MAX);
      return;
    }
    if (![...userIndustryDomain].includes(domain.id)) {
      setUserIndustryDomain([...userIndustryDomain, domain.id]);
      setUserIndustryDomainName([...userIndustryDomainName, domain]);
    }
    document.getElementById("industry_domain").value = "";
    onChangeHandlerIndustryDomain("");
  };

  const del_location = async (e, data) => {
    e.preventDefault();
    var arrow = [...preferred_location];
    var tempPreferredLocationName = [...preferredLocationName];
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    for (var i = 0; i < tempPreferredLocationName.length; i++) {
      if (parseInt(tempPreferredLocationName[i]?.id) === data) {
        tempPreferredLocationName.splice(i, 1);
      }
    }
    setPreferred_location(arrow);
    setPreferredLocationName(tempPreferredLocationName);
  };

  const deleteIndustryDomain = async (e, data) => {
    e.preventDefault();
    var arrow = [...userIndustryDomain];
    var tempUserIndustryDomainName = [...userIndustryDomainName];
    for (var i = 0; i < arrow.length; i++) {
      if (parseInt(arrow[i]) === data) {
        arrow.splice(i, 1);
      }
    }
    for (var i = 0; i < tempUserIndustryDomainName.length; i++) {
      if (parseInt(tempUserIndustryDomainName[i]?.id) === data) {
        tempUserIndustryDomainName.splice(i, 1);
      }
    }
    setUserIndustryDomain(arrow);
    setUserIndustryDomainName(tempUserIndustryDomainName);
  };

  const required_check = document.getElementById("employee_required");

  const isInvalidData = () => {
    let isError = 0;

    if (!profile.first_name) {
      isError = 1;
      toast.warn(errors.FIRST_NAME_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.FIRST_NAME_REQUIRED);
    } else if (!profile.last_name) {
      isError = 1;
      toast.warn(errors.LAST_NAME_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.LAST_NAME_REQUIRED);
    } else if (!profile.email) {
      isError = 1;
      toast.warn(errors.EMAIL_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.EMAIL_REQUIRED);
    } else if (!commonServices.checkEmailValid(profile.email)) {
      isError = 1;
      toast.warn(errors.INCORRECT_EMAIL);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.INCORRECT_EMAIL);
    } else if (!profile.phone_number) {
      isError = 1;
      toast.warn(errors.PHONE_NUMBER_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.PHONE_NUMBER_REQUIRED);
    } else if (profile.phone_number && !isValidPhoneNumber(profile.phone_number)) {
      isError = 1;
      toast.warn(errors.PHONE_NUMBER_INCORRECT);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.PHONE_NUMBER_INCORRECT);
    } else if (profile.alternate_phone_number && !isValidPhoneNumber(profile.alternate_phone_number)) {
      isError = 1;
      toast.warn(errors.ALTERNATE_PHONE_NUMBER_INCORRECT);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.ALTERNATE_PHONE_NUMBER_INCORRECT);
    } else if (selectedProfile === "candidate" && !profile.location_name) {
      isError = 1;
      toast.warn(errors.LOCATION_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.LOCATION_REQUIRED);
    } else if (!profile.summary || stripHTMLTags(profile.summary).length <= 0) {
      isError = 1;
      toast.warn(errors.PROFESSIONAL_SUMMARY_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.PROFESSIONAL_SUMMARY_REQUIRED);
    } else if (skill.length < 1) {
      isError = 1;
      toast.warn(errors.SKILLS_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.SKILLS_REQUIRED);
    } else if (skillChildRef.current.isInvalidSkills()) {
      isError = 1;
      logEvent(analyticsConstant.VALIDATION_ERROR, "Invalid Skills");
    }else if (!profile.user_work_profile.joining_preference_id) {
      isError = 1;
      toast.warn(errors.NOTICE_PERIOD_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.NOTICE_PERIOD_REQUIRED);
    }else if (!profile.current_salary) {
      isError = 1;
      toast.warn(errors.CURRENT_SALARY_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.CURRENT_SALARY_REQUIRED);
    }else if (commonServices.removeCommaFromAmount(profile.current_salary) > 999999999) {
      isError = 1;
      toast.warn(errors.MAX_SALARY_AMT_REACHED+ " for currect CTC");
    }else if (!profile.min_salary) {
      isError = 1;
      toast.warn(errors.REQUIRED_SALARY_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.REQUIRED_SALARY_REQUIRED);
    }else if (commonServices.removeCommaFromAmount(profile.min_salary) > 999999999) {
      isError = 1;
      toast.warn(errors.MAX_SALARY_AMT_REACHED+ " for expected CTC");
    } else if (required_check.classList.contains("required") && isNullOrEmpty(profile?.userWorkProfileDetail?.experience)) {
      isError = 1;
      toast.warn(errors.EMPLOYMENT_HISTORY_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.EMPLOYMENT_HISTORY_REQUIRED);
    } else if (profile.userWorkProfileDetail === undefined || profile.userWorkProfileDetail?.degree === undefined || profile.userWorkProfileDetail?.degree?.length < 1) {
      isError = 1;
      toast.warn("Please provide Education Details");
      logEvent(analyticsConstant.VALIDATION_ERROR, "Please provide Education Details");
    }

    return isError;
  };

  const register = () => {
    if (!isInvalidData()) {
      if (
        !emailOtpSent &&
        !emailOtpVerified &&
        profile?.email_verified_at == null
      ) {
        sendEmailOtp();
      }

      if (
        emailOtpSent &&
        !emailOtpVerified &&
        doneVerifyingEmail === false &&
        profile?.email_verified_at == null
      ) {
        setShowEmailVerificationModal(true);
      }

      if ((emailOtpSent && emailOtpVerified && doneVerifyingEmail === true) || profile?.email_verified_at != null) {
        const alternate_phoneNumber = parsePhoneNumber(profile?.alternate_phone_number.toString());
        const phoneNumber = parsePhoneNumber(profile?.phone_number.toString());
        let tempProfile = { ...profile };
        tempProfile['phone_number'] = phoneNumber.nationalNumber;
        tempProfile['cc_phone_number'] = phoneNumber.countryCallingCode;
        tempProfile['alternate_phone_number'] = alternate_phoneNumber?.nationalNumber;
        tempProfile['cc_alternate_phone_number'] = alternate_phoneNumber?.countryCallingCode;
        setProfile(tempProfile);
        const obj = {
          selectedProfile: selectedProfile,
          first_name: profile.first_name,
          last_name: profile.last_name,
          email: profile.email,
          gender: profile?.gender,
          current_salary: commonServices.removeCommaFromAmount(profile?.current_salary),
          min_salary: commonServices.removeCommaFromAmount(profile?.min_salary),
          id: profile?.id,
          password: profile.password,
          confirm_password: profile.confirm_password,
          phone_number: tempProfile.phone_number,
          cc_phone_number: "+" + tempProfile.cc_phone_number,
          alternate_phone_number: tempProfile.alternate_phone_number,
          cc_alternate_phone_number: "+" + tempProfile.cc_alternate_phone_number,
          summary: profile.summary,
          smart_summary: profile.smart_summary,
          location_id: profile.location_id,
          location_name: profile.location_name,
          resume_file: profile.resume_file !== undefined ? profile.resume_file : '',
          avatar: profile.avatar !== undefined ? profile.avatar : '',
          skills: skill,
          preferred_location: JSON.stringify(preferred_location),
          referralCode: referralCode,
          total_experience: profile?.user_work_profile?.total_experience,
          experience: profile?.userWorkProfileDetail?.experience,
          education_degree: profile.userWorkProfileDetail.degree,
          joining_preference_id: profile.user_work_profile.joining_preference_id,
          required_company_size: profile.user_work_profile.required_company_size,
          industry_domain: JSON.stringify(userIndustryDomain),
        }

        if (profile.location_id !== undefined) {
          obj["location_id"] = profile.location_id;
        }
        if (profile.location_name !== undefined) {
          obj["location_name"] = profile.location_name;
        }
        if (profile.id === undefined) {
          obj["id"] = userId;
        }

        setLoading(true);
        const formDataSeralize = jsonToFormData(obj);

        ApiHelper.candidateRegister(formDataSeralize).then((response) => {
          if (response.isSuccess === true) {
            setLoading(false);
            setProfile(response.data);
            setUserId(response?.data?.id);
            if (
              response.data.is_verified &&
              response.data.token !== undefined
            ) {
              commonServices.storeLocalData(
                storageConstants.AUTH,
                response.data.token
              );
              commonServices.storeLocalData(
                storageConstants.USER_ROLE,
                response.data.role_type
              );
              commonServices.storeLocalData(
                storageConstants.PROFILE,
                response.data.profile
              );
              setIsHeaderUpdate(!isHeaderUpdate);
              if (response?.data?.user_unread_messages > 0) {
                updateHeaderMessageIcon(UNREAD);
              } else {
                updateHeaderMessageIcon(READ);
              }
              var nextStep = response?.data?.next_step;
              var message = response?.data?.message;
              navigate("/", {
                state: {
                  nextStep: nextStep,
                  message: message,
                },
              });
            } else {
              toast.warn(response.message);
              setLoading(false);
            }
          } else {
            setLoading(false);
            toast.warn(response.message);
          }
        });
      }
    }
  };

  const triggerClickDown = (event) => {
    if (event.which === 27 || event.keyCode === 27) {
      setLocSuggestion([]);
    } else if (event.which === 9 || event.keyCode === 9) {
      if (event.target.name === "locations") {
        addDataOnFocusOut(
          locSuggestion,
          setLocSuggestion,
          setPreferred_location,
          setPreferredLocationName
        );
        event.target.value = "";
      }
    }
  };

  const triggerSelect = (event) => {
    // const list = [...document.querySelectorAll('.sugg_p')];
    const active = document.querySelector(".sugg_p.active");
    // let i = list.indexOf(active);

    if (event.which === 13 || event.keyCode === 13) {
      // const c = document.getElementsByClassName('sugg_p hand-hover');

      if (active) {
        active.click();
      }
    }
  };

  const handleKeyPress = (event, key) => {
    if (key === "location") {
      if (event.key === "Enter" || event.key === "Tab") {
        if (locationSuggestion && locationSuggestion.length > 0) {
          onLocationClick(locationSuggestion[0]);
          document.getElementById("search_location").value = "";
          setLocationSuggestion([]);
        } else if (
          profile?.location_id === undefined ||
          profile?.location_id === null ||
          profile?.location_id === ""
        ) {
          setCurrentLocation("");
        }
      }

      if (event.key === "Escape") {
        setLocationSuggestion([]);
      }
    }

    if (key === "preferred_location") {
      if (event.key === "Enter" || event.key === "Tab") {
        if (locSuggestion && locSuggestion.length > 0) {
          onPreferredLocationClick(locSuggestion[0]);
          setLocSuggestion([]);
        }
      }

      if (event.key === "Escape") {
        setLocSuggestion([]);
      }
    }
    
    if (key === "industry_doamin") {
      if (event.key === "Enter" || event.key === "Tab") {
        if (industryDomainSuggestion && industryDomainSuggestion.length > 0) {
          onIndustryDomainClick(industryDomainSuggestion[0]);
          setIndustryDomainSuggestion([]);
        }
      }

      if (event.key === "Escape") {
        setIndustryDomainSuggestion([]);
      }
    }
  };

  const logEvent = (step, data) => {
    switch (selectedProfile) {
      case "candidate":
        trackCandidateOnboarding(step, data);
        break;
      case "evaluator":
        trackEvaluatorOnboarding(step, data);
        break;
      default:
        break;
    }
  };

  const month = [
    "abc",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const experienceHandler = (e, type) => {
    var tempArray = { ...profile };

    if (
      tempArray.user_work_profile === null ||
      tempArray.user_work_profile === undefined
    ) {
      tempArray.user_work_profile = {};
      tempArray.user_work_profile.total_experience = 0;
    }
    if (isNaN(tempArray.user_work_profile.total_experience)) {
      tempArray.user_work_profile.total_experience = 0;
    }
    if (type === "year") {
      var months = parseInt(e.target.value) * 12;
      tempArray.user_work_profile.total_experience =
        (tempArray.user_work_profile.total_experience % 12) + months;
      setProfile(tempArray);
    } else if (type === "month") {
      tempArray.user_work_profile.total_experience =
        Math.trunc(tempArray.user_work_profile.total_experience / 12) * 12 +
        parseInt(e.target.value);

      setProfile(tempArray);
    }
  };

  const deleteEmployment = async (e, data) => {
    e.preventDefault();
    var tempProfileArr = { ...profile };
    tempProfileArr.userWorkProfileDetail.experience.splice(data, 1);
    setProfile(tempProfileArr);
  };

  const sortArray = (array) => {
    return array.sort(function (a, b) {
      var keyA = new Date(a.from_date),
        keyB = new Date(b.from_date);
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });
  };

  const deleteDegree = async (e, data) => {
    e.preventDefault();
    var tempProfileArr = { ...profile };
    tempProfileArr.userWorkProfileDetail.degree.splice(data, 1);
    setProfile(tempProfileArr);
  };

  const [emailbuttonLoading, setEmailButtonLoading] = useState(false);
  const [phonebuttonLoading, setPhoneButtonLoading] = useState(false);

  const [emailOtpSent, setEmailOtpSent] = useState(false);

  const [isAccountDeleted, setIsAccountDeleted] = useState(false);

  const sendEmailOtp = () => {
    let tempProfile = { ...profile };
    tempProfile['phone_number'] = null;
    tempProfile['cc_phone_number'] = null;
    tempProfile['alternate_phone_number'] = null;
    tempProfile['cc_alternate_phone_number'] = null;

    if (!isNullOrEmpty(profile?.phone_number)) {
      const phoneNumber = parsePhoneNumber(profile?.phone_number.toString());
      tempProfile['phone_number'] = phoneNumber.nationalNumber;
      tempProfile['cc_phone_number'] = phoneNumber.countryCallingCode;
    }
    if (!isNullOrEmpty(profile?.alternate_phone_number)) {
      const alternate_phoneNumber = parsePhoneNumber(profile?.alternate_phone_number.toString());
      tempProfile['alternate_phone_number'] = alternate_phoneNumber?.nationalNumber;
      tempProfile['cc_alternate_phone_number'] = alternate_phoneNumber?.countryCallingCode;
    }

    setEmailButtonLoading(true);
    const obj = {
      selectedProfile: selectedProfile,
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      gender: profile?.gender,
      current_salary: commonServices.removeCommaFromAmount(profile?.current_salary),
      min_salary: commonServices.removeCommaFromAmount(profile?.min_salary),
      id: profile?.id,
      password: profile.password,
      confirm_password: profile.confirm_password,
      phone_number: tempProfile.phone_number,
      cc_phone_number: "+" + tempProfile.cc_phone_number,
      alternate_phone_number: tempProfile.alternate_phone_number,
      cc_alternate_phone_number: "+" + tempProfile.cc_alternate_phone_number,
      summary: profile.summary,
      smart_summary: profile.smart_summary,
      location_id: profile.location_id,
      location_name: profile.location_name,
      resume_file: profile.resume_file !== undefined ? profile.resume_file : '',
      avatar: profile.avatar !== undefined ? profile.avatar : '',
      skills: skill,
      preferred_location: JSON.stringify(preferred_location),
      referralCode: referralCode,
      total_experience: profile?.user_work_profile?.total_experience,
      experience: profile?.userWorkProfileDetail?.experience,
      education_degree: profile.userWorkProfileDetail.degree,
      joining_preference_id: profile.user_work_profile.joining_preference_id,
      required_company_size: profile.user_work_profile.required_company_size,
      industry_domain: JSON.stringify(userIndustryDomain),
    }

    if (profile.location_id !== undefined) {
      obj["location_id"] = profile.location_id;
    }
    if (profile.location_name !== undefined) {
      obj["location_name"] = profile.location_name;
    }
    if (profile.id !== undefined) {
      obj["id"] = profile.id;
    }

    const formDataSeralize = jsonToFormData(obj);

    ApiHelper.candidateRegister(formDataSeralize).then((response) => {
      if (response.isSuccess) {
        toast.success(errors.OTP_SENT_SUCCESS);
        setUserId(response?.data?.id);
        setEmailOtpSent(true);
        setShowEmailVerificationModal(true);
      } else if (response?.code === responseCode.ALREADY_REGISTERED) {
        setAlreadyRegistered(true);
      } else if (response?.code === responseCode.EMAIL_UNVERIFIED) {
        setUserId(response?.data?.id);
        setEmailUnverified(true);
        setShowEmailVerificationModal(true);
      } else if (response?.code === 203) {
        setIsAccountDeleted(true);
        toast.warn(response?.message);
      } else {
        toast.warn(response?.message);
      }
      setEmailButtonLoading(false);
    });
  };

  const [emailOtp, setEmailOtp] = useState();
  const [emailOtpVerified, setEmailOtpVerified] = useState(false);

  const verifyEmailOtp = () => {
    let isError = 0;

    if (!profile.password) {
      isError = 1;
      toast.warn(errors.PASSWORD_REQUIRED);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.PASSWORD_REQUIRED);
    } else if (!commonServices.isValidPassword(profile.password)) {
      isError = 1;
      toast.warn(errors.INVALID_PASSWORD);
      logEvent(analyticsConstant.VALIDATION_ERROR, errors.INVALID_PASSWORD);
    } else if (!profile.confirm_password) {
      isError = 1;
      toast.warn(errors.CONFIRM_PASSWORD_REQUIRED);
      logEvent(
        analyticsConstant.VALIDATION_ERROR,
        errors.CONFIRM_PASSWORD_REQUIRED
      );
    } else if (profile.password !== profile.confirm_password) {
      isError = 1;
      toast.warn(errors.PASSWORD_CONFIRM_PASSWORD_MATCH);
      logEvent(
        analyticsConstant.VALIDATION_ERROR,
        errors.PASSWORD_CONFIRM_PASSWORD_MATCH
      );
    } else if (isNullOrEmpty(emailOtp) || emailOtp.length !== 4) {
      toast.warn("Please Enter Four-Digit OTP to Proceed");
    } else if (
      !isNullOrEmpty(emailOtp) &&
      emailOtp.length === 4 &&
      isError === 0
    ) {
      setEmailButtonLoading(true);
      const newFormData = new FormData();
      newFormData.append("email", profile?.email);
      newFormData.append("otp", emailOtp);
      newFormData.append("user_id", userId);
      newFormData.append("password", profile?.password);
      newFormData.append("confirm_password", profile?.confirm_password);
      ApiHelper.EmailOtpVerify(newFormData).then((response) => {
        console.log("email otp resp=-==>", response);
        if (response.isSuccess === true) {
          toast.success(response.message);
          setEmailButtonLoading(false);
          setEmailOtpVerified(true);
          setShowEmailVerificationModal(false);
          setDoneVerifyingEmail(true);

          if (response.data.token !== undefined) {
            commonServices.storeLocalData(
              storageConstants.AUTH,
              response.data.token
            );
            commonServices.storeLocalData(
              storageConstants.USER_ROLE,
              response.data.role_type
            );
            commonServices.storeLocalData(
              storageConstants.PROFILE,
              response.data.profile
            );
            setIsHeaderUpdate(!isHeaderUpdate);
            if (response?.data?.user_unread_messages > 0) {
              updateHeaderMessageIcon(UNREAD);
            } else {
              updateHeaderMessageIcon(READ);
            }
            var nextStep = response?.data?.next_step;
            var message = response?.data?.message;
            var reffer = response?.data?.referral_data;
            navigate("/", {
              state: {
                isreferred: reffer,
                nextStep: nextStep,
                message: message,
              },
            });
          }
        } else {
          toast.warn(response.message);
          setEmailButtonLoading(false);
        }
      });
    }
  };

  const [otpResendLoading, setOtpResendLoading] = useState(false);

  const resendEmailOtp = () => {
    const formData = new FormData();
    formData.append("email", profile.email);
    setOtpResendLoading(true);

    ApiHelper.resendVerificationEmail(formData).then((res) => {
      if (res.isSuccess === true) {
        toast.success(res.message);
      } else {
        toast.warn(res.message);
      }
      setOtpResendLoading(false);
    });
  };

  const [phoneOtpSent, setPhoneOtpSent] = useState(false);

  useEffect(() => {
    initiateFirebase();
  }, []);

  const sendPhoneOtp = () => {
    let validNumber = false;

    if (isNullOrEmpty(profile?.phone_number)) {
      validNumber = false;
      toast.warn('Please Enter Contact Number');
    } else if (profile?.phone_number.length === 13) {
      // } else if ((profile?.contact.toString().startsWith('0') && profile?.contact.length === 13) || (profile?.contact.toString().startsWith('0') && profile?.contact.length === 14)) {
      validNumber = true;
    } else {
      validNumber = false;
      toast.warn('Please Enter Valid Contact Number');
    }

    if (validNumber === true) {
      try {
        setPhoneButtonLoading(true);
        handleSendOTP(profile?.phone_number,
          // Success callback
          (confirmationResult) => {
            toast.success(errors.OTP_SENT_SUCCESS);
            setPhoneOtpSent(true);
            setPhoneButtonLoading(false);
          },
          // Failure callback
          () => {
            toast.warn(errors.OTP_SENT_FAILURE);
            setPhoneButtonLoading(false);
          })
      } catch (error) {
        toast.warn(error);
        setPhoneButtonLoading(false);
      }
    }
  }

  const [phoneOtp, setPhoneOtp] = useState();
  const [phoneOtpVerified, setPhoneOtpVerified] = useState(false);

  const verifyPhoneOtp = () => { };

  const [doneVerifyingEmail, setDoneVerifyingEmail] = useState(
    emailOtpSent && emailOtpVerified
  );
  const [doneVerifyingPhone, setDoneVerifyingPhone] = useState(
    phoneOtpSent && phoneOtpVerified
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const inputRef = useRef();

  function clearData(type) {
    // if (type === 'unmount') navigate("/?auth=login");

    const tempProfile = { first_name: "", last_name: "", email: "" };

    if (type !== "unmount") {
      if (!isNullOrEmpty(selectedProfile)) {
        tempProfile.selectedProfile = selectedProfile;
      }
    }

    setProfile(tempProfile);
    setSkill([]);
    setPreferred_location([]);
    setPreferredLocationName([]);
    setCurrentLocation("");

    if (
      isResumeExists() &&
      !isNullOrEmpty(selectedProfile) &&
      type !== "unmount"
    ) {
      setTimeout(() => inputRef.current.click(), 500);
    }
  }

  useEffect(() => {
    return () => {
      navigate("/?auth=login");
      clearData("unmount");
      // setTimeout(() => {
      //     clearData('unmount');
      // }, 0);
    };
  }, []);

  const EmpRequired = () => {
    if (isEvaluator() || selectedProfile === "evaluator") {
      return "mb-3";
    } else if (
      profile?.user_work_profile?.total_experience &&
      !isEvaluator() &&
      selectedProfile !== "evaluator"
    ) {
      return "mb-3 required";
    } else {
      return "mb-3";
    }
  };

  return (
    <div className="personal-details reigstration-section row main-body">

      {loading ? (
        <Loader />
      ) : null}

      <AppModal
        title="TrueTalent Resume Reader"
        isOpen={showResumeLoadingModal}
        isFooter={false}
        closeModal={() => setShowResumeLoadingModal(false)}
        size="lg"
        minH={"40vh"}
      >
        <div className="d-flex position-relative py-4 align-items-center flex-column gap-2">
          <img src="./clock.gif" height={"280px"} width={"350px"} alt="" />
          <Text
            type="h6"
            className="mt-1 text-center"
            text="Our Key Feature"
            style={{ fontWeight: "700" }}
          />
          <Text
            type="h7"
            className="mt-1 text-center"
            text="Effortlessly auto-apply to jobs matching your skills and preferences"
          />
        </div>
      </AppModal>

      <AppModal
        title="Confirmation"
        isOpen={showConfirmModal}
        isFooter={true}
        size={"lg"}
        cancelText="No"
        closeModal={() => setShowConfirmModal(false)}
        submitText="Yes"
        submit={() => {
          setConfirmation(true);
          clearData();
          setShowConfirmModal(false);
        }}
      >
        <Text
          type="h7"
          className="text-center"
          text="The filled data will become Empty by this Action, Do you want to Proceed?"
        />
      </AppModal>

      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="registration-section-header">
          <Text type="h2" text={""} />
        </div>
        {/* {selectedProfile === 'candidate' ?
                    <div>
                        <Text type='small' className='text-center' text='*' />
                    </div>
                    : null
                } */}
        <div>
          <Button
            buttonType="primary"
            text={isAccountDeleted ? "Login" : "Next"}
            isLoading={emailbuttonLoading}
            buttonRef={emailRef}
            onClick={() => isAccountDeleted ? navigate("/?auth=login") : register()}
          />
        </div>
      </div>

      {/* Resume upload component/section start */}
      <div className="row no-padding-row">
        <div className="col-lg-6 my-2">
          <div className="resume-upload-section">
            <div className="row align-items-center">
              <div className="col-8 col-md-10 d-flex flex-md-column flex-row justify-content-md-center justify-content-start">
                <div className="row align-items-center">
                  <div className="col-12 col-md-5">
                    {isResumeExists() ? (
                      <div
                        className="resume-filename"
                        style={{ display: "flex", gap: "8px" }}
                      >
                        <img
                          onError={commonServices.imgError}
                          src="./Assets/images/file_text.png?1"
                          alt=""
                        />
                        <div className="file-name-upload">
                          <a href={undefined}>{profile?.resume_file_name}</a>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12 col-md-7">
                    <div
                      className="file-name-upload justify-content-start hand-hover"
                      onClick={() =>
                        isResumeExists() ? setShowConfirmModal(true) : null
                      }
                    >
                      <input
                        ref={inputRef}
                        id="input-file-now"
                        className="upload-input"
                        type={`${isResumeExists()
                          ? !confirmation
                            ? "hidden"
                            : "file"
                          : "file"
                          }`}
                        accept="image/*, .doc, .pdf, .docx, .ppt, .pptx"
                        onChange={(e) => onResumeChange(e)}
                      />

                      <img
                        onError={commonServices.imgError}
                        src="./Assets/svgs/upload.svg"
                        alt=""
                      />
                      {isResumeExists() ? "Update Resume" : "Upload Resume"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-2 text-end">
                {isResumeExists() ? (
                  <a
                    className="delete-button"
                    onClick={(e) => {
                      if (isResumeExists()) {
                        setShowConfirmModal(true);
                      }
                    }}
                  >
                    <img
                      className=""
                      src="../Assets/svgs/trash-white-bg.svg"
                    ></img>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 my-2 d-flex align-content-center">
          <div
            className="profile-upload-section w-100 py-2 ps-2 justify-content-center justify-content-lg-start"
            style={{ border: "2px dashed #dbdbdb" }}
          >
            <div className="profile-upload-img">
              <img
                onError={commonServices.imgError}
                src={profile.avatar_location ?? defaultAvatar}
                alt=""
              />
            </div>
            <div className="profile-upload-input">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => onAvatarChange(e)}
              />
              <p>Upload photo</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <Text type="h2" text="Personal Details" className="my-3" />

        <div className="mt-3">
          <div className="row">
            <div className="col-12 col-md-6">
              <Input
                label="First Name"
                placeholder="First Name"
                value={profile.first_name}
                // value={!isNullOrEmpty(profile.first_name) ? profile.first_name : ''}
                name="first_name"
                onChange={(e) => onChange(e)}
                isRequired={true}
              />
            </div>

            <div className="col-12 col-md-6">
              <Input
                label="Last Name"
                placeholder="Last Name"
                value={profile.last_name}
                // value={!isNullOrEmpty(profile.last_name) ? profile.last_name : ''}
                name="last_name"
                onChange={(e) => onChange(e)}
                isRequired={true}
              />
            </div>

            <div className="col-12 col-md-6">
              <Input
                label={`Email ${doneVerifyingEmail ? '(Verified)' : ''}`}
                placeholder="Email"
                value={profile.email}
                // value={!isNullOrEmpty(profile.email) ? profile.email : ''}
                name="email"
                onChange={(e) => { if (!isEmailDisabled()) onChange(e) }}
                disabled={isEmailDisabled()}
                readOnly={isEmailDisabled()}
                isRequired={true}
              />
            </div>

            <div className="col-12 col-md-6">
              <Input
                label="Referral Code (Optional)"
                placeholder="Referral Code"
                onChange={(e) => {
                  setReferralCode(e.target.value);
                }}
                value={referralCode}
              />
            </div>

            <div className="col-12 col-md-6">
              <Input
                type="phone"
                label={`Phone Number ${doneVerifyingPhone ? '(Verified)' : ''}`}
                placeholder="Phone Number"
                name="contact"
                defaultCountry={COUNTRY}
                isRequired={true}
                value={profile.phone_number}
                // value={!isNullOrEmpty(profile.contact) ? profile.contact : ''}
                onChange={phoneNumber => {
                  let tempProfile = { ...profile };
                  tempProfile['phone_number'] = phoneNumber;
                  setProfile(tempProfile);
                }}

              />
            </div>
            <div className="col-12 col-md-6">
              <Input
                type="phone"
                label={`Alternate Phone Number`}
                placeholder="Phone Number"
                name="contact"
                defaultCountry={COUNTRY}
                isRequired={false}
                value={profile.alternate_phone_number}
                // value={!isNullOrEmpty(profile.contact) ? profile.contact : ''}
                onChange={alternatePhoneNumber => {
                  let tempProfile = { ...profile };
                  tempProfile['alternate_phone_number'] = alternatePhoneNumber;
                  setProfile(tempProfile);
                }}

              />

            </div>

          </div>

          {/* Email Verification Modal */}
          <ModalEmailVerification
            profile={profile}
            showEmailVerificationModal={showEmailVerificationModal}
            emailbuttonLoading={emailbuttonLoading}
            emailOtpSent={emailOtpSent}
            emailOtpVerified={emailOtpVerified}
            verifyEmailOtp={verifyEmailOtp}
            doneVerifyingEmail={doneVerifyingEmail}
            setShowEmailVerificationModal={setShowEmailVerificationModal}
            emailOtp={emailOtp}
            setEmailOtp={setEmailOtp}
            onChange={onChange}
            title={
              emailUnverified
                ? "Welcome back! Continue your onboarding"
                : "Verify & Complete your profile"
            }
            otpResendLoading={otpResendLoading}
            resendEmailOtp={resendEmailOtp}
          />
          <ModalInformation
            showModal={AlreadyRegistered}
            setShowModal={setAlreadyRegistered}
            title={"Email Already Registered"}
            text={
              "Your Email is already Registered with us. Kindly Please Login or Reset your Password by clicking buttons below."
            }
            buttonOneText={"Reset Password"}
            buttonOneAction={() => navigate("/?auth=forgot")}
            buttonTwoText={"Login"}
            buttonTwoAction={() => navigate("/?auth=login")}
          />
          <ModalInformation
            showModal={AlreadyRegistered}
            setShowModal={setAlreadyRegistered}
            title={"Email Already Registered"}
            text={
              "Your Email is already Registered with us. Kindly Please Login or Reset your Password by clicking buttons below."
            }
            buttonOneText={"Reset Password"}
            buttonOneAction={() => navigate("/?auth=forgot")}
            buttonTwoText={"Login"}
            buttonTwoAction={() => navigate("/?auth=login")}
          />

          {selectedProfile === "candidate" ? (
            <div className="row" ref={locationRef}>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label className="required" htmlFor="">
                  Current Location{" "}
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <img
                        className={"input-icon"}
                        onError={commonServices.imgError}
                        src={mapPinImg}
                        alt=""
                      />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type to search for location"
                    id="search_location"
                    value={currentLocation}
                    autoComplete="off"
                    name="location_id"
                    onChange={(e) => {
                      locationHandler(e.target.value);
                      if (e.target.value.length < 1) {
                        var tempProfile = { ...profile };
                        tempProfile["location_id"] = "";
                        tempProfile["location_name"] = "";
                        setProfile(tempProfile);
                      }
                    }}
                    onKeyDown={(e) => handleKeyPress(e, "location")}
                  />
                </div>

                <div style={{ height: "auto", position: "relative" }}>
                  <div
                    className={
                      locationSuggestion?.length > 0
                        ? " suggestion_box active"
                        : "suggestion_box"
                    }
                  >
                    <div>
                      {locationSuggestion &&
                        locationSuggestion.map((suggestion, i) => (
                          <p
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            key={i}
                            onClick={() => {
                              onLocationClick(suggestion);
                              document.getElementById("search_location").value =
                                "";
                              setLocationSuggestion([]);
                            }}
                            className="sugg_p hand-hover"
                          >
                            {suggestion.name}, {suggestion.description}
                          </p>
                        ))}
                    </div>

                    {locationSuggestion?.length > 0 ? (
                      <div
                        style={{
                          position: "sticky",
                          bottom: 0,
                          background: "#f5f5f5",
                          padding: "10px",
                          color: "#9398A1",
                          fontSize: "0.875em",
                        }}
                      >
                        {KEYBOARD_SHORTCUT_TEXT}
                      </div>
                    ) : null}

                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <label htmlFor="">Preferred Location </label>
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <img
                        className={"input-icon"}
                        onError={commonServices.imgError}
                        src={mapPinImg}
                        alt=""
                      />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type to search for location"
                    id="preferred_location"
                    autoComplete="off"
                    onChange={(e) => {
                      onChangeHandler_location(e.target.value);
                      if (e.target.value.length < 1) {
                        var tempProfile = { ...profile };
                        tempProfile["location_id"] = "";
                        tempProfile["location_name"] = "";
                        setProfile(tempProfile);
                        setLocSuggestion([]);
                      }
                    }}
                    onKeyDown={(e) => handleKeyPress(e, "preferred_location")}
                  />
                </div>

                <div style={{ height: "auto", position: "relative" }}>
                  <div
                    className={
                      locSuggestion?.length > 0
                        ? " suggestion_box active"
                        : "suggestion_box"
                    }
                  >
                    <div>
                      {locSuggestion &&
                        locSuggestion.map((suggestion, i) => (
                          <p
                            style={{ fontSize: "14px" }}
                            key={i}
                            onClick={(e) => {
                              onPreferredLocationClick(suggestion, e);
                            }}
                            className="sugg_p hand-hover"
                          >
                            {suggestion.name}, {suggestion.description}
                          </p>
                        ))}
                    </div>

                    {locSuggestion?.length > 0 ? (
                      <div
                        style={{
                          position: "sticky",
                          bottom: 0,
                          background: "#f5f5f5",
                          padding: "10px",
                          color: "#9398A1",
                          fontSize: "0.875em",
                        }}
                      >
                        {KEYBOARD_SHORTCUT_TEXT}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex f_t_box">
                  {preferredLocationName ? (
                    <>
                      {preferredLocationName.map((data, k) => {
                        if (preferred_location.includes(data.id))
                          return (
                            <div className="f_t" key={k}>
                              <p>
                                {data.name}, {data.description}
                              </p>
                              <button
                                onClick={(e) => {
                                  del_location(e, data.id);
                                }}
                                className="del_jt"
                              >
                                <img src={xSvg} alt="" />
                              </button>
                            </div>
                          );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}



          <div className="row summary">
            <div className="col">
              <Text
                type="h2"
                text="Professional Summary"
                className="required my-3"
              ></Text>
              <Text
                className="p-gray"
                text="Write 2-4 short sentences that best showcases your experience, knowledge, skills and competencies."
              ></Text>
              {/* <Editor desc={profile.summary} setDesc={(data) => updateSummary(data)} placeholder="Enter Professional Summary" /> */}
              <Editor
                desc={!isNullOrEmpty(profile.summary) ? profile.summary : ""}
                setDesc={updateSummary}
                placeholder="Enter Professional Summary"
              />
            </div>
          </div>

          <div className="mt-3">
            <AddSkill
              skill={skill}
              setSkill={setSkill}
              selectedProfile={selectedProfile}
              ref={skillChildRef}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div id="Experience">
          <Text type="h2" text="Total Experience" className="my-3" />
          <div className="row gap-2 gap-sm-0 mb-2">
            <div className="col-12 col-sm-6">
              <Dropdown
                isRequired={false}
                label={"Years"}
                id="year"
                name="year"
                value={
                  profile?.user_work_profile?.total_experience
                    ? Math.trunc(
                      profile?.user_work_profile.total_experience / 12
                    )
                    : 0
                }
                onChange={(e) => experienceHandler(e, "year")}
                select={years}
              />
            </div>

            <div className="col-12 col-sm-6">
              <Dropdown
                isRequired={false}
                label={"Months"}
                id="mon"
                name="mon"
                value={
                  profile?.user_work_profile?.total_experience
                    ? profile?.user_work_profile.total_experience % 12
                    : 0
                }
                onChange={(e) => experienceHandler(e, "month")}
                select={months}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="row mt-3 gap-2 gap-sm-0">
            <Text
              type="h2"
              text="Additional Information"
              className={
                isCandidate() &&
                  profile?.user_work_profile?.total_experience > 0
                  ? "required mb-0"
                  : "mb-0"
              }
            />
          </div>
          <div className="row mb-3 gap-2 gap-sm-0">
            <div className="col-12 col-sm-6">
              <Dropdown
                isRequired={false}
                label={"Gender"}
                id="gender"
                name="gender"
                value={
                  profile?.gender
                }
                onChange={(e) => onChange(e)}
                select={genderOptions}
              />
            </div>

            <div className="col-12 col-sm-6">
              <Dropdown
                isRequired={true}
                label={"Notice Period"}
                id="joining_preference_id"
                name="joining_preference_id"
                value={
                  profile.user_work_profile &&
                    profile.user_work_profile.joining_preference_id
                    ? profile.user_work_profile.joining_preference_id
                    : ""
                }
                onChange={(e) => onChange(e, "user_work_profile")}
                select={joiningPreferenceOptions}
                
              />
            </div>

            <div className="col-sm-6">
              <Input
                type="text"
                isRequired="true"
                className="form-control"
                label="Current CTC"
                placeholder="Current CTC"
                value={
                  commonServices.currencyFormatter(profile?.current_salary)
                }
                name="current_salary"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="col-sm-6">
              <Input
                type="currency"
                isRequired="true"
                className="form-control"
                label="Expected CTC"
                placeholder="Expected CTC"
                value={
                  commonServices.currencyFormatter(profile?.min_salary)
                }
                name="min_salary"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="col-12 col-sm-6">
              <Dropdown
                isRequired={false}
                label={"Company size to work for"}
                id="required_company_size"
                name="required_company_size"
                value={
                  profile.user_work_profile &&
                    profile.user_work_profile.required_company_size
                    ? profile.user_work_profile.required_company_size
                    : ""
                }
                onChange={(e) => onChange(e, "user_work_profile")}
                select={companySizeOptions}
                
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <label htmlFor="">Industry Domain </label>
              <div className="input-group mb-1">
                
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type to search for industry domain"
                  id="industry_domain"
                  autoComplete="off"
                  onChange={(e) => {
                    onChangeHandlerIndustryDomain(e.target.value);
                    if (e.target.value.length < 1) {
                      
                      setIndustryDomainSuggestion([]);
                    }
                  }}
                  onKeyDown={(e) => handleKeyPress(e, "industry_doamin")}
                />
              </div>

              <div style={{ height: "auto", position: "relative" }}>
                <div
                  className={
                    industryDomainSuggestion?.length > 0
                      ? " suggestion_box active"
                      : "suggestion_box"
                  }
                >
                  <div>
                    {industryDomainSuggestion &&
                      industryDomainSuggestion.map((suggestion, i) => (
                        <p
                          style={{ fontSize: "14px" }}
                          key={i}
                          onClick={(e) => {
                            onIndustryDomainClick(suggestion, e);
                          }}
                          className="sugg_p hand-hover"
                        >
                          {suggestion.name}
                        </p>
                      ))}
                  </div>

                  {industryDomainSuggestion?.length > 0 ? (
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        background: "#f5f5f5",
                        padding: "10px",
                        color: "#9398A1",
                        fontSize: "0.875em",
                      }}
                    >
                      {KEYBOARD_SHORTCUT_TEXT}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex f_t_box">
                {userIndustryDomainName ? (
                  <>
                    {userIndustryDomainName.map((data, k) => {
                      if (userIndustryDomain.includes(data.id))
                        return (
                          <div className="f_t" key={k}>
                            <p>
                              {data.name}
                            </p>
                            <button
                              onClick={(e) => {
                                deleteIndustryDomain(e, data.id);
                              }}
                              className="del_jt"
                            >
                              <img src={xSvg} alt="" />
                            </button>
                          </div>
                        );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="row" id="Employment">
          <ModalEmp
            showModal={showModalEmployment}
            setShowModal={setShowModalEmployment}
            profile={profile}
            setProfile={setProfile}
            setDataAutoSave={setDataAutoSave}
          />

          <div className="col my-3">
            <Text
              type="h2"
              text="Employment History"
              id="employee_required"
              className={EmpRequired()}
            />
            <Text
              type="small"
              text="Give details about your employment over the years starting with your current employment. If you are starting your career, mention your career goals and aspirations, starting with, Looking forward to an exciting career."
            />

            {profile?.userWorkProfileDetail &&
              profile?.userWorkProfileDetail?.experience &&
              sortArray(profile?.userWorkProfileDetail?.experience).map(
                (data, key) => {
                  return (
                    <div className="emp_box" key={key}>
                      <div
                        className="emp_box_det"
                        onClick={(e) => {
                          setShowModalEmployment((prev) => !prev);
                        }}
                      >
                        <div>
                          <p>{data.awarded_by}</p>
                          <p className="green_text">{data.title}</p>
                          <p>
                            {month[data.from_date.split("/")[0] * 1]}{" "}
                            {data.from_date.split("/")[2]} <span>-</span>{" "}
                            {data.to_date !== null ? (
                              <>
                                {month[data.to_date.split("/")[0] * 1]}{" "}
                                {data.to_date.split("/")[2]}
                              </>
                            ) : (
                              <>Present</>
                            )}
                          </p>
                        </div>
                        <img
                          onError={commonServices.imgError}
                          src="./Assets/svgs/chevron_down.svg"
                          alt=""
                        />
                      </div>
                      <button onClick={(e) => deleteEmployment(e, key)}>
                        <img
                          onError={commonServices.imgError}
                          src="./Assets/svgs/trash.svg"
                          alt=""
                          className="trash"
                        />
                      </button>
                    </div>
                  );
                }
              )}

            <button
              className="add-btn"
              onClick={() => setShowModalEmployment((prev) => !prev)}
            >
              <img
                onError={commonServices.imgError}
                src="./Assets/svgs/plus_circle.svg"
                alt=""
              />{" "}
              Add Employment History
            </button>
          </div>
        </div>

        <div className="row" id="Education">
          <ModalAddEducation
            showModal={showModalEducation}
            setShowModal={setShowModalEducation}
            profile={profile}
            setProfile={setProfile}
            setDataAutoSave={setDataAutoSave}
          />

          <div className="col">
            <Text type="h2" text="Education" className="required my-3" />
            <Text
              type="small"
              text="Highlight your education details with details of your specific areas of learning"
            ></Text>

            {profile.userWorkProfileDetail &&
              profile.userWorkProfileDetail.degree &&
              sortArray(profile.userWorkProfileDetail.degree).map(
                (data, key) => {
                  return (
                    <div className="emp_box" key={key}>
                      <div
                        className="emp_box_det"
                        onClick={() => setShowModalEducation((prev) => !prev)}
                      >
                        <div>
                          <p>{data.awarded_by}</p>
                          <p className="green_text">{data.title}</p>
                          <p>
                            {data.from_date !== null
                              ? month[data.from_date.split("/")[0] * 1]
                              : ""}{" "}
                            {data.from_date !== null
                              ? data.from_date.split("/")[2] + " - "
                              : ""}{" "}
                            {data.to_date !== null ? (
                              <>
                                {month[data.to_date.split("/")[0] * 1]}{" "}
                                {data.to_date.split("/")[2]}
                              </>
                            ) : (
                              <>Present</>
                            )}
                          </p>
                        </div>
                        <img
                          onError={commonServices.imgError}
                          src="./Assets/svgs/chevron_down.svg"
                          alt=""
                        />
                      </div>
                      <button onClick={(e) => deleteDegree(e, key)}>
                        <img
                          onError={commonServices.imgError}
                          src="./Assets/svgs/trash.svg"
                          alt=""
                          className="trash"
                        />
                      </button>
                    </div>
                  );
                }
              )}

            <button
              className="add-btn"
              onClick={() => setShowModalEducation((prev) => !prev)}
            >
              <img
                onError={commonServices.imgError}
                src="./Assets/svgs/plus_circle.svg"
                alt=""
              />{" "}
              Add Education
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end w-100">
        <Button
          buttonType="primary"
          text={isAccountDeleted ? "Login" : "Next"}
          isLoading={emailbuttonLoading}
          onClick={() => isAccountDeleted ? navigate("/?auth=login") : register()}
        />
      </div>
    </div>
  );
}

export default RegisterV2;
