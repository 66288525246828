import * as ApiHelper from './apiHelper';
import { CURRENCY_FORMAT } from '../constants/storageConstants';
import * as storageConstants from '../constants/storageConstants';
import * as toast from "../wrapper/toast";
import { isExist } from '../utils/objectUtil';
import { addSearchParamsInHistory } from '../utils/redirectUtil';
import { changeDateFormat } from '../utils/dateUtil';
import { capitalizeText, isNullOrEmpty } from '../utils/stringUtils';
import { SIMILAR_SEARCH_CRITERIA } from '../constants/searchConstants';
import { getIdWithPrefix } from '../utils/userProfileID';

const localStorageArray = [storageConstants.AUTH, storageConstants.PROFILE, storageConstants.USER_ROLE];
const sessionStorageArray = [storageConstants.DATA, storageConstants.SKILLS, storageConstants.LOCATIONS, storageConstants.GUEST_USER];

export function imgError(image) {
    image.target.onerror = null;
}

export async function getLocations(text) {
    var locations = await ApiHelper.getLocationByNameOrPincode(text).then((response) => {
        if (response.isSuccess === true) {
            return response.data;
        }
        else {
            return [];
        }
    });

    return locations;
}

export function isValidCurrency(amount) {
    if (amount !== null && amount !== '') {
        var num = removeCommaFromAmount(amount);
        return !isNaN(num) ? true : false;
    } else {
        return true;
    }
}

export function currencyFormatter(amount, rounding = true) {
    if (amount === null || amount === '' || amount === undefined) {
        return 0;
    }

    var num = removeCommaFromAmount(amount, rounding);
    if (!isNaN(num)) {
        return num.toLocaleString(CURRENCY_FORMAT)
    } else {
        return 0;
    }
}

export function currencyText(amount, rounding = true) {
    if (amount === null || amount === '' || amount === undefined) {
        return 0;
    }

    const num = removeCommaFromAmount(amount, rounding);

    if (!isNaN(num)) {
        // Format based on the size of the number
        if (num >= 1000000) {
            // Convert to millions (M)
            const inMillions = num / 1000000;
            return `${inMillions.toFixed(2)}M`;
        } else if (num >= 100000) {
            // Convert to lakhs (l)
            const inLakhs = num / 100000;
            return `${inLakhs.toFixed(2)}L`;
        } else if (num >= 1000) {
            // Convert to thousands (k)
            const inThousands = num / 1000;
            return `${inThousands.toFixed(2)}K`;
        }

        // Return as a localized string if less than 1000
        return num.toLocaleString(CURRENCY_FORMAT);
    } else {
        return 0;
    }
}




export function removeCommaFromAmount(amount, rounding = true) {
    if (amount !== null && amount !== '') {
        var regex = /[,\s]/g;
        var num = amount?.toString().replace(regex, '');
        if (rounding) {
            return Math.round(parseFloat(num, 10));
        } else {
            return parseFloat(num, 10)
        }
    } else {
        return amount;
    }
}

export function checkEmailValid(email) {
    const regex = /^(([A-Za-z0-9](?!.*\.{2})[A-Za-z0-9_\-\.]+[A-Za-z0-9])|([A-Za-z0-9]{1,60}))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/; /* eslint-disable-line */
    return regex.test(email);
}

export function sortWorkProfileArray(array) {

    return array.sort(function (a, b) {
        var keyA = new Date(a.from_date),
            keyB = new Date(b.from_date);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
    });

}

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function getRegularExpression(text) {
    return new RegExp(`${escapeRegExp(text)}`, "gi");
}

export function syncSkillData(skills = []) {
    var skillData = getLocalData(storageConstants.SKILLS);
    const skills_ids = skills.map(function (skill) {
        return skill.skill_id;
    });
    if (skillData === null) {
        return true;
    }
    const skillData_ids = skillData.map(function (skill) {
        return skill.id;
    });
    var notFound = skills_ids.find((val, index) => {
        return !skillData_ids.includes(parseInt(val))
    })

    return notFound;
}

export async function getSkills() {
    let skills = await ApiHelper.getSkills().then((response) => {
        if (response.isSuccess === true) {
            storeLocalData(storageConstants?.SKILLS,response.data)
            return response.data;
        }
        else {
            toast.warn(response.message);
            return [];
        }
    });

    return skills;
}

export function storeLocalData(name, data) {
    try {
        if (sessionStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.setItem(name, data) : sessionStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        else if (localStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.setItem(name, data) : localStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        return true;
    } catch (e) {
        alert('Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.');
        return false;
    }

}

export function getLocalData(name) {
    try {
        if (sessionStorageArray.includes(name)) {
            
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.getItem(name) : JSON.parse(sessionStorage.getItem(name));
        }
        else if (localStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.getItem(name) : JSON.parse(localStorage.getItem(name));
        }
        return null;
    } catch (e) {
        return null;
    }

}

export function clearLocalStorageData() {
    try {
        localStorage.clear();
        return true;
    } catch (e) {
        return false;
    }

}

export async function getSkillByName(text) {
    if (text && text.length > storageConstants.SKILL_THRESHOLD) {
        var localStoredSkills = [];
        var skills = [];

        localStoredSkills = isExist(getLocalData(storageConstants.SKILLS)) ? getLocalData(storageConstants.SKILLS) : [];

        if (localStoredSkills.length > 0) {
            skills = localStoredSkills.filter(skill => {
                return skill.name?.startsWith(text) || skill.name?.toLowerCase().startsWith(text.toLowerCase());
            })
        }

        if (skills.length < 1) {
            const res = await ApiHelper.getSkillByName(text);

            if (res.isSuccess === true) {
                if (res.data.length < 1) {
                    skills = [];
                } else {
                    skills = res.data;
                    storeLocalData(storageConstants.SKILLS, [...localStoredSkills, ...res.data]);
                }
            }
        }

        return sortByBestMatch(skills,text);
    }
}

function sortByBestMatch(objects, keyword) {
    return objects.sort((a, b) => {
        const scoreA = getMatchScore(a.name, keyword);
        const scoreB = getMatchScore(b.name, keyword);

        // Primary sort: Match score (higher score first)
        if (scoreA !== scoreB) {
            return scoreB - scoreA;
        }

        // Secondary sort: Length (shorter length first)
        return a.name.length - b.name.length;
    });
}

function getMatchScore(name, keyword) {
    const lowerName = name.toLowerCase();
    const lowerKeyword = keyword.toLowerCase();

    if (lowerName === lowerKeyword) {
        return 3; // Exact match
    } else if (lowerName.startsWith(lowerKeyword)) {
        return 2; // Starts with keyword
    } else if (lowerName.includes(lowerKeyword)) {
        return 1; // Contains keyword
    } else {
        return 0; // No match
    }
}


export function dateFormat(date) {
    return changeDateFormat(date);
}

export function getYearsList(yearDiff) {
    const year = new Date().getFullYear();
    return Array.from({ length: yearDiff }, (v, i) => year - yearDiff + i + 1);
}

export function checkRecordExists(data) {
    return (data === null || data === undefined || data === "") ? false : true;
}

export function isValidChartData(data) {
    return (data !== undefined && ((Array.isArray(data) && data > 0) || !Array.isArray(data))) ? true : false;
}

export function isValidPassword(password) {
    return (password.length >= 6 && password.length <= 12) ? true : false;
}

export function addDataOnFocusOut(suggestion, setSuggestion, setData, setPreferredData, historyParam = '') {
    var i = 0;
    if (suggestion !== undefined && suggestion.length > 0) {
        if (setData !== undefined) {
            setData((value) => {
                if (value.includes(suggestion[i].id)) {
                    return [...value]
                }
                return [...value, suggestion[i].id]
            });
        }

        setPreferredData(value => {
            if (value.some(val => val.id === suggestion[i].id)) {
                return [...value]
            }
            return [...value, suggestion[i]]
        });

        if (historyParam !== '') {
            addSearchParamsInHistory(historyParam, suggestion[i].id);
        }
        setSuggestion([]);
    }
}

export function getCandidateExperienceText(experience) {
    if (experience <= 0) {
        return 'Fresher'
    } else {
        var text = Math.floor(experience / 12) + 'Y ';
        text += experience && Math.floor(experience % 12) > 0 ? Math.floor(experience % 12) + 'M' : '';
        return text;
    }
}

export function getSkillPercentage(totalExperience, skillExperience) {
    if (isNaN(totalExperience) || isNaN(skillExperience)) {
        return 0;
    } else if (totalExperience <= 0 || skillExperience <= 0) {
        return 0;
    } else if (skillExperience >= totalExperience) {
        return 100;
    } else {
        return ((skillExperience / totalExperience) * 100).toFixed(3);
    }
}

export function getBarColor(profilePercentage = 0) {
    if (profilePercentage >= 0 && profilePercentage <= 40) {
        return "#FBBC09";
    } else if (profilePercentage >= 41 && profilePercentage <= 70) {
        return "#4B8BFF";
    } else if (profilePercentage >= 71 && profilePercentage <= 100) {
        return "#20C9AC";
    } else {
        return "#E3E3E3";
    }
}

export function getPercentage(profilePercentage = 0) {
    return profilePercentage > 100 ? 100 : Math.round(profilePercentage);
}

export const addCandidateToSaveLater = (userId, setLoading, redirect = true) => {
    setLoading(true);
    ApiHelper.saveCandidate({ candidate_id: userId }).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
            toast.success(response.message);

            if (redirect) {
                setTimeout(() => {
                    window.location.href = "/view-later";
                }, 700);
            }
        } else {
            toast.warn(response.message);
        }
    });
}

export const viewSimilarCandidates = (candidateData, setLoading, isSimilarCriteriaChecked) => {
    if (isNullOrEmpty(candidateData)) {
        return;
    }

    const nameParams = [];
    const valueParams = [];

    setLoading(true);
    const { skills, location_id, full_name, id } = candidateData;

    const addDataInHistory = (criteria, value) => {
        nameParams.push(criteria);
        valueParams.push(value);
    };

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.SKILLS) && skills.length > 0) {
        skills.forEach((s) => {
            addDataInHistory("skills", typeof s === 'object' ? s.id : s);
        });
        addDataInHistory("isSimilarSkills", 1);
    }

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.LOCATION) && !isNullOrEmpty(location_id)) {
        addDataInHistory("locations", location_id);
        addDataInHistory("isSimilarLocation", 1);
    }

    const addEducationOrEmploymentCriteria = (criteria) => {
        const data = `${capitalizeText(criteria)} - ${full_name} (${getIdWithPrefix(id)})`;
        addDataInHistory(criteria, data);
    };

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EDUCATION)) {
        addEducationOrEmploymentCriteria("education");
    }

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT)) {
        addEducationOrEmploymentCriteria("employment");
    }

    if (isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EDUCATION) || isSimilarCriteriaChecked(SIMILAR_SEARCH_CRITERIA.EMPLOYMENT)) {
        addDataInHistory("u", id);
    }

    let searchQuery = '?';
    nameParams.forEach((name, i) => {
        searchQuery += name;
        searchQuery += '=';
        searchQuery += valueParams[i];
        searchQuery += '&';
    });

    window.location.href = "/jobseeker/search" + searchQuery;
}

export const preventPlusMinusInNumberInput = (e) => {
    // Prevent entry of minus sign (keycode 189) and plus sign (keycode 187)
    if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 187 || e.keyCode === 107) {
        e.preventDefault();
    }
}


export async function getIndustryDomainByName(text) {
    if (text && text.length > storageConstants.INDUSTRY_DOMAIN_THRESHOLD) {
        var localStoredIndustryDomains = [];
        var domains = [];
        const masterData = getLocalData(storageConstants.DATA);
        console.log(masterData?.industry_domains);
        
        localStoredIndustryDomains = isExist(masterData?.industry_domains) ? masterData?.industry_domains : [];

        if (localStoredIndustryDomains.length > 0) {
            domains = localStoredIndustryDomains.filter(domain => {
                return domain.name?.startsWith(text) || domain.name?.toLowerCase().startsWith(text.toLowerCase());
            })
        }

        console.log("domains",domains);
        

        return domains;
    }
}