import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import "./GigSearch.scss";
import ClipLoader from "react-spinners/ClipLoader";
import * as toast from "@/wrapper/toast";
import * as ApiHelper from "@/services/apiHelper";
import * as storageConstants from "../../constants/storageConstants";
import * as commonServices from "@/services/common";
import emptyResult from "@/Assets/svgs/search_no_result.svg";
import { isLoggedIn, isCandidate } from "@/utils/authUtil";
import PayWall from "@Components/Common/PayWall";
import DateRangePicker from "@Components/Common/DateRangePicker";
import CustomPagination from "@Components/Pagination/Pagination";
import mapPin from "@/Assets/svgs/map-pin.svg";
import { locations } from "@/utils/arrayUtils";
import ModalLocation from "@Components/Modals/ModalLocation";
import { changeDateFormat } from "@/utils/dateUtil";
import { addNavShadow } from "@/utils/styleUtils";
import { validateKeyword } from "@/utils/validation";
import { MaxWidthContainer } from "@Components/Common/MaxWidthContainer";
import Text from "../_widgets/Text/Text";
import { RxCross2 } from "react-icons/rx";
import { highlightText, isNullOrEmpty, createSlugFromString } from "../../utils/stringUtils";
import Button from "../_widgets/Button/Button";
import Link from "../_widgets/Link/Link";
import FilterBar from "../_widgets/FIlterBar/FilterBar";
import { GIG_SEARCH_FILTERS, ORDER_BY, ORDER_BY_TEXT, SEARCH_FILTERS } from "../../constants/searchConstants";
import * as stringConstants from "../../constants/stringConstants";
import { transformArrayFromObject } from "../../utils/arrayUtils";
import { getChipsNameFromMasterData, getLocationChipsName, getSkillChipsName, returnNewPayloadArray } from "../../utils/searchUtils";
import {
  addSearchParamsInHistory,
  removeSearchParamsInHistory,
  removeAllParams,
  removeSearchParamsInHistoryWithoutValue
} from "../../utils/redirectUtil";
import IconResource from '../_widgets/IconResource/IconResource';
import CustomTooltip from '../../../src/utils/CustomTooltip'
import Loader from "../_widgets/Loader/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function GigSearch({
  jobSearch,
  locationSearch,
  locationSearchName,
  jobSearchName,
  searchType,
  keywordSearch,
  setShowStickySearchBar,
  isStickySearch,
  setIsStickySearch,
  setSearchFilterState,
  keywordSeachFilters,
  setKeywordSeachFilters,
}) {
  const [filter, setFilter] = useState(false);
  const [filting, setFilting] = useState("hidden");
  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");
  const params = new URLSearchParams(window.location.search);
  var searchTypeParam = params.getAll(SEARCH_FILTERS?.SEARCH_TYPE);
  var keywordParam = params.getAll(SEARCH_FILTERS?.KEYWORD);

  const [keyword, setKeyword] = useState(keywordParam && keywordParam[0] !== undefined ? keywordParam[0] : keywordSearch ? keywordSearch : "");
  const [searchBy, setSearchBy] = useState(searchTypeParam && keywordParam[0] !== undefined ? searchTypeParam[0] : searchType ? searchType : "smart");
  const [orderByUser, setOrderByUser] = useState(false);
  const [showModalLocation, setShowModalLocation] = useState(false);

  const datePickerStartRef = useRef();
  const datePickerEndRef = useRef();

  const masterData = commonServices.getLocalData(storageConstants.DATA);

  const jobLocations = commonServices.getLocalData(storageConstants.LOCATIONS);

  const [featuredGigs, setFeaturedGigs] = useState([]);

  const maxBudgetRef = useRef();

  const initialDisplayLocations = {
    count: commonServices.getLocalData(storageConstants.LOCATIONS)?.length - 10, arr: commonServices.getLocalData(storageConstants.LOCATIONS)
  }

  const [displayLocations, setDisplayLocations] = useState(initialDisplayLocations);

  const setPreferredSkillData = (arr) => {
    arr && commonServices.storeLocalData(storageConstants.SKILLS, arr);
  }

  const [activePage, setActivePage] = useState(1);

  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    FindWork(activePage);
    setActivePage(activePage);
  };

  const setIsKeywordReset = (value) => {
    setSearchPayload({ ...searchPayload, keywordReset: [value] })
  }

  useEffect(() => {
    window.addEventListener('popstate', handleBrowserNavigation);
    setParamsFromURL();

    return () => {
      window.removeEventListener('popstate', handleBrowserNavigation);
    };
  }, []);

  const handleBrowserNavigation = (event) => {
    // Back or forward button clicked
    if (event.state) {
      setParamsFromURL();
    }
  };

  // This payload will be used for first/raw API call
  const initialPayload = {
    skills: [], locations: [], gig_types: [], min_budget: [null], max_budget: [null], start_date: [null], end_date: [null], orderBy: [ ORDER_BY.NEWEST], orderByText: [ ORDER_BY_TEXT.NEWEST], keywordReset: [false],
  };

  const [gigBudget, setGigBudget] = useState({ min_budget: null, max_budget: null });

  const [searchPayload, setSearchPayload] = useState(initialPayload);

  const setParamsFromURL = () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    const keyArr = [];
    const valArr = [];
    for (const [key, value] of searchParams.entries()) {
      keyArr.push(key);
      valArr.push(value);
    }

    setDataInPayload(keyArr, valArr);
  }

  const singleValueArrTypes = [GIG_SEARCH_FILTERS.MIN_BUDGET, GIG_SEARCH_FILTERS.MAX_BUDGET, GIG_SEARCH_FILTERS.START_DATE, GIG_SEARCH_FILTERS.END_DATE];

  const multiValueArrTypes = [GIG_SEARCH_FILTERS.SKILLS, GIG_SEARCH_FILTERS.LOCATIONS, GIG_SEARCH_FILTERS.GIG_TYPES];

  const validKeys = [GIG_SEARCH_FILTERS.SKILLS, GIG_SEARCH_FILTERS.LOCATIONS, GIG_SEARCH_FILTERS.GIG_TYPES, GIG_SEARCH_FILTERS.MIN_BUDGET, GIG_SEARCH_FILTERS.MAX_BUDGET, GIG_SEARCH_FILTERS.START_DATE, GIG_SEARCH_FILTERS.END_DATE];

  const setDataInPayload = (keyArr, valueArr) => {
    let tempPayload = {
      skills: [], locations: [], gig_types: [], min_budget: [null], max_budget: [null], start_date: [null], end_date: [null], orderBy: [ORDER_BY.NEWEST], orderByText: [ORDER_BY_TEXT.NEWEST], keywordReset: [false],
    };

    if (Array.isArray(keyArr) && Array.isArray(valueArr) &&
      keyArr.length > 0 && valueArr.length === keyArr.length) {
      keyArr.forEach((key, index) => {
        if (validKeys.includes(key)) {
          if (!tempPayload[key]) {
            tempPayload[key] = [];
          }

          if (singleValueArrTypes.includes(key)) {
            if (key === 'start_date' || key === 'end_date') {
              tempPayload[key][0] = new Date(valueArr[index]);
            } else if (key === 'min_budget' || key === 'max_budget') {
              tempPayload[key][0] = valueArr[index];
            } else {
              tempPayload[key][0] = parseInt(valueArr[index]);
            }
          } else if (multiValueArrTypes.includes(key)) {
            if (!tempPayload[key].includes(parseInt(valueArr[index]))) {
              tempPayload[key].push(parseInt(valueArr[index]));
            }
          }
        }
      });
    }

    // if (isLoggedIn() && isCandidate() && !orderByUser) {
    //   tempPayload['orderBy'] = [ORDER_BY.RELEVANT];
    //   tempPayload['orderByText'] = [ORDER_BY_TEXT.RELEVANT];
    // }
    // else if (!isLoggedIn() && !isCandidate() && !orderByUser) {
    //   tempPayload['orderBy'] = [ORDER_BY.NEWEST];
    //   tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
    // }

    if(!orderByUser){
      tempPayload['orderBy'] = [ORDER_BY.NEWEST];
      tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
    }

    setSearchPayload(tempPayload);
  };

  const handleDisplayLocationChange = (text) => {
    const locArr = commonServices.getLocalData(storageConstants.LOCATIONS);

    if (locArr) {
      if (text.length > 1) {
        const arr = locArr.length > 0 ? locArr.filter(l => l.name.toLowerCase().includes(text.toLowerCase())) : [];

        setDisplayLocations({ count: arr.length, arr });
      } else {
        setDisplayLocations({ count: locArr.length, arr: locArr });
      }
    }
  }

  const handleDateFormatChange = (fromDate, toDate) => {
    return `${changeDateFormat(fromDate)} - ${changeDateFormat(toDate)}`
  }

  const handleMinBudgetBlur = (e) => {
    const val = e.target.value;

    if (isNullOrEmpty(searchPayload.max_budget[0])) {
      if (val !== searchPayload.min_budget[0]) {
        if (!isNullOrEmpty(val)) {
          manageDataInPayload('min_budget', val, 'a');
          maxBudgetRef.current.focus();
        } else if (isNullOrEmpty(val)) {
          manageDataInPayload('min_budget', val, 'r');
        }
      }
    } else if (!isNullOrEmpty(searchPayload.max_budget[0])) {
      if (parseInt(val) !== parseInt(searchPayload.min_budget[0])) {
        if (!isNullOrEmpty(val)) {
          if (parseInt(val) <= parseInt(searchPayload.max_budget[0])) {
            manageDataInPayload('min_budget', val, 'a');
          } else {
            toast.warn(stringConstants.MIN_MAX_BUDGET_ERROR);
          }
        } else if (isNullOrEmpty(val)) {
          manageDataInPayload('min_budget', val, 'r');
        }
      }
    }
  }

  const handleMaxBudgetBlur = (e) => {
    const val = e.target.value;

    if (isNullOrEmpty(searchPayload.min_budget[0])) {
      if (parseInt(val) !== parseInt(searchPayload.max_budget[0])) {
        if (!isNullOrEmpty(val)) {
          manageDataInPayload('max_budget', val, 'a');
        } else if (isNullOrEmpty(val)) {
          manageDataInPayload('max_budget', val, 'r');
        }
      }
    } else if (!isNullOrEmpty(searchPayload.min_budget[0])) {
      if (parseInt(val) !== parseInt(searchPayload.max_budget[0])) {
        if (!isNullOrEmpty(val)) {
          if (parseInt(val) >= parseInt(searchPayload.min_budget[0])) {
            manageDataInPayload('max_budget', val, 'a');
          } else {
            toast.warn(stringConstants.MAX_MIN_BUDGET_ERROR);
          }
        } else if (isNullOrEmpty(val)) {
          manageDataInPayload('max_budget', val, 'r');
        }
      }
    }
  }

  useEffect(() => {
    const { min_budget: min, max_budget: max } = searchPayload;
    let tempBudget = { ...gigBudget };

    tempBudget.min_budget = min;
    tempBudget.max_budget = max;
    setGigBudget(tempBudget);
  }, [searchPayload.min_budget, searchPayload.max_budget])

  const manageDataInPayload = (type, data, action) => {
    const { SKILLS, LOCATIONS, GIG_TYPES, MIN_BUDGET, MAX_BUDGET, START_DATE, END_DATE } = GIG_SEARCH_FILTERS;
    let tempPayload = { ...searchPayload };

    if (action === 'a') {
      if (type === SKILLS || type === LOCATIONS) {
        const newData = typeof data === 'function' ? data(tempPayload[type]) : data;

        if (Array.isArray(newData)) {
          tempPayload[type] = newData;
        }
        else {
          tempPayload[type] = returnNewPayloadArray(tempPayload[type], newData, action);
          addSearchParamsInHistory(type, newData);
        }

        // if (isLoggedIn() && isCandidate() && !orderByUser) {
        //   tempPayload['orderBy'] = [ORDER_BY.RELEVANT];
        //   tempPayload['orderByText'] = [ORDER_BY_TEXT.RELEVANT];
        // }
        // else if (!isLoggedIn() && !isCandidate() && !orderByUser) {
        //   tempPayload['orderBy'] = [ORDER_BY.NEWEST];
        //   tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
        // }

        if(!orderByUser){
          tempPayload['orderBy'] = [ORDER_BY.NEWEST];
          tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
        }
      }
      else if (type === GIG_TYPES) {
        tempPayload[type] = returnNewPayloadArray(tempPayload[type], data, action);
        addSearchParamsInHistory(type, data);
      }
      else if (type === START_DATE || type === END_DATE) {
        tempPayload[type] = [data];
      }
      else if (type === MIN_BUDGET) {
        tempPayload[type] = [data];
        addSearchParamsInHistory(type, data);
      }
      else if (type === MAX_BUDGET) {
        tempPayload[type] = [data];
        addSearchParamsInHistory(type, data);
      }
      else if (type === START_DATE || type === END_DATE) {
        tempPayload[type] = [data];
      }
    }
    else if (action === 'r') {
      if (type === SKILLS || type === LOCATIONS) {
        removeSearchParamsInHistory(type, data);
        const arr = returnNewPayloadArray(tempPayload[type], data, action);
        tempPayload[type] = arr;

        // if (isLoggedIn() && isCandidate() && !orderByUser) {
        //   tempPayload['orderBy'] = [ORDER_BY.RELEVANT];
        //   tempPayload['orderByText'] = [ORDER_BY_TEXT.RELEVANT];
        // }
        // else if (!isLoggedIn() && !orderByUser) {
        //   tempPayload['orderBy'] = [ORDER_BY.NEWEST];
        //   tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
        // }
        if(!orderByUser){
          tempPayload['orderBy'] = [ORDER_BY.NEWEST];
          tempPayload['orderByText'] = [ORDER_BY_TEXT.NEWEST];
        }
      }
      else if (type === GIG_TYPES) {
        tempPayload[type] = returnNewPayloadArray(tempPayload[type], data, action);
        removeSearchParamsInHistory(type, data);
      }
      else if (type === START_DATE || type === END_DATE) {
        tempPayload[START_DATE] = [null];
        tempPayload[END_DATE] = [null];
        removeSearchParamsInHistoryWithoutValue([START_DATE, END_DATE]);
      }
      else if (type === MIN_BUDGET) {
        tempPayload[type] = [null];
        removeSearchParamsInHistoryWithoutValue([type]);
      }
      else if (type === MAX_BUDGET) {
        tempPayload[type] = [null];
        removeSearchParamsInHistoryWithoutValue([type]);
      }
    } else {
      if (type === 'dates') {
        tempPayload[START_DATE] = [data[0]];
        tempPayload[END_DATE] = [data[1]];
      }
    }

    setSearchPayload(tempPayload);
  }

  const handleRemoveChips = (data) => {
    const k = Object.keys(data)[0];
    const v = Object.values(data)[0];
    const action = 'r'
    // const action = k === 'min_exp' || k === 'max_exp' ? '' : 'r'

    return manageDataInPayload(k, v, action);
  }

  const getChipsName = (data) => {
    const key = Object.keys(data);
    const value = Object.values(data);

    const { SKILLS, LOCATIONS, GIG_TYPES, MIN_BUDGET, MAX_BUDGET, START_DATE } = GIG_SEARCH_FILTERS;

    if (key[0] === LOCATIONS && searchPayload.locations.includes(value[0])) {
      return getLocationChipsName(value[0]);
    }
    else if (key[0] === SKILLS && searchPayload.skills.includes(value[0])) {
      return getSkillChipsName(value[0]);
    }
    else if (key[0] === GIG_TYPES && searchPayload.gig_types.includes(value[0])) {
      return getChipsNameFromMasterData(GIG_TYPES, value[0]);
    }
    else if (key[0] === START_DATE && value[0] !== null && searchPayload.start_date[0] !== null && searchPayload.end_date[0] !== null) {
      return handleDateFormatChange(searchPayload.start_date[0], searchPayload.end_date[0]);
    }
    else if (key[0] === MIN_BUDGET && searchPayload.min_budget[0] !== null && searchPayload.min_budget[0].includes(value)) {
      return `Min Budget: ${searchPayload.min_budget[0]}`;
    }
    else if (key[0] === MAX_BUDGET && searchPayload.max_budget[0] !== null && searchPayload.max_budget[0].includes(value)) {
      return `Max Budget: ${searchPayload.max_budget[0]}`;
    }
    else {
      return '';
    }
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setKeyword("");
    setOrderByUser(false);
    removeAllParams();
    document.getElementById("search_location_filter").value = "";
    setDisplayLocations(initialDisplayLocations);
    setSearchPayload(initialPayload);
  };

  const [isDateFilter, setIsDateFilter] = useState(false);

  const [featuredData, setFeaturedData] = useState([]);
  const [noResultErr, setNoResultErr] = useState(false);
  const [isPaywallActive, setIsPaywallActive] = useState(false);
  const [isPaywallActiveCancel, setIsPaywallActiveCancel] = useState(false);

  const onLocationChange = (e, element) => {
    if (e.target.checked) {
      manageDataInPayload('locations', element.id, 'a');
      document.getElementById("search_location_filter").value = "";
      addSearchParamsInHistory('locations', element.id);
    } else {
      manageDataInPayload('locations', element.id, 'r');
    }
  };

  const isValidRequest = () => {
    return (
      searchType === "smart" ||
      (searchType === "keyword" && validateKeyword(keyword))
    );
  };

  const FindWork = (activePage) => {
    if (isValidRequest()) {
      window.scrollTo(0, 0);
      const { skills, locations, gig_types, min_budget, max_budget, start_date, end_date, orderBy } = searchPayload;

      const obj = {
        skills,
        locations,
        job_types: gig_types,
        gig_from: changeDateFormat(start_date[0], 'YYYY-MM-DD'),
        gig_to: changeDateFormat(end_date[0], 'YYYY-MM-DD'),
        min_budget: min_budget[0],
        max_budget: max_budget[0],
        salary_types: [],
        limit: 20,
        order_by: orderBy[0],
        pageNumber: activePage,
        q: keyword,
      };

      setLoading(true);
      ApiHelper.searchGig(obj).then((response) => {
        if (response.isSuccess === true) {
          setFeaturedData(response.data);
          setActivePage(response.data.current_page);
          if (response.data.data.length < 1) {
            setNoResultErr(true);
          }
        } else {
          toast.warn(response.message);
        }
        setLoading(false);
      });
    } else {
      toast.warn(stringConstants.INVALID_KEYWORD_ERROR_MESSAGE);
    }
  };

  const [workRun, setWorkRun] = useState(false);

  useEffect(() => {
    if (workRun === false) {
      setWorkRun(true);
    } else {
      FindWork(activePage);

      let skillName = '';
      let locationName = '';
      if (searchPayload.skills.length > 0) {
        skillName = getSkillChipsName(searchPayload.skills[0]);
      }
      if (searchPayload.locations.length > 0) {
        locationName = getLocationChipsName(searchPayload.locations[0]);
      }

      setSearchFilterState({ skill: [skillName], location: [locationName], keyword, searchType });
    }
  }, [searchPayload]);

  useEffect(() => {
    setLoading(true);
    ApiHelper.getLocations("GIG_LOCATIONS").then((response) => {
      if (response.isSuccess === true) {
        commonServices.storeLocalData(storageConstants.LOCATIONS, response.data);
        setDisplayLocations({ count: response.data.length - 10, arr: response.data });
      } else {
        console.log("error");
      }
    });

    ApiHelper.getSkills().then((response) => {
      if (response.isSuccess === true) {
        var resdata = [];
        response.data.map((data, i) => {
          resdata.push(data);
        });

        commonServices.storeLocalData(storageConstants.SKILLS, response.data);
      } else {
        console.log("error");
      }
    });

    getFeaturedGigsData();
  }, []);

  const getFeaturedGigsData = async () => {
    await ApiHelper.getFeaturedGigs("searchpage").then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        setFeaturedGigs(response.data);

        let ids = response.data.map((gig) => gig?.eid);

        addFeatureGigImpression(ids, "impression");
      } else {
        toast.warn(response.message);
      }
    });
  };

  const addFeatureGigImpression = (gig_id, type = "impression") => {
    if (gig_id.length > 0) {
      let formData = new FormData();
      formData.append("gig_id", gig_id);
      formData.append("page", "searchpage");
      formData.append("type", type);
      ApiHelper.addGigImpression(formData).then((response) => {
        if (response.isSuccess === true) {
          console.log("addGigImpression success");
        } else {
          console.error("addGigImpression error");
        }
      });
    }
  };

  const [isSticky, setIsSticky] = useState(false);
  const [y, setY] = useState(document.scrollingElement.scrollHeight);

  const handleNavigation = useCallback(
    (e) => {
      if (y > window.scrollY) {
        filterScrollTopNav();
      } else if (y < window.scrollY) {
        filterScrollDownNav();
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const filterScrollDownNav = () => {
    const searchElement = document.getElementById("result_section");
    if (
      searchElement !== null &&
      searchElement.getBoundingClientRect().top < 0
    ) {
      setShowStickySearchBar(true);
      setIsStickySearch(false);
      addNavShadow();
    }
  };

  const filterScrollTopNav = () => {
    const searchElement = document.getElementById("result_section");
    if (
      searchElement !== null &&
      searchElement.getBoundingClientRect().top > 0
    ) {
      addNavShadow();
      setShowStickySearchBar(false);
      setIsStickySearch(false);
    }
  };

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const navigateGigDetail = (gigData) => {
    window.open(
      "/gig/details/" +
      gigData.eid +
      "/" +
      createSlugFromString(gigData.title) +
      "?q=" +
      keyword,
      "_blank"
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : null}

      {isPaywallActive ? (
        <PayWall
          desc={"Your dream Gigs"}
          onCancel={() => {
            setIsPaywallActive(false);
            setIsPaywallActiveCancel(true);
          }}
        />
      ) : null}
      <div
        className={`overlay ${filting}`}
        onClick={() => {
          setFilter(false);
          setFilting("hidden");
        }}
      ></div>

      <ModalLocation
        showModal={showModalLocation}
        setShowModal={setShowModalLocation}
        locations={jobLocations}
        selectedLocations={searchPayload.locations}
        onChange={onLocationChange}
      />

      <MaxWidthContainer pageKey="gig_search">
        <div className="job_page">
          <div className="body_section tt-container">
            <FilterBar
              searchedSkills={searchPayload.skills}
              setSearchedSkills={(type, data) => manageDataInPayload(type, data, 'a')}
              searchedLocations={searchPayload.locations}
              setSearchedLocations={(type, data) => manageDataInPayload(type, data, 'a')}
              FindWork={FindWork}
              searchType={searchBy}
              setSearchType={setSearchBy}
              keyword={keyword}
              setKeyword={setKeyword}
              searchBar={"normal"}
              module="Gigs"
              keywordSeachFilters={keywordSeachFilters}
              setKeywordSeachFilters={setKeywordSeachFilters}
              setPreferredSkillData={setPreferredSkillData}
              isStickySearch={isStickySearch}
              activePage={activePage}
              isKeywordReset={searchPayload.keywordReset[0]}
              setIsKeywordReset={setIsKeywordReset}
            />

            <div className="result_section" id="result_section">
              {featuredData &&
                featuredData.data &&
                featuredData.data.length > 0 ? (
                <div
                  className={
                    isSticky ? "result_feed sticky-effect" : "result_feed"
                  }
                  id="result_feed"
                >
                  <div className="result_header d-flex justify-content-between align-items-center">
                    <Text
                      type="h2"
                      className="topTitle"
                      text="Search result"
                    ></Text>
                    <div className="result_header_sort">
                      <Text text="Sort by:"></Text>
                      <div className="dropdown">
                        <Link
                          type="a"
                          className="btn btn-secondary dropdown-toggle"
                          href={undefined}
                          role="button"
                          id="dropdownMenuLink"
                          dataBsToggle="dropdown"
                          aria-expanded="false"
                          text={searchPayload.orderByText}
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <Link
                              type="a"
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.NEWEST], orderByText: [ORDER_BY_TEXT.NEWEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                              text="Newest First"
                            />
                          </li>
                          <li>
                            <Link
                              type="a"
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.OLDEST], orderByText: [ORDER_BY_TEXT.OLDEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                              text="Oldest First"
                            />
                          </li>
                          {/* {isLoggedIn() && isCandidate() && <li>
                            <Link
                              type="a"
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.RELEVANT], orderByText: [ORDER_BY_TEXT.RELEVANT] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                              text="Most Relevant"
                            />
                          </li>} */}
                        </ul>
                      </div>
                      <div className="filter_tab_header">
                        <div
                          className="list-group"
                          id="list-tab-1"
                          role="tablist"
                        >
                          <a
                            className="list-group-item list-group-item-action active"
                            id="list_1"
                            data-bs-toggle="list"
                            href="#list_1_content"
                            role="tab"
                            aria-controls="list_1_content"
                          >
                            <CustomTooltip placement={"top"} text="Grid View">
                              <div className="round">
                                <img
                                  onError={commonServices.imgError}
                                  src="/Assets/svgs/list_view_icon.svg"
                                  alt=""
                                  className="ig"
                                />
                              </div>
                            </CustomTooltip>
                          </a>

                          <a
                            className="list-group-item list-group-item-action"
                            id="list_2"
                            data-bs-toggle="list"
                            href="#list_2_content"
                            role="tab"
                            aria-controls="list_2_content"
                          >
                            <CustomTooltip placement={"top"} text="Table View">
                              <div className="round">
                                <img
                                  onError={commonServices.imgError}
                                  src="/Assets/svgs/grid_view_icon.svg"
                                  alt=""
                                  className="ig"
                                />
                              </div>
                            </CustomTooltip>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-0" id="featured-3">
                    {Object.values(searchPayload).length > 0 &&
                      <div className="row all-filters d-flex" style={{ margin: 0 }}>
                        {transformArrayFromObject(searchPayload).map((data, i) => {
                          if (!isNullOrEmpty(getChipsName(data))) {
                            return <div key={i} className="col-2 f_t">
                              <p>{getChipsName(data)}</p>
                              <button className="del_jt" onClick={() => handleRemoveChips(data)}>
                                <img src="../../Assets/svgs/x-grey.svg" alt="cross" />
                              </button>
                            </div>
                          }
                        })}
                      </div>}
                  </div>

                  <div className="tab-content" id="nav_tabContent_1">
                    <div
                      className="tab-pane fade show active"
                      id="list_1_content"
                      role="tabpanel"
                      aria-labelledby="list_1"
                    >
                      <div className="px-0" id="featured-3">
                        <div
                          style={{
                            marginTop: "1.5rem",
                          }}
                          className="cards__container"
                        >
                          {featuredData.data.map((gigData, key) => {
                            return (
                              <div className="card__hover main-card">
                                <div className="hand-hover">
                                  <div className="job__card justify-content-between box-hover">
                                    <div className="image_container">
                                      <img
                                        className="featured__img"
                                        src={gigData?.company?.logo}
                                        alt=""
                                      />
                                    </div>

                                    <div
                                      onClick={() => navigateGigDetail(gigData)}
                                      className="job__content pb-2"
                                    >
                                      <p
                                        className="paragraph_1 truncate-2"
                                        style={{ textWrap: "wrap" }}
                                        title={gigData?.title}
                                        dangerouslySetInnerHTML={{
                                          __html: highlightText(
                                            keyword,
                                            gigData?.title
                                          ),
                                        }}
                                      ></p>
                                      <p
                                        className="paragraph_3"
                                        style={{ color: "#9398A1" }}
                                        dangerouslySetInnerHTML={{
                                          __html: highlightText(
                                            keyword,
                                            gigData?.type?.name
                                          ),
                                        }}
                                      ></p>
                                      <p
                                        className="paragraph_3 d-flex gap-1 my-2"
                                        style={{ color: "#4B8BFF" }}
                                      >
                                        <span className="map-pin">
                                          <img src={mapPin} alt="" />
                                        </span>{" "}
                                        <span
                                          className="truncate-1"
                                          title={
                                            gigData?.engagement_mode_id !==
                                              null &&
                                              gigData?.engagement_mode?.name ===
                                              storageConstants.REMOTE
                                              ? gigData?.engagement_mode?.name
                                              : gigData?.locations
                                                ? locations(gigData.locations)
                                                : "India"
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: highlightText(
                                              keyword,
                                              gigData?.engagement_mode_id !==
                                                null &&
                                                gigData?.engagement_mode
                                                  ?.name === storageConstants.REMOTE
                                                ? gigData?.engagement_mode?.name
                                                : gigData?.locations
                                                  ? locations(gigData.locations)
                                                  : "India"
                                            ),
                                          }}
                                        ></span>
                                      </p>
                                    </div>

                                    {gigData?.is_featured ? (
                                      <div className="bookmark">
                                        <CustomTooltip text="Featured Gig">
                                          <IconResource type="bookmark" color="var(--red)" />
                                        </CustomTooltip>
                                      </div>
                                    ) : null}

                                    <div
                                      className="footer"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        className="paragraph_3_name truncate-1"
                                        title={gigData?.company?.name}
                                        style={{ fontWeight: 600 }}
                                        dangerouslySetInnerHTML={{
                                          __html: highlightText(
                                            keyword,
                                            gigData?.company?.name
                                          ),
                                        }}
                                      ></p>
                                      <Text
                                        style={{ color: "#9398A1" }}
                                        className="paragraph_4 truncate-1"
                                        title={gigData?.updated_date}
                                        text={gigData?.updated_date}
                                      ></Text>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade common_items"
                      id="list_2_content"
                      role="tabpanel"
                      aria-labelledby="list_2"
                      style={{
                        overflowX: "scroll",
                      }}
                    >
                      <table className="table job-list-table">
                        <thead>
                          <tr>
                            <th scope="col">Job</th>
                            <th scope="col">Skills</th>
                            <th scope="col">Location</th>
                            <th scope="col">Company</th>
                            <th scope="col">Job Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {featuredData.data.map((data, key) => {
                            return (
                              <tr
                                key={key}
                                className="hover position-relative"
                                onClick={() => navigateGigDetail(data)}
                              >
                                <td scope="col" className="bold">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        keyword,
                                        data?.title
                                      ),
                                    }}
                                  ></p>
                                </td>
                                <td scope="col">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        keyword,
                                        data?.skills !== null &&
                                          data?.skills !== undefined &&
                                          data.skills.length > 0
                                          ? data?.skills
                                            .map((skill) => skill.name)
                                            .toString()
                                            .split(",")
                                            .join(", ")
                                          : "N/A"
                                      ),
                                    }}
                                  ></p>
                                </td>
                                <td scope="col">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        keyword,
                                        data?.locations !== null &&
                                          data?.locations !== undefined &&
                                          data.locations.length > 0
                                          ? data?.locations
                                            .map((location) => location.name)
                                            .toString()
                                            .split(",")
                                            .join(", ")
                                          : "India"
                                      ),
                                    }}
                                  ></p>
                                </td>
                                <td scope="col">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        keyword,
                                        data?.company?.name
                                      ),
                                    }}
                                  ></p>
                                </td>
                                <td scope="col">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        keyword,
                                        data?.type?.name
                                      ),
                                    }}
                                  ></p>
                                </td>
                                {data?.is_featured ? (
                                  <td
                                    colSpan={2}
                                    className="bookmark"
                                    style={{ right: "-12px", top: "-10px" }}
                                  >
                                    <CustomTooltip text="Featured Gig">
                                      <IconResource type="bookmark" color="var(--red)" />
                                    </CustomTooltip>
                                  </td>
                                ) : null}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div>
                    {/* <p className="page-size-info">Showing {featuredData.from} - {featuredData.to}</p> */}

                    <div className="my-3">
                      {
                        <CustomPagination
                          activePage={activePage}
                          onPageChange={handlePaginationChange}
                          totalData={featuredData?.total}
                          pageLength={featuredData?.per_page}
                          tag="pages"
                          currentPage={setActivePage}
                          isPaywallActiveCancel={isPaywallActiveCancel}
                          dataFrom={featuredData.from}
                          dataTo={featuredData.to}
                        />
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <div className="result_feed">
                  <div className="result_header d-flex justify-content-between align-items-center">
                    <Text
                      type="h2"
                      className="topTitle"
                      text="Search result"
                    ></Text>
                    <div className="result_header_sort">
                      <Text text="Sort by:"></Text>
                      <div className="dropdown">
                        <Link
                          type="a"
                          className="btn btn-secondary dropdown-toggle"
                          href={undefined}
                          role="button"
                          id="dropdownMenuLink"
                          dataBsToggle="dropdown"
                          aria-expanded="false"
                          text={searchPayload.orderByText}
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <Link
                              type="a"
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.NEWEST], orderByText: [ORDER_BY_TEXT.NEWEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                              text="Newest First"
                            />
                          </li>
                          <li>
                            <Link
                              type="a"
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: [ORDER_BY.OLDEST], orderByText: [ORDER_BY_TEXT.OLDEST] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                              text="Oldest First"
                            />
                          </li>
                          {/* {isLoggedIn() && isCandidate() && <li>
                            <Link
                              type="a"
                              className="dropdown-item"
                              onClick={() => {
                                setSearchPayload({ ...searchPayload, orderBy: ORDER_BY.RELEVANT, orderByText: [ORDER_BY_TEXT.RELEVANT] });
                                setOrderByUser(true);
                              }}
                              href={undefined}
                              text="Most Relevant"
                            />
                          </li>} */}
                        </ul>
                      </div>
                      <div className="filter_tab_header">
                        <div
                          className="list-group"
                          id="list-tab-1"
                          role="tablist"
                        >
                          <a
                            className="list-group-item list-group-item-action active"
                            id="list_1"
                            data-bs-toggle="list"
                            href="#list_1_content"
                            role="tab"
                            aria-controls="list_1_content"
                          >
                            <CustomTooltip placement={"top"} text="Grid View">
                              <div className="round">
                                <img
                                  onError={commonServices.imgError}
                                  src="/Assets/svgs/list_view_icon.svg"
                                  alt=""
                                  className="ig"
                                />
                              </div>
                            </CustomTooltip>
                          </a>

                          <a
                            className="list-group-item list-group-item-action"
                            id="list_2"
                            data-bs-toggle="list"
                            href="#list_2_content"
                            role="tab"
                            aria-controls="list_2_content"
                          >
                            <CustomTooltip placement={"top"} text="Table View">
                              <div className="round">
                                <img
                                  onError={commonServices.imgError}
                                  src="/Assets/svgs/grid_view_icon.svg"
                                  alt=""
                                  className="ig"
                                />
                              </div>
                            </CustomTooltip>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container" id="featured-3">
                    <div className="row all-filters d-flex">
                      {Object.values(searchPayload).length > 0 &&
                        transformArrayFromObject(searchPayload).map((data, i) => {
                          if (!isNullOrEmpty(getChipsName(data))) {
                            return <div key={i} className="col-2 f_t">
                              <p>{getChipsName(data)}</p>
                              <button className="del_jt" onClick={() => handleRemoveChips(data)}>
                                <img src="../../Assets/svgs/x-grey.svg" alt="cross" />
                              </button>
                            </div>
                          }
                        })}
                    </div>
                  </div>

                  <div className="blank_content d-flex">
                    {noResultErr ? (
                      <>
                        <img
                          onError={commonServices.imgError}
                          src={emptyResult}
                          alt=""
                        />
                        <Text
                          type="h2"
                          text="Sorry we couldn’t find any matches"
                        ></Text>
                        <Text text="No records found. Please retry with other search parameters"></Text>
                        {/* <p>{getNoDataMessage()}</p> */}
                        <div
                          className="form_buttons"
                          style={{ marginTop: "2em" }}
                        >
                          <Button
                            buttonType="danger"
                            onClick={(e) => {
                              clearFilters(e);
                            }}
                            text="Clear"
                          />
                          <Button
                            buttonType="secondary"
                            onClick={() => {
                              window.history.back();
                            }}
                            text="Back"
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <button
            className={"drawer__toggle-btn"}
            onClick={() => {
              setDrawerIsOpen(!drawerIsOpen);
            }}
          >
            {/* Drawer Toggle Button */}
            <Text
              style={{ fontWeight: "700", wordSpacing: "8px" }}
              text="FILTER YOUR RESULTS"
            />
          </button>

          <div
            className={`drawer__wrapper ${drawerIsOpen ? "show" : ""}`}
            direction="right"
            style={{ right: drawerIsOpen ? 0 : "-100%" }}
          >
            {/* Drawer */}
            <div
              className="drawer__overlay"
              style={{ inset: drawerIsOpen ? 0 : "auto" }}
              onClick={() => {
                setDrawerIsOpen(!drawerIsOpen);
              }}
            ></div>

            <div
              className={`drawer`}
              style={{ width: drawerIsOpen ? "400px" : "0px" }}
            >
              <div className="drawer__header">
                <h2>Filter Search </h2>
                <div className="drawer__header-clear">
                  <button className="clearBtn" onClick={(e) => clearFilters(e)}>
                    Clear All
                  </button>

                  <RxCross2
                    style={{
                      cursor: "pointer",
                    }}
                    color="var(--green)"
                    size={20}
                    className="closeBtn"
                    onClick={() => {
                      setDrawerIsOpen(!drawerIsOpen);
                    }}
                  />
                </div>
              </div>
              <div className="drawer__body">
                <form className="result_filter" id="result_filter">
                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper"><p className="titleCls">Preferred Gig Location</p>
                    </div>

                    <div>
                      <div className="filter-search">
                        <input type="text" placeholder="City" id="search_location_filter" onChange={(e) => handleDisplayLocationChange(e.target.value)} />
                      </div>

                      {displayLocations.arr?.length > 0 ? (
                        displayLocations.arr
                          .sort((a, b) => {
                            return searchPayload.locations.includes(b.id) - searchPayload.locations.includes(a.id);
                          })
                          .slice(0, 10)
                          .map((loc, i) => (
                            <div key={i} className="checkbox_div_c">
                              <input
                                type="checkbox"
                                name=""
                                id={`loc_checked_c_d${i}`}
                                checked={searchPayload.locations.includes(loc.id)}
                                onChange={(e) => {
                                  manageDataInPayload('locations', loc.id, e.target.checked ? 'a' : 'r');
                                  handleDisplayLocationChange('');
                                  document.getElementById('search_location_filter').value = '';
                                }}
                              />
                              <label className="form-label" htmlFor={`loc_checked_c_d${i}`}>
                                {loc.name}, {loc.description}
                              </label>
                            </div>
                          ))
                      ) : <em>Try Searching different Location!</em>}

                      {displayLocations.count > 10 &&
                        <div className="more" >
                          <p className="hand-hover" onClick={() => { setShowModalLocation(true); setDrawerIsOpen(false); }}>+{displayLocations.count - 10} More
                          </p>
                        </div>}
                    </div>
                  </div>

                  <div className="filter-category leftJobTypeTab d-flex flex-column">
                    <div className="filter_title-wrapper">
                      <p>Gig Type</p>
                    </div>
                    <div>
                      {masterData?.gig_types
                        .sort((a, b) => searchPayload.gig_types.includes(b.id) - searchPayload.gig_types.includes(a.id))
                        .map((gt, i) => {
                          return <div key={i} className="checkbox_div">
                            <input type="checkbox" name="" id={`gig_type_checked_${i}`}
                              checked={searchPayload.gig_types.includes(gt.id)}
                              onChange={(e) => manageDataInPayload('gig_types', gt.id, e.target.checked ? 'a' : 'r')}
                            />
                            <label className="form-label" htmlFor={`gig_type_checked_${i}`}>{gt.name}</label>
                          </div>
                        })}
                    </div>
                  </div>

                  <div className="filter-category d-flex flex-column gig-budget">
                    <div className="filter_title-wrapper"><p>Gig Budget</p></div>

                    <div className="row">
                      <div className="col-md-6">
                        <input
                          className="form-control mb-3"
                          type="number"
                          name="min_budget"
                          placeholder="Minimum Budget"
                          id="gig_min_budget"
                          value={gigBudget.min_budget}
                          onChange={e => setGigBudget({ ...gigBudget, min_budget: e.target.value })}
                          onBlur={e => handleMinBudgetBlur(e)}
                        />
                      </div>

                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="number"
                          name="max_budget"
                          placeholder="Maximum Budget"
                          value={gigBudget.max_budget}
                          id="gig_max_budget"
                          ref={maxBudgetRef}
                          onChange={e => setGigBudget({ ...gigBudget, max_budget: e.target.value })}
                          onBlur={e => handleMaxBudgetBlur(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="filter-category d-flex flex-column">
                    <div className="filter_title-wrapper"><p>Gigs Posted Between</p></div>

                    <DateRangePicker startDate={searchPayload.start_date[0]} setStartDate={(type, date) => manageDataInPayload(type, date, 'a')} endDate={searchPayload.end_date[0]} setEndDate={(type, date) => manageDataInPayload(type, date, 'a')} datePickerStartRef={datePickerStartRef} datePickerEndRef={datePickerEndRef} isDateFilter={isDateFilter} setIsDateFilter={setIsDateFilter} showDateShortcuts={true} onChange={(start, end) => {
                      manageDataInPayload('dates', [start, end], '');
                    }} usedInSearchComp={true} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </>
  );
}

export default GigSearch;
