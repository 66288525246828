import { BUDGET_LIMIT_TEXT } from "./storageConstants";
export const EMAIL_REQUIRED = "Please enter the email";
export const PASSWORD_REQUIRED = "Please enter the password";
export const EMAIL_PASSWORD_REQUIRED = "Please enter email and password";
export const FIRST_NAME_REQUIRED = "Please enter First Name";
export const PHOTO_REQUIRED = "Please upload your photo";
export const FIRST_NAME_LIMIT_ERROR = 'First Name should be less than 30 characters';
export const LAST_NAME_REQUIRED = "Please enter Last Name";
export const LAST_NAME_LIMIT_ERROR = 'Last Name should be less than 30 characters';
export const CONFIRM_PASSWORD_REQUIRED = "Please confirm your password";
export const PASSWORD_CONFIRM_PASSWORD_MATCH = "Password and confirm password do not match";
export const OLD_PASSWORD_REQUIRED = "Please enter old Password";
export const NEW_PASSWORD_REQUIRED = "Please enter new Password";
export const NEW_CONFIRM_PASSWORD_REQUIRED = "Please enter confirm new Password";
export const INVALID_PASSWORD = "Please enter password between 6 to 12 characters";
export const INVALID_TRAVEL_PERCENTAGE = "Travel % should be between 1 to 100";
export const OTP_REQUIRED = "Please enter OTP";
export const OTP_SENT_SUCCESS = "Email Validation OTP has been sent successfully";
export const OTP_SENT_FAILURE = "Please retry after 30 seconds.";
export const COMPANY_NAME_REQUIRED = "Please enter company name";
export const COMPANY_ADDRESS_REQUIRED = "Please enter company address";
export const COMPANY_LOCATION_REQUIRED = "Please select company location";
export const LOCATION_REQUIRED = "Please enter location";
export const COMPANY_SIZE_REQUIRED = "Please select company size";
export const COMPANY_INDUSTRY_DOMAIN_REQUIRED = "Please select industry domain";
export const INDUSTRY_DOMAIN_MAX = "You can add maximum 3 industry domains";
export const SKILLS_MAX_ERROR = "You can search for up to 4 skills only";
export const WEBSITE_REQUIRED = "Please enter website";
export const VALID_DOMAIN_REQUIRED = "Please enter a valid website domain";
export const VALID_WEBSITE_URL = "Please enter a valid website url";
export const TERMS_AND_CONDITIONS_REQUIRED = "Please accept TERMS & CONDITIONS";
export const REFERRAL_POLICY_REQUIRED = "Please accept Global Referral Policy";
export const EMAIL_WEBSITE_DOMAIN_SAME = "Domain of email and website must be same!";
export const BROWSER_STORAGE_ERROR = "Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.";
export const PROFESSIONAL_SUMMARY_REQUIRED = "Please enter professional summary";
export const NOTICE_PERIOD_REQUIRED = "Please select notice period";
export const CURRENT_SALARY_REQUIRED = "Please enter current salary";
export const REQUIRED_SALARY_REQUIRED = "Please enter expected salary";
export const EDUCATION_REQUIRED = "Please add your education details";
export const EMPLOYMENT_HISTORY_REQUIRED = "Please add your employment history";
export const SKILLS_REQUIRED = "Please add your skills";

/* ADD/EDIT JOB ERROR MESSAGES */
export const JOB_TITLE_REQUIRED = "Please enter job title";
export const JOB_DESCRIPTION_REQUIRED = "Please enter job description";
export const JOB_TYPE_REQUIRED = "Please select job type";
export const WORK_MODE_REQUIRED = "Please select work mode";
export const SALARY_TYPE_REQUIRED = "Please select salary type";
export const MIN_SALARY_REQUIRED = "Please enter minimum salary";
export const MAX_SALARY_REQUIRED = "Please enter maximum salary";
export const MIN_EXPERIENCE_REQUIRED = "Please enter minimum experience";
export const MAX_EXPERIENCE_REQUIRED = "Please enter maximum experience";
export const INDUSTRY_REQUIRED = "Please select industry domain";
export const WORK_AUTHORIZATION_REQUIRED = "Please select work authorization";
export const JOINING_PREFERENCE_REQUIRED = "Please select joining preference";
export const JOB_DURATION_REQUIRED = "Please select job duration";

export const JOB_TITLE_LIMIT_ERROR = 'Job title should be less than 100 characters';
export const REFERENCE_NUMBER_LIMIT_ERROR = 'Reference number should be less than 30 characters';

export const AMOUNT_GREATER_ZERO = "Cash amount must be greater than 0";
export const MIN_AMOUNT_REQUIRED = "Cash amount must be greater or equal to 100";
export const MAX_AMOUNT_REQUIRED = "Cash amount must be lesser or equal to 99,999";
export const SOMETHING_WENT_WRONG = "We seem to have hit a snag. Please retry in a while.";

export const NAME_REQUIRED = "Please enter the name";
export const PHONE_NUMBER_REQUIRED = "Please enter the phone number";
export const PHONE_NUMBER_INCORRECT = "Please enter correct phone number";
export const ALTERNATE_PHONE_NUMBER_INCORRECT = "Please enter correct alternate phone number";
export const MESSAGE_REQUIRED = "Please enter the message";
export const MUTE_DURATION_REQUIRED = "Please select the duration";
export const MIN_MAX_SALARY_ERROR = "Maximum salary should be greater than or equal to minimum salary";
export const INCORRECT_EMAIL = "The email must be a valid email address";
export const EMPTY_DEGREE_ERROR = "Please enter Degree/Qualification!";
export const EMPTY_SCHOOL_ERROR = "Please enter School/University/Institution!";
export const EMPTY_FROM_DATE_ERROR = "Please enter from date!";
export const EMPTY_TO_DATE_ERROR = "Please enter to date!";
export const EMPLOYMENT_FROM_TO_DATE_ERROR = "Employment start date should be earlier than Employment end date";
export const EDUCATION_FROM_TO_DATE_ERROR = "Education start date should be earlier than Education end date";

export const EMPTY_COMPANY_ERROR = "Please enter Company/Employer!";
export const JOB_TITLE_ERROR = "Please enter Job Title!";

export const EMPTY_ORGANISATION_ERROR =
  "Please enter Organisation/Authority Name!";
export const EMPTY_CERTIFICATE_TITLE_ERROR =
  "Please enter certification title!";
export const EMPTY_AWARDED_DATE_ERROR = "Please enter awarded date!";

export const EMPTY_SKILL_ERROR = "Please enter skill!";
export const ADDED_SKILL_ERROR = "Skill already added!";
export const INTERVIEW_HOURS_ERROR = "Please enter hours of interview!";

export const EMPTY_TITLE_ERROR = "Please enter title!";
export const EMPTY_AWARDED_ON_DATE_ERROR = "Please enter awarded on date!";

export const EMPTY_LINK_ERROR = "Please enter video link!";

export const INCOMPLETE_WORKPROFILE_ERROR = "Please complete your workprofile!";
export const INCOMPLETE_PROFILE_ERROR = "Please complete your profile!";
export const MIN_MAX_EXPERIENCE_ERROR = "Maximum experience should be greater than minimum experience";
export const TOTAL_POSITIONS_REQUIRED_ERROR = "Please enter number of positions";
export const TOTAL_POSITIONS_LIMIT_ERROR = "Number of positions must be greater than 0";

export const USER_REGISTRATION_ISSUE = "Issue with User Registration";
export const COMPANY_REGISTRATION_ISSUE = "Issue with Company Registration";
export const KEYBOARD_SHORTCUT_TEXT = "Enter the key words";
export const ADD_SKILL_SUGGESTION = "Please press enter if the skill is not showing up in the list";
export const LOCATION_SUGGESTION = "Please type minimum 3 characters to get the list of locations";
export const NO_LOCATION_FOUND_SUGGESTION = "Please try with another location";

export const COMPANY_NAME_LIMIT_ERROR = "Company name should be less than 30 characters";
export const VALID_LINK = "Please enter a valid link";
export const VALID_FACEBOOK_LINK = "Please enter a valid facebook link";
export const VALID_INSTAGRAM_LINK = "Please enter a valid instagram link";
export const VALID_LINKEDIN_LINK = "Please enter a valid linkedin link";
export const VALID_TWITTER_LINK = "Please enter a valid twitter link";

export const BUTTON_TEXT_OK = "OK";
export const BUTTON_TEXT_CONTINUE_EDITING = "Continue Editing";
export const DATEPICKER_RANGE_PLACEHOLDER = "DD/MM/YYYY - DD/MM/YYYY";
export const DATEPICKER_PLACEHOLDER = "DD/MM/YYYY";

export const EMPTY_PORTFOLIO_LINK_ERROR = "Please enter portfolio link!";
export const EMPTY_PORTFOLIO_LABEL_ERROR = "Please enter portfolio label!";
export const PORTFOLIO_LINK_EXISTS_ERROR = "Portfolio link already added!";
export const VALID_PORTFOLIO_LINK = "Please enter a valid portfolio link";


/* ADD/EDIT GIG ERROR MESSAGES */
export const GIG_TITLE_REQUIRED = 'Please enter gig title';
export const GIG_TITLE_LIMIT_ERROR = 'Gig title should be less than 100 characters';
export const GIG_DESCRIPTION_REQUIRED = 'Please enter gig description';
export const GIG_TYPE_REQUIRED = 'Please select gig type';
export const MIN_BUDGET_REQUIRED = 'Please enter minimum budget';
export const MAX_BUDGET_REQUIRED = 'Please enter maximum budget';
export const PRE_LOCATION_REQUIRED = 'Please enter location';
export const BONUS_REQUIRED = 'Please specify bonus';
export const GIG_SKILL_REQUIRED = 'Please enter skill';
export const BUDGET_LIMIT_ERROR = "Max Budget should be less than " + BUDGET_LIMIT_TEXT;
export const MIN_MAX_BUDGET_ERROR = "Minimum budget should be less than or equal to maximum budget";
export const MAX_MIN_BUDGET_ERROR = "Maximum budget should be greater than minimum budget";

export const IMPORTANT_WARNING_TITLE = "Important";
export const TOTAL_EXPERIENCE_WARNING_TEXT = "Maximum individual skill experience will be mapped to Total experience.";

export const MANDATORY_FIELDS_REQUIRED = "Please fill all the mandatory fields";
export const REFERRAL_NUMBER_INVALID = "Please enter valid referral number";
export const REFERRAL_NUMBER_REQUIRED = "Please enter number of referrals";
export const REFERRAL_AMOUNT_REQUIRED = "Please enter referral amount";
export const REFERRAL_NUMBER_LIMIT = "Referral number must be less than 100";
export const REFERRAL_DURATION_REQUIRED = "Please enter referral duration";

export const REFERRAL_INFO = "This post is open for referrals";
export const INVALID_KEYWORD_ERROR_MESSAGE = "Please enter a valid keyword!";
export const NO_SKILL_ERROR_MESSAGE = "Please add atleast 1 skill";
export const BOOK_APPOINTMENT_SUCCESS_MESSAGE = "Appointment booked successfully!";
export const NO_TIME_SLOT_ERROR_MESSAGE = "Please add atleast 1 time slot";
export const MINIMUM_SKILL_EVALUATOR_ERROR_MESSAGE = "Currently, we accept evaluators with at least eight years of experience.";
export const MAXIMUM_ADDITIONAL_DOMAINS_ERROR_MESSAGE = 'You can add maximum 5 domains';
export const INVALID_ADDITIONAL_DOMAIN_ERROR_MESSAGE = "Please enter a valid domain or url";
export const ADDITIONAL_DOMAIN_EXISTS_ERROR_MESSAGE = "Domain already added";



export const COURSE_NAME_REQUIRED = "Please Enter Course Name"
export const COURSE_URL_REQUIRED = "Please Enter Course URL"
export const COURSE_IMAGE_REQUIRED = "Please Add Course Image"
export const COURSE_DESCRIPTION_REQUIRED = "Please Enter Course Description"
export const VALID_COURSE_URL = "Please Enter Valid Course URL"


export const MIN_MATCH_PERCENT_ERROR_MESSAGE = "Match percentage should be greater than 60%";
export const MAXIMUM_NUMBER_ALLOWED = (length) => `Maximum ${length} characters allowed.`;


export const NO_ENOUGH_TT_CASH_ERROR_MESSAGE = "Insufficient Balance. Please purchase more TT Cash from the “Buy More” link";

export const HOMEPAGE_SLOTS_REQUIRED = "Please enter Home page slots";
export const HOMEPAGE_SLOTS_TIME_REQUIRED = "Please select the duration for Home page slots";

export const SEARCHPAGE_SLOTS_REQUIRED = "Please enter Search page slots";
export const SEARCHPAGE_SLOTS_TIME_REQUIRED = "Please select the duration for Search page slots";

export const JOB_ALREADY_FEATURED = "This Job is already featured on search page.";
export const GIG_ALREADY_FEATURED = "This Gig is already featured on search page.";

export const TARGET_USER_REQUIRED = "Please select target user to assign data";
export const CERTIFICATE_REQUIRED = "Certificate can't be empty";
export const CERTIFICATE_NAME_REQUIRED = "Enter the name of certification provider";

export const SELECT_VIDEO_FILE = "Please select video file type only";
export const SELECT_IMG_FILE = "Please select image file type only";
export const MAX_SALARY_AMT_REACHED = "Max 99,99,99,999 Amount is allowed";

export const maxLengthAllowed = (char) => (`Max ${char} charachters allowed`);
export const RESUME_LINK_NOT_FOUND = `Candidate Resume link not found`;
